import { BaseEntityApi, GetFields, Modify, ApiToSlice } from 'types/slices'

export const statuses = ['open', 'partially_paid', 'paid', 'void'] as const

type Status = typeof statuses[number]

export type MapData = {
  primaryLanguage?: string,
  secondaryLanguage?: string,
  defaultUnits?: {
    qty: string,
    weight: string,
    length: string,
    surface: string,
    volume: string,
  },
}

export type BillApi = Modify<{
  name: string,
  company_id: number,
  vendor_id: string,
  bill_to_address_id: string,
  notes: string,
  reference_number: string,
  bill_date: Date,
  status: Status,
  payment_terms: number,
  terms_and_conditions: string,
  currency_id: number,
  exchange_rate: number,
  external_id: string,
  config: Record<string, any>,
  due_date: Date,
  is_overdue: boolean,
  vendor_display_name: string,
  vendor_company_name: string,
  currency_code: string,
  currency_symbol: string,
  line_items: BillItemApi[]
}, BaseEntityApi>

export type Bill = ApiToSlice<Modify<BillApi, {
  lineItems: BillItem[],
  total: number
}>>

export type BillGetFields = GetFields<BillApi, Bill, MapData>

export type BillItemApi = Modify<{
  name: string,
  bill_id: string,
  bill_name: string,
  bill_status: Status,
  bill_is_overdue: boolean,
  bill_external_id: string,
  measure: number,
  purchase_order_measure: number,
  purchase_order_billed_count: number,
  measure_unit: string,
  dimension_to_display: string,
  template_inventory_management_type: string,
  template_id: string,
  conversion_factor: number,
  unit_cost: number,
  account_id: string,
  account_name: string,
  template_title: string,
  purchase_order_id: string,
  purchase_order_name: string,
}, BaseEntityApi>

type Exceptions = {
  purchase_order_billed_count: 'purchaseOrderBilled',
  measure_unit: 'unit',
  dimension_to_display: 'dimension'
  template_title: 'item',
  purchase_order_name: 'purchaseOrder',
  measure: 'billed'
}
export type BillItem = ApiToSlice<Modify<BillItemApi, {
  totalCost: number,
  convertedMeasure: number,
}>, Exceptions>

export type BillItemGetFields = GetFields<BillItemApi, BillItem, MapData>
