import { FieldType } from 'types/slices'

export const upperCaseFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export const lowerCaseFirstLetter = (str: string) => {
  return str.charAt(0).toLowerCase() + str.slice(1)
}

export const htmlToText = (html: string) => {
  return html.replace(/<[^>]*>?/gm, '')
}

export const toSnake = (text) => {
  if (typeof text !== 'string') return text
  return text.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`)
}

export const toCamel = (text, field?: FieldType<any, any>) => {
  if (!text) {
    // DevLogs.error(`Attempting to convert \`${text}\` to camel case.`, { field })
    return null
  }

  if (typeof text !== 'string') return text

  const camelCased = text.replace(/([-_][a-z])/ig, ($1) => {
    return $1.toUpperCase()
      .replace('-', '')
      .replace('_', '')
  })

  return camelCased.charAt(0).toLowerCase() + camelCased.slice(1)
}
