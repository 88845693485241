import React, { forwardRef, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Skeleton } from 'primereact/skeleton'

import dictionary from './dictionary.json'

/**
 * @typedef {Object} Props
 * @property {string} [status]
 * @property {string} [dictionaryKey]
 * @property {string} [type]
 * @property {string} [extraInfo]
 * @property {string} [className]
 * @property {boolean} [isSkeleton]
 * @property {any} [override]
 */

/**
 * @param {React.PropsWithRef<Props>} props
 * @return {React.ReactElement}
 */
function SmartStatus({
  status,
  className,
  isSkeleton = false,
  dictionaryKey = '',
  type = 'status',
  extraInfo = '',
  override,
}, ref) {
  const entity = dictionary[dictionaryKey]
  const namespace = entity?.namespace || 'common'
  const entityStatus = entity?.[type]?.[status] || entity?.[type]?.default
  const { t } = useTranslation([namespace])

  const style = useMemo(
    () => override?.backgroundColor && { backgroundColor: override.backgroundColor },
    [override?.backgroundColor],
  )

  if (isSkeleton) {
    return (
      <Skeleton
        className="a-smart-status-skeleton"
        borderRadius="2rem"
        width="8rem"
      />
    )
  }

  const classNames = [
    'a-smart-status',
    ...(override?.statusClass ? [override.statusClass] : entityStatus?.classNames || []),
  ]
  if (className) {
    classNames.push(className)
  }
  const iconLibrary = override?.iconLibrary ?? entityStatus?.iconLibrary
  const icon = override?.icon ?? entityStatus?.icon

  return (
    <div
      ref={ref}
      className={classNames.join(' ')}
      style={style}
    >
      {icon ? <FontAwesomeIcon icon={[iconLibrary, icon]} /> : null}
      {override?.title ? override.title : `${extraInfo}${t(`${namespace}:${type}.${status}`)}`}
    </div>
  )
}

export default forwardRef(SmartStatus)
