import { GetFields } from 'types/slices'

import { buildGetUrl, parse } from 'utils/api'
import { isJob, safeFetch, safeFetchJson } from 'utils/safeFetch'

import { parseCard } from 'reducers/cards/cardsSlice'
import { parseSalesOrder } from 'reducers/sales-orders/shared'
import { E, SmartFormOptionObject } from 'reducers/smart-form/smartFormTypes'

import { ProjectApi, ProjectStatuses, Project, ProjectMapData } from './projectType'
import {
  GET_PROJECTS,
  GET_PROJECTS_COUNT,
  DELETE_PROJECTS,
} from './types'

const dataSetName = 'projectView'

const fields = getFields()
const initialState = {
  dataSetName,
  fields,
  projectsCount: 0,
  projects: [],
}

const _unallowedDuplicateTypes = ['manufacturing']

export default function projectsReducer(state = initialState, action) {
  const { payload } = action
  switch (action.type) {
  case GET_PROJECTS_COUNT: {
    return {
      ...state,
      projectsCount: payload,
    }
  }
  case GET_PROJECTS: {
    return {
      ...state,
      projects: payload,
    }
  }
  default: {
    return state
  }
  }
}

export type ProjectDuplicationOptions = {
  shownInSales?: boolean,
  hiddenFromSales?: boolean,
  isCancel?: boolean
}

export function getFields(): GetFields<ProjectApi, Project, ProjectMapData, 'projectView'> {
  return {
    'id': { dataSetName, dbField: 'id', type: 'id' },
    'plantId': { dataSetName, dbField: 'plant_id', type: 'id', relationEntity: 'plants', isEdit: true },
    'title': { dataSetName, dbField: 'title', isEdit: true },
    'displayTitle': { dataSetName, dbField: 'display_name' },
    'status': {
      dataSetName,
      dbField: 'status',
      customEventValueTranslationKey: (value) => `projects:status.${value ?? 'draft'}`,
      isEdit: true,
      type: 'status',
      dictionaryKey: 'project',
      values: ProjectStatuses,
    },
    'type': { dataSetName, dbField: 'type', isEdit: true },
    'formattedNumber': { dataSetName, dbField: 'formated_number' },
    'clientId': { dataSetName, dbField: 'client_id', isEdit: true, updateDbField: 'contact_id' },
    'clientDisplayName': { dataSetName, dbField: 'client_display_name' },
    'clientCompanyName': { dataSetName, dbField: 'client_company_name' },
    'ownerLastname': { dataSetName, dbField: 'owner_lastname' },
    'ownerFirstname': { dataSetName, dbField: 'owner_firstname' },
    'clientOnLabel': { dataSetName, dbField: 'client_on_label', isEdit: true },
    'promisedDate': {
      dataSetName,
      dbField: 'promised_date',
      type: 'date',
      isTimezoned: false,
      isEdit: true,
    },
    'createdDate': { dataSetName, dbField: 'created_date', type: 'date' },
    'createdBy': { dataSetName, dbField: 'created_by' },
    'createdById': { dataSetName, dbField: 'created_by_id', type: 'id' },
    'modifiedDate': { dataSetName, dbField: 'modified_date', type: 'date' },
    'modifiedBy': { dataSetName, dbField: 'modified_by' },
    'modifiedById': { dataSetName, dbField: 'modified_by_id', type: 'id' },
    'newItemCount': { dataSetName, dbField: 'new_item_count' },
    'totalItemCount': { dataSetName, dbField: 'total_item_count' },
    'plantName': { dataSetName, dbField: 'plant_name' },
    'description': { dataSetName, dbField: 'description', isEdit: true },
    'clientAddressId': { dataSetName, dbField: 'client_address_id', type: 'id', isEdit: true },
    'clientFulladdress': { dataSetName, dbField: 'client_fulladdress' },
    'ownerId': { dataSetName, dbField: 'owner_id', type: 'id', isEdit: true },
    'setInventoryProject': { dataSetName, dbField: 'set_inventory_project', isEdit: true },
    'addItemFromBoard': { dataSetName, dbField: 'add_item_from_board', isEdit: true, formDefaultValue: false },
    'markup': { dataSetName, dbField: 'markup', isEdit: true },
    'resourceDiscount': { dataSetName, dbField: 'resource_discount', isEdit: true },
    'equipmentDiscount': { dataSetName, dbField: 'equipment_discount', isEdit: true },
    'materialDiscount': { dataSetName, dbField: 'material_discount', isEdit: true },
    'subcontractDiscount': { dataSetName, dbField: 'subcontract_discount', isEdit: true },
    'valueStreamIds': {
      dataSetName,
      type: 'array',
      parse: (project) => project.valuestreams?.map((valueStream) => valueStream.valuestream_id) || [],
      isEdit: true,
    },
    'salesOrders': {
      dataSetName,
      type: 'array',
      parse: (project, mapData) => project.sales_orders?.map((salesOrder) =>
        parseSalesOrder(salesOrder, mapData)) || [],
    },
    // ! TODO (wboudreault): rework pour que ce soit cardTemplates et non lineItems (#DEV-I2282)
    'lineItems': {
      dataSetName,
      type: 'array',
      parse: (project, mapData) => project.card_templates?.map((cardTemplate) =>
        parseCard(cardTemplate, mapData)) || [],
    },
  }
}

export async function _fetchProjectsCount(data: Record<string, any> = {}) {
  data = parseFetchProjectsData(data)
  try {
    const result = await (await safeFetch(buildGetUrl('/new_api/projects/count', data))).json()
    if (result.isSuccess) {
      return +result.result[0]?.count || 0
    }
  } catch (err) {
    console.error(err)
  }
  return 0
}

export function fetchProjectsCount(data: Record<string, any>) {
  return async function fetchProjectsCountThunk(dispatch) {
    const count = await _fetchProjectsCount(data)
    dispatch({ type: GET_PROJECTS_COUNT, payload: count })
    return count
  }
}

export function duplicateProjects(projectIds: Project['id'][], state: ProjectDuplicationOptions = {}) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      'projectIds': projectIds,
      'shownInSales': state.shownInSales,
      'hiddenFromSales': state.hiddenFromSales,
      'isCancel': state.isCancel,
    }),
  }

  return safeFetchJson(`/new_api/projects/copy`, requestOptions)
}

export async function _fetchProjects(data?: Record<string, any>) {
  let projects = []

  data = parseFetchProjectsData(data)
  try {
    const result = await (await safeFetch(buildGetUrl('/new_api/projects', {
      ...data,
      excludeItems: true,
    }))).json()
    if (result.isSuccess) {
      projects = result.result.map((project) => parseProject(project))
    }
  } catch (err) {
    console.error(err)
  }

  return projects
}

export function fetchProjects(data?: Record<string, any>) {
  return async function fetchProjectsThunk(dispatch) {
    const projects = await _fetchProjects(data)
    dispatch({ type: GET_PROJECTS, payload: projects })
    return projects
  }
}

export async function fetchProjectByIds(ids: Project['id'][], data: Record<string, any> = {}) {
  if (!ids?.length) return []

  data = parseFetchProjectsData(data)
  const { isSuccess, result } = await safeFetchJson<ProjectApi>(
    buildGetUrl(`/new_api/projects/${ids}`, { ...data, excludeItems: true }),
  )

  return isSuccess && !isJob(result) ? result.map((project) => parseProject(project)) : []
}

export async function fetchProject(projectId: Project['id']) {
  let project

  try {
    const result = await (await safeFetch(buildGetUrl(`/new_api/projects/${projectId}`))).json()
    if (result.isSuccess) {
      [project] = result.result.map((project) => parseProject(project))
    }
  } catch (err) {
    console.error(err)
  }

  return project
}

export async function fetchProjectIndex(id, data: Record<string, any> = {}) {
  let index = 0

  if (id) {
    try {
      data = parseFetchProjectsData(data)
      const result = await (await safeFetch(buildGetUrl(`/new_api/projects/${id}/index`, data))).json()
      if (result.isSuccess) {
        index = +result.result || 0
      }
    } catch (err) {
      console.error(err)
    }
  }

  return index
}

function parseCondition(conditionObj) {
  return Array.isArray(conditionObj) ? JSON.stringify(conditionObj) : conditionObj
}

function parseFetchProjectsData(data: Record<string, any> = {}) {
  const parsedData = { ...data }
  if (parsedData.conditionObj) {
    parsedData.conditionObj = parseCondition(parsedData.conditionObj)
  }

  return parsedData
}

export function parseProject(project: ProjectApi): Project {
  const options = {
    defaultData: getDefaultProject(),
    fields: initialState.fields,
    dataSetName,
  }
  const ownerFullName = `${project.owner_firstname || ''} ${project.owner_lastname || ''}`

  return {
    ...parse(project, options),
    ownerFullName,
  }
}

function getDefaultProject(): Project {
  return parse({}, { fields })
}

export function getProjectTitle(project: Project) {
  return project.displayTitle
}

export function deleteProjects(ids: Project['id'][]) {
  return async function deleteProjectsThunk(dispatch) {
    try {
      const result = await (await safeFetch(`/new_api/projects/${ids}`, { method: 'DELETE' })).json()
      const error = !result.isSuccess ? result.result : null
      dispatch({ type: DELETE_PROJECTS, payload: !!result.isSuccess, error })
      return result
    } catch (error) {
      dispatch({ type: DELETE_PROJECTS, payload: false, error })
      console.error(error)
    }
  }
}

export function canDuplicateProjects(projects: Project[]) {
  return projects.every((project) => !_unallowedDuplicateTypes.includes(project.type))
}

export function canDeleteProjects(projects: Project[]) {
  return !projects.some((project) => +project.totalItemCount > +project.newItemCount)
}

export const getProjectDropdownOptions = <M extends E = E, L extends E = E>
  ({ fetchData = {} } = { fetchData: {} }): SmartFormOptionObject<M, Project, L> => {
  return {
    key: 'projects',
    fetchOnFirstOpen: true,
    fetcher: _fetchProjects,
    indexFetcher: fetchProjectIndex,
    countFetcher: _fetchProjectsCount,
    fields: initialState.fields,

    fetchData: {
      ...fetchData,
    },
    filterFieldKeys: ['title', 'formattedNumber'],
    orderByFieldKeys: [
      {
        key: 'title',
        isNullFirst: true,
      },
      {
        key: 'id',
        isNullFirst: true,
      },
    ],
    isLazy: true,
  }
}

export const parseFormatBodyData = (data, method, activeData, options: Record<string, any>): object => {
  if (method != 'put' && method != 'post') return { data }

  const _data = { ...data }
  if (method === 'post') {
    const valueStreamInsertions = _data.valueStreamIds || []
    _data.valuestreams = _data.type === 'sales' ? { insertions: valueStreamInsertions } : valueStreamInsertions

    if (_data.add_item_from_board) {
      const cardTemplateInsertions = _data.lineItems.map((insertion) => insertion.id)
      _data.card_templates = _data.type === 'sales' ? { insertions: cardTemplateInsertions } : cardTemplateInsertions
    }
  } else {
    const valueStreamInsertions = _data.valueStreamIds?.filter((id) => !activeData?.valueStreamIds?.includes(id)) || []
    const valueStreamDeletions = activeData?.valueStreamIds?.filter(
      (id) => !_data.valueStreamIds?.includes(id)) || []
    const valueStreams = { insertions: valueStreamInsertions, deletions: valueStreamDeletions }
    _data.valuestreams = valueStreams

    if (_data.add_item_from_board ?? activeData?.addItemFromBoard) {
      _data.card_templates = {
        insertions: (_data.lineItems.insertions || _data.lineItems).map((insertion) => insertion.id),
        deletions: _data.lineItems.deletions || [],
      }
    }
  }
  delete _data.valueStreamIds
  delete _data.lineItems

  switch (method) {
  case 'put':
    return { project: _data, ...options }
  case 'post':
    return { projects: [_data], ...options }
  }
}
