import { ApiToSlice, BaseEntityApi, Modify } from 'types/slices'

import { ContactType } from 'components/alix-front/contacts/ContactContext'

import { ResourceApi } from 'reducers/resources/resourcesSlice'
import { SalesOrder, SalesOrderApi } from 'reducers/sales-orders/types'

export type ProjectMapData = { }

export const ProjectStatuses = ['draft', 'on_hold', 'open', 'quote_submitted', 'canceled', 'closed']
export const ProjectTypes = ['sales', 'standard', 'template', 'manufacturing']

export type ProjectApi = Modify<
    {
        number: number,
        contact_id: string,
        owner_id: number,
        status: string,
        title: string,
        description: string,
        promised_date: string,
        resource_discount: number,
        equipment_discount: number,
        material_discount: number,
        subcontract_discount: number,
        markup: number,
        created_date: Date,
        modified_date: Date,
        year: number,
        stack_rank: number,
        client_on_label: boolean,
        client_address_id: string,
        add_item_from_board: boolean,
        set_inventory_project: boolean,
        zoho_workdrive_folder_id: string,
        formated_number: string,
        zoho_books_project_id: string,
        type: (typeof ProjectTypes[number]),
        plant_id: string,
        created_from_id: string,
        display_name: string,
        client_id: string,
        client_company_name: string,
        client_website: string,
        client_display_name: string,
        client_is_portal_enabled: boolean,
        client_currency_id: number,
        client_notes: string,
        client_language: string,
        client_contact_type: ContactType,
        client_fulladdress: string,
        client_zoho_books_contact_id: string,
        client_zoho_crm_account_id: string,
        client_woo_commerce_contact_id: string,
        client_price_list_id: string,
        client_zoho_books_tax_id: string,
        client_tax_id: string,
        client_payment_terms: number,
        client_category_id: number,
        client_code: string,
        client_owner_id: ResourceApi['id'],
        client_is_guest: boolean,
        client_carrier_id: string,
        owner_isactive: boolean,
        owner_no: string,
        owner_firstname: ResourceApi['firstname'],
        owner_lastname: ResourceApi['lastname'],
        owner_email: ResourceApi['email'],
        owner_managerid: number,
        owner_department_id: ResourceApi['department_id'],
        owner_position_id: string,
        owner_shift_id: ResourceApi['shift_id'],
        owner_phone: string,
        owner_mobile: string,
        owner_pin: string,
        owner_photo_filename: string,
        owner_timeclockstatus: string,
        owner_color: string,
        owner_costing: number,
        owner_notes: string,
        owner_show_in_pay: boolean,
        owner_profile_is_initials: boolean,
        owner_employe_no: string,
        owner_fringe: number,
        owner_user_view: ResourceApi['user_view'],
        owner_user_view_editable: ResourceApi['user_view_editable'],
        owner_is_project_supervisor: boolean,
        owner_bucket: string,
        owner_current_break_time_entry_id: string,
        price_list_id: string,
        price_list_name: string,
        price_list_description: string,
        price_list_config: any,
        price_list_currency_id: number,
        price_list_pricing_method: string,
        price_list_scope: string,
        sales_currency_code: SalesOrderApi['currency_code'],
        sales_currency_symbol: SalesOrderApi['currency_symbol'],
        plant_name: string,
        total_item_count: number,
        new_item_count: number,
        project_title: string,
        valuestreams: any[],
        card_templates: CardTemplate[],
        sales_orders: SalesOrderApi[],
    },
    BaseEntityApi
>

export type CardTemplate = {id: string, title: string, part_number: string}

export type ProjectExceptions = {}

export type Project = Modify<
  ApiToSlice<ProjectApi, ProjectExceptions>,
  {
    valueStreamIds: string[]
    lineItems: CardTemplate[]
    salesOrders: SalesOrder[],
    ownerFullName: string
    displayTitle: string,
    formattedNumber: number,
  }
>
