import { BaseEntityApi, GetFields, Modify, ApiToSlice } from 'types/slices'

import { TaxApi } from 'reducers/taxes/taxesSlice'

export const statuses = ['draft', 'sent', 'partially_paid', 'paid', 'void'] as const

type Status = typeof statuses[number]

export type MapData = {
  primaryLanguage?: string,
  secondaryLanguage?: string,
  defaultUnits?: {
    qty: string,
    weight: string,
    length: string,
    surface: string,
    volume: string,
  },
}

export type InvoiceApi = Modify<{
  company_id: number,
  customer_id: string,
  ship_to_address_id: string,
  bill_to_address_id: string,
  name: string,
  notes: string,
  reference_number: string,
  invoice_date: Date,
  status: Status,
  payment_terms: number,
  subject: any,
  terms_and_conditions: string,
  currency_id: number,
  exchange_rate: number,
  external_id: string,
  config: Record<string, any>,
  due_date: Date,
  is_overdue: boolean,
  customer_display_name: string,
  customer_company_name: string,
  currency_code: string,
  currency_symbol: string,
  line_items: InvoiceItemApi[]
}, BaseEntityApi>

export type Invoice = ApiToSlice<Modify<InvoiceApi, {
  lineItems: InvoiceItem[],
  total: number
}>>

export type InvoiceGetFields = GetFields<InvoiceApi, Invoice, MapData>

export type InvoiceItemApi = Modify<{
  invoice_id: string,
  invoice_name: string,
  invoice_status: Status,
  invoice_is_overdue: boolean,
  invoice_external_id: string,
  name: string,
  measure: number,
  sales_order_measure: number,
  sales_order_invoiced_count: number,
  sales_order_shipped_count: number,
  measure_unit: string,
  dimension_to_display: string,
  income_account_id: string,
  account_name: string,
  template_id: string,
  unit_selling_price: number,
  template_title: string,
  discount: number,
  tax_id: string,
  sales_order_id: string,
  sales_order_name: string,
  contract_number:string,
  overwritten_notes: string,
  primary_notes: string,
  secondary_notes: string,
  promised_date: Date,
  template_sku: string,
  tax: TaxApi
}, BaseEntityApi>

type Exceptions = {
  sales_order_invoiced_count: 'salesOrderInvoiced',
  sales_order_shipped_count: 'salesOrderShipped',
  measure_unit: 'unit',
  dimension_to_display: 'dimension'
  template_title: 'item',
  purchase_order_name: 'purchaseOrder',
  unit_selling_price: 'unitPrice',
  sales_order_name: 'salesOrder'
  measure: 'invoiced'
}

export type InvoiceItem =
  ApiToSlice<Modify<InvoiceItemApi, {
    totalPrice: number,
    taxe: string,
    convertedMeasure: number,
    discountedPrice: number,
    longDescription: string,
  }>, Exceptions>

export type InvoiceItemGetFields = GetFields<InvoiceItemApi, InvoiceItem, MapData>

