import { createSlice } from '@reduxjs/toolkit'
import { AppDispatch } from 'store'

import { buildGetUrl, parse } from 'utils/api'
import { parseNumber } from 'utils/numberParser'
import { isJob, safeFetchJson } from 'utils/safeFetch'

import { parseDisplayTitle } from 'reducers/charts-of-accounts/chartsOfAccountsSlice'

import {
  Transaction,
  TransactionApi,
  TransactionGetFields,
  TransactionItem,
  TransactionItemApi,
  TransactionItemGetFields,
  referenceEntities,
  reversalReasons,
  syncStatuses,
  transactionTypes,
} from './types'

const dataSetName = 'transactionView'
const itemDataSetName = 'transactionItemView'

const initialState = {
  dataSetName,
  fields: getFields(),
  itemFields: getItemFields(),
  transactionsCount: 0,
  transactions: [],
  transactionItemsCount: 0,
  transactionItems: [],
}

const transactionsSlice = createSlice({
  name: 'transactions',
  initialState,
  reducers: {
    setTransactions: (state, action) => {
      state.transactions = action.payload.data
    },
    setTransactionsCount: (state, action) => {
      state.transactionsCount = action.payload.count
    },
    setTransactionItems: (state, action) => {
      state.transactionItems = action.payload.data
    },
    setTransactionItemsCount: (state, action) => {
      state.transactionItemsCount = action.payload.count
    },
  },
})

export const {
  setTransactions,
  setTransactionsCount,
  setTransactionItems,
  setTransactionItemsCount,
} = transactionsSlice.actions

export function getFields(): TransactionGetFields {
  return {
    'id': { dataSetName, dbField: 'id', type: 'string' },
    'exist': { dataSetName, dbField: 'exist', type: 'boolean' },
    'createdDate': { dataSetName, dbField: 'created_date', type: 'timestamp' },
    'createdBy': { dataSetName, dbField: 'created_by', type: 'string' },
    'createdById': { dataSetName, dbField: 'created_by_id', type: 'string' },
    'modifiedDate': { dataSetName, dbField: 'modified_date', type: 'timestamp' },
    'modifiedBy': { dataSetName, dbField: 'modified_by', type: 'string' },
    'modifiedById': { dataSetName, dbField: 'modified_by_id', type: 'string' },
    'companyId': { dataSetName, dbField: 'company_id', type: 'id', relationEntity: 'companies' },
    'name': { dataSetName, dbField: 'name' },
    'notes': { dataSetName, dbField: 'notes' },
    'transactionDate': { dataSetName, dbField: 'transaction_date', type: 'date' },
    'transactionType': {
      dataSetName,
      dbField: 'transaction_type',
      type: 'enum',
      values: [...transactionTypes],
      translationPath: 'transactions:transaction.fields.transactionType.values',
    },
    'postingDate': { dataSetName, dbField: 'posting_date', type: 'date' },
    'generatedNumber': { dataSetName, dbField: 'generated_number' },
    'referenceNumber': { dataSetName, dbField: 'reference_number' },
    'referenceEntity': {
      dataSetName,
      dbField: 'reference_entity',
      type: 'enum',
      values: [...referenceEntities],
      translationPath: 'transactions:transaction.fields.referenceEntity.values',
    },
    'referenceId': { dataSetName, dbField: 'reference_id' },
    'isReversal': {
      dataSetName,
      dbField: 'is_reversal',
      type: 'boolean',
      translationPath: (key) => `common:boolean.${key}`,
    },
    'reversalReason': {
      dataSetName,
      dbField: 'reversal_reason',
      type: 'enum',
      values: [...reversalReasons],
      allowEmptyEnum: true,
      translationPath: 'transactions:transaction.fields.reversalReason.values',
    },
    'linkedTransactionId': {
      dataSetName,
      dbField: 'linked_transaction_id',
      type: 'id',
      relationEntity: 'transactions',
    },
    'currencyId': { dataSetName, dbField: 'currency_id' },
    'exchangeRate': { dataSetName, dbField: 'exchange_rate', type: 'float' },
    'syncStatus': {
      dataSetName,
      dbField: 'sync_status',
      type: 'status',
      dictionaryType: 'syncStatus',
      dictionaryKey: 'transaction',
      values: [...syncStatuses],
      translationPath: 'transactions:transaction.fields.syncStatus.values',
    },
    'syncDate': { dataSetName, dbField: 'sync_date', type: 'timestamp' },
    'externalTransactionId': { dataSetName, dbField: 'external_transaction_id' },
    'currencyCode': { dataSetName, dbField: 'currency_code' },
    'currencySymbol': { dataSetName, dbField: 'currency_symbol' },
    'currencyExternalId': { dataSetName, dbField: 'currency_external_id' },
    'isReversed': {
      dataSetName,
      dbField: 'is_reversed',
      type: 'boolean',
      translationPath: (key) => `common:boolean.${key}`,
    },
    'debitTotal': { dataSetName, dbField: 'debit_total', type: 'currency' },
    'creditTotal': { dataSetName, dbField: 'credit_total', type: 'currency' },
    'lineItems': { parse: (transaction) => {
      return (transaction?.lineItems || []).map((item) => parseTransactionItem(item))
    } },
  }
}

export function getTransactionTitle(transaction: Transaction): string {
  return transaction.name
}

export async function fetchTransactionsByIds(ids: string[], data?: Record<string, any>) {
  if (!ids?.length) return []

  const { isSuccess, result } = await safeFetchJson<TransactionApi>(
    buildGetUrl(`/new_api/accounting/transactions/${ids}`, data),
  )

  return isSuccess && !isJob(result) ?
    result.map((transaction) => parseTransaction(transaction)) :
    []
}

export function fetchTransactions(fetchData?: Record<string, any>) {
  return async function fetchTransactionsThunk(dispatch: AppDispatch) {
    const data = await _fetchTransactions(fetchData)
    dispatch(setTransactions({ data }))
    return data
  }
}

export async function _fetchTransactions(fetchData: Record<string, any>) {
  let transactions = []

  try {
    const { isSuccess, result } = await safeFetchJson<TransactionApi>(
      buildGetUrl('/new_api/accounting/transactions', fetchData),
    )
    if (isSuccess && !isJob(result)) {
      transactions = result.map((transaction) => parseTransaction(transaction))
    }
  } catch (err) {
    console.error(err)
  }

  return transactions
}

export function fetchTransactionsCount(fetchData?: Record<string, any>) {
  return async function fetchTransactionsCountThunk(dispatch: AppDispatch) {
    const count = await _fetchTransactionsCount(fetchData)
    dispatch(setTransactionsCount({ count }))
    return count
  }
}

export async function _fetchTransactionsCount(fetchData?: Record<string, any>) {
  let count = 0

  try {
    const { isSuccess, result } = await safeFetchJson<{count: string}>(
      buildGetUrl('/new_api/accounting/transactions/count', fetchData),
    )
    if (isSuccess && !isJob(result)) {
      count = +result[0].count || 0
    }
  } catch (err) {
    console.error(err)
  }

  return count
}

export function parseTransaction(transaction: TransactionApi): Transaction {
  const options = {
    defaultData: getDefaultTransaction(),
    fields: initialState.fields,
    dataSetName: dataSetName,
  }

  return parse(transaction, options)
}

function getDefaultTransaction(): Transaction {
  return parse({}, { fields: initialState.fields })
}

export function getItemFields(): TransactionItemGetFields {
  return {
    'id': { dataSetName: itemDataSetName, dbField: 'id', type: 'string' },
    'exist': { dataSetName: itemDataSetName, dbField: 'exist', type: 'boolean' },
    'createdDate': { dataSetName: itemDataSetName, dbField: 'created_date', type: 'timestamp' },
    'createdBy': { dataSetName: itemDataSetName, dbField: 'created_by', type: 'string' },
    'createdById': { dataSetName: itemDataSetName, dbField: 'created_by_id', type: 'string' },
    'modifiedDate': { dataSetName: itemDataSetName, dbField: 'modified_date', type: 'timestamp' },
    'modifiedBy': { dataSetName: itemDataSetName, dbField: 'modified_by', type: 'string' },
    'modifiedById': { dataSetName: itemDataSetName, dbField: 'modified_by_id', type: 'string' },
    'transactionId': {
      dataSetName: itemDataSetName,
      dbField: 'transaction_id',
      type: 'id',
      relationEntity: 'transactions',
    },
    'accountId': {
      dataSetName: itemDataSetName,
      dbField: 'account_id',
      type: 'id',
      relationEntity: 'chart-of-accounts',
    },
    'debit': { dataSetName: itemDataSetName, dbField: 'debit', type: 'currency' },
    'credit': { dataSetName: itemDataSetName, dbField: 'credit', type: 'currency' },
    'accountCode': { dataSetName: itemDataSetName, dbField: 'account_code' },
    'accountName': { dataSetName: itemDataSetName, dbField: 'account_name' },
    'accountExternalId': { dataSetName: itemDataSetName, dbField: 'account_external_id' },
  }
}

export function getTransactionItemTitle(transactionItem: TransactionItem): string {
  return parseDisplayTitle({ code: transactionItem.accountCode, name: transactionItem.accountName })
}

export async function fetchTransactionItemsByIds(ids: string[], data?: Record<string, any>) {
  if (!ids?.length) return []

  const { isSuccess, result } = await safeFetchJson<TransactionItemApi>(
    buildGetUrl(`/new_api/accounting/transactions/items/${ids}`, data),
  )

  return isSuccess && !isJob(result) ?
    result.map((transactionItem) => parseTransactionItem(transactionItem)) :
    []
}

export function fetchTransactionItems(fetchData?: Record<string, any>) {
  return async function fetchTransactionItemsThunk(dispatch: AppDispatch) {
    const data = await _fetchTransactionItems(fetchData)
    dispatch(setTransactionItems({ data }))
    return data
  }
}

export async function _fetchTransactionItems(fetchData: Record<string, any>) {
  let transactionItems = []

  try {
    const { isSuccess, result } = await safeFetchJson<TransactionItemApi>(
      buildGetUrl('/new_api/accounting/transactions/items', fetchData),
    )
    if (isSuccess && !isJob(result)) {
      transactionItems = result.map((transactionItem) => parseTransactionItem(transactionItem))
    }
  } catch (err) {
    console.error(err)
  }

  return transactionItems
}

export function fetchTransactionItemsCount(fetchData?: Record<string, any>) {
  return async function fetchTransactionItemsCountThunk(dispatch: AppDispatch) {
    const count = await _fetchTransactionItemsCount(fetchData)
    dispatch(setTransactionItemsCount({ count }))
    return count
  }
}

export async function _fetchTransactionItemsCount(fetchData?: Record<string, any>) {
  let count = 0

  try {
    const { isSuccess, result } = await safeFetchJson<{count: string}>(
      buildGetUrl('/new_api/accounting/transactions/items/count', fetchData),
    )
    if (isSuccess && !isJob(result)) {
      count = +result[0].count || 0
    }
  } catch (err) {
    console.error(err)
  }

  return count
}

export function parseTransactionItem(transactionItem: TransactionItemApi): TransactionItem {
  const options = {
    defaultData: getDefaultTransactionItem(),
    fields: initialState.itemFields,
    dataSetName: itemDataSetName,
  }

  return parse(transactionItem, options)
}

function getDefaultTransactionItem(): TransactionItem {
  return parse({}, { fields: initialState.itemFields })
}

export function exchangeRateToDisplay(exchangeRate: number, culture: string) {
  const exchangeRateDigits = { minimumFractionDigits: 2, maximumFractionDigits: 8 }
  return parseNumber(exchangeRate, exchangeRateDigits, culture)
}

export default transactionsSlice.reducer
