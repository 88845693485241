import { ApiToSlice, BaseEntityApi, GetFields, Modify } from 'types/slices'

import { buildGetUrl, parse } from 'utils/api'
import { isJob, safeFetch, safeFetchJson } from 'utils/safeFetch'

const dataSetName = 'chartOfAccounts'
const fields = getFields()
const initialState = {
  dataSetName,
  fields,
}

export default function chartsOfAccountsReducer(state = initialState, action) {
  switch (action.type) {
  default: {
    return state
  }
  }
}

export type ChartOfAccountApi = Modify<{
  company_id: string
  name: string
  code: string
  description: string
  type: string
  external_id: string
  external_type: string
}, BaseEntityApi>

export type ChartOfAccount = ApiToSlice<Modify<ChartOfAccountApi, {display_title: string}>>

// companies exports
export function getFields(): GetFields<ChartOfAccountApi, ChartOfAccount> {
  return {
    id: { dataSetName, dbField: 'id' },
    exist: { dataSetName, dbField: 'exist' },
    companyId: { dataSetName, dbField: 'company_id' },
    name: { dataSetName, dbField: 'name' },
    code: { dataSetName, dbField: 'code' },
    description: { dataSetName, dbField: 'description' },
    type: { dataSetName, dbField: 'type' },
    externalId: { dataSetName, dbField: 'external_id' },
    externalType: { dataSetName, dbField: 'external_type' },
    displayTitle: { parse: parseDisplayTitle },
  }
}

export type FetchChartsOfAccountsData = {
  orderByList?: string
  types?: string[]
}
export async function fetchChartsOfAccounts(data: FetchChartsOfAccountsData = {}) {
  let chartsOfAccounts = []

  try {
    const result = await (await safeFetch(buildGetUrl('/new_api/accounting/charts-of-accounts', data))).json()
    if (result.isSuccess) {
      chartsOfAccounts = result.result.map((chartOfAccount) => parseChartOfAccounts(chartOfAccount))
    }
  } catch (err) {
    console.error(err)
  }

  return chartsOfAccounts
}

export async function fetchChartsOfAccountByIds(ids: (string|number)[]) {
  if (!ids?.length) return []

  const { isSuccess, result } = await safeFetchJson<ChartOfAccountApi>(
    buildGetUrl(`/new_api/accounting/charts-of-accounts/${ids}`),
  )

  return isSuccess && !isJob(result) ? result.map((chartOfAccounts) => parseChartOfAccounts(chartOfAccounts)) : []
}

// parsing functions
export function parseChartOfAccounts(chartOfAccounts: ChartOfAccountApi): ChartOfAccount {
  const options = {
    defaultData: parse({}, { fields }),
    fields: initialState.fields,
    dataSetName,
  }
  return parse(chartOfAccounts, options)
}

export function parseDisplayTitle(chartOfAccounts: Pick<ChartOfAccountApi, 'code' | 'name'>) {
  return `${chartOfAccounts.code ? `[ ${chartOfAccounts.code} ] ` : ''}${chartOfAccounts.name}`
}
