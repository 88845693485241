import { ApiToSlice, BaseEntityApi, GetFields, Modify } from 'types/slices'

import { IconName } from '@fortawesome/fontawesome-svg-core'

export type MapData = {
  primaryLanguage?: string,
  secondaryLanguage?: string,
}

export const statuses = ['active', 'inactive'] as const
type Status = typeof statuses[number]

export type InventoryConditionApi = Modify<{
  id: string,
  is_planable: boolean,
  is_sellable: boolean,
  is_consumable: boolean,
  name: string,
  secondary_name: string,
  color: string,
  status: Status,
  is_default: boolean,
  icon_class: IconName,
}, BaseEntityApi>

export type InventoryCondition = ApiToSlice<InventoryConditionApi>

export type InventoryConditionGetFields = GetFields<InventoryConditionApi, InventoryCondition, MapData>
