
import { AppDispatch } from 'store'
import { ApiToSlice, BaseEntityApi, GetFields, Modify } from 'types/slices'
import { v4 as uuid } from 'uuid'

import { buildGetUrl, parse } from 'utils/api'
import { dataToFormData, formDataToArray } from 'utils/mapperHelper'
import { isJob, parseError, safeFetch, safeFetchJson } from 'utils/safeFetch'

import {
  CREATE_CUSTOM_IDENTITY_PROVIDER,
  DELETE_CUSTOM_IDENTITY_PROVIDER,
  GET_CUSTOM_IDENTITY_PROVIDER,
  GET_CUSTOM_IDENTITY_PROVIDERS,
  UPDATE_CUSTOM_IDENTITY_PROVIDER,
} from './types'

type CustomIdentityProviderState = { id: string, name: string, status: string }
type State = {
  customIdentityProviders: CustomIdentityProviderState[],
  activeCustomIdentityProviders: CustomIdentityProviderState,
  fields: Record<string, any>
}

const dataSetName = 'customIdentityProviders'

export const initialState: State = {
  customIdentityProviders: [],
  activeCustomIdentityProviders: null,
  fields: getFields(),
}

export default function customIdentityProvidersReducer(state = initialState, action) {
  const { payload } = action

  switch (action.type) {
  case GET_CUSTOM_IDENTITY_PROVIDERS: {
    return {
      ...state,
      customIdentityProviders: payload as CustomIdentityProviderState[],
    }
  }
  case GET_CUSTOM_IDENTITY_PROVIDER:
  case CREATE_CUSTOM_IDENTITY_PROVIDER:
  case UPDATE_CUSTOM_IDENTITY_PROVIDER: {
    return buildCustomIdentityProvidersState(state, payload)
  }
  default: {
    return state
  }
  }
}

export type CustomeIdentityProviderApi = Modify<{
  name: string
  notes: string
  status: string
  details: Record<string, any>
}, BaseEntityApi>

export type CustomeIdentityProvider = ApiToSlice<
  Modify<CustomeIdentityProviderApi, {tenant_id: string, client_id: string, client_secret: string}>
>

export function getFields(): GetFields<CustomeIdentityProviderApi, CustomeIdentityProvider> {
  return {
    'id': { dataSetName, dbField: 'id', type: 'id' },
    'exist': { dataSetName, dbField: 'exist', type: 'boolean' },

    'createdBy': { dataSetName, dbField: 'created_by', type: 'string' },
    'createdById': { dataSetName, dbField: 'created_by_id', type: 'id', relationEntity: 'resources' },
    'createdDate': { dataSetName, dbField: 'created_date', type: 'date' },
    'modifiedBy': { dataSetName, dbField: 'modified_by', type: 'string' },
    'modifiedById': { dataSetName, dbField: 'modified_by_id', type: 'id', relationEntity: 'resources' },
    'modifiedDate': { dataSetName, dbField: 'modified_date', type: 'date' },

    'name': { dataSetName, dbField: 'name', type: 'string' },
    'notes': { dataSetName, dbField: 'notes', type: 'string' },
    'status': { dataSetName, dbField: 'status', type: 'string' },
    'details': { dataSetName, dbField: 'details', type: 'json' },

    'tenantId': {
      parseWithParsedData: (customIdentityProvider) => customIdentityProvider.details?.tenantId,
    },
    'clientId': {
      parseWithParsedData: (customIdentityProvider) => customIdentityProvider.details?.clientId,
    },
    'clientSecret': {
      parseWithParsedData: (customIdentityProvider) => customIdentityProvider.details?.clientSecret,
    },
  }
}

export function fetchCustomIdentityProviders() {
  return async function fetchCustomIdentityProvidersThunk(dispatch: AppDispatch) {
    const { error, customIdentityProviders } = await _fetchCustomIdentityProviders()
    dispatch({ type: GET_CUSTOM_IDENTITY_PROVIDERS, payload: customIdentityProviders, error })
    return customIdentityProviders
  }
}

export async function _fetchCustomIdentityProviders(ids?: string[]) {
  let customIdentityProviders = []
  let error = null

  try {
    let url = '/new_api/hub/custom-idp'
    if (ids?.length > 0) {
      url += `/${ids}`
    }
    const { isSuccess, result } = await safeFetchJson(buildGetUrl(url))

    if (isSuccess && !isJob(result)) {
      customIdentityProviders = result.map((customIdentityProvider: CustomIdentityProviderState) =>
        parseCustomIdentityProvider(customIdentityProvider),
      )
    }
    if (!isSuccess) error = result
  } catch (_error) {
    error = parseError(_error)
    console.error(_error)
  }

  return { customIdentityProviders, error }
}

export async function fetchCustomIdentityProvidersByIds(ids?: string[]) {
  if (!ids?.length) return []

  const url = `/new_api/hub/custom-idp/${ids}`

  const { isSuccess, result } = await safeFetchJson(buildGetUrl(url))
  return isSuccess && !isJob(result) ?
    result.map((customIdentityProvider: CustomIdentityProviderState) =>
      parseCustomIdentityProvider(customIdentityProvider)) :
    []
}

export function createCustomIdentityProviderFromForm(formData: any) {
  let changedData = formDataToArray({ [uuid()]: formData }, false, true, false)[0]
  changedData = _formatCustomIdentityProviderForm(changedData)
  return createCustomIdentityProvider(changedData)
}

export function createCustomIdentityProvider(customIdentityProvider: any) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-type': 'application/json' },
    body: JSON.stringify({ customIdentityProvider }),
  }
  return async function createCustomIdentityProviderThunk(dispatch) {
    try {
      const result = await (await safeFetch(`/new_api/hub/custom-idp`, requestOptions)).json()
      const created = result.isSuccess ? result.result : null
      const payload = created ? parseCustomIdentityProvider(created) : null
      dispatch({ type: CREATE_CUSTOM_IDENTITY_PROVIDER, payload })

      return result
    } catch (error) {
      dispatch({ type: CREATE_CUSTOM_IDENTITY_PROVIDER, payload: null })
      return parseError(error)
    }
  }
}

export function activateCustomIdentityProvider(id: string) {
  return updateCustomIdentityProvider(id, { status: 'active' })
}

export function deactivateCustomIdentityProvider(id: string) {
  return updateCustomIdentityProvider(id, { status: 'inactive' })
}

export function updateCustomIdentityProviderFromForm(id: string, formData: any) {
  let changedData = formDataToArray({ [id]: formData }, false, true, false)[0]
  changedData = _formatCustomIdentityProviderForm(changedData)
  return updateCustomIdentityProvider(id, changedData, false)
}

export function updateCustomIdentityProvider(id: string, customIdentityProvider: any, dispatchError = true) {
  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ customIdentityProvider }),
  }
  return async function updateCustomIdentityProviderThunk(dispatch) {
    try {
      const result = await (await safeFetch(`/new_api/hub/custom-idp/${id}`, requestOptions)).json()
      const updated = result.isSuccess ? result.result : null
      const payload = updated ? parseCustomIdentityProvider(updated) : null
      const error = !result.isSuccess && dispatchError ? result.result : null
      dispatch({ type: UPDATE_CUSTOM_IDENTITY_PROVIDER, payload, error })
      return result
    } catch (error) {
      if (dispatchError) dispatch({ type: UPDATE_CUSTOM_IDENTITY_PROVIDER, error })
      return parseError(error)
    }
  }
}

export function deleteCustomIdentityProviders(id: string) {
  const requestOptions = {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
  }
  return async function deleteCustomIdentityProvidersThunk(dispatch) {
    try {
      const result = await (await safeFetch(
        `/new_api/hub/custom-idp/${id}`,
        requestOptions,
      )).json()
      const error = result.isSuccess ? null : result.result
      dispatch({ type: DELETE_CUSTOM_IDENTITY_PROVIDER, payload: result.isSuccess, error })

      return result
    } catch (error) {
      dispatch({ type: DELETE_CUSTOM_IDENTITY_PROVIDER, error })
    }
  }
}

export function parseCustomIdentityProvider(customIdentityProvider: CustomIdentityProviderState) {
  const options = {
    defaultData: parse({}, { fields: initialState.fields }),
    fields: initialState.fields,
    dataSetName,
  }

  return parse(customIdentityProvider, options)
}

function buildCustomIdentityProvidersState(state, payload) {
  if (!payload) {
    return state
  }

  const globalForm = dataToFormData(payload, getFields())
  const newActiveForm = {
    ...state.activeForm,
    isCreate: false,
    isValid: isFormValid(globalForm),
    global: globalForm,
  }

  return {
    ...state,
    activeCustomIdentityProviders: payload,
    activeForm: {
      ...newActiveForm,
      hasChanges: hasChanges(newActiveForm),
    },
  }
}

export function getCustomIdentityProviderTitle(customIdentityProviders) {
  return customIdentityProviders.name
}

export function getFormChangedValueDict(formData): any[] {
  return formDataToArray(formData, false, true, true)
}

export function isFormValid(globalForm) {
  return !!globalForm?.name?.value && !!globalForm?.clientId?.value && !!globalForm?.clientSecret?.value
}

export function hasChanges(globalForm) {
  return Object.keys(globalForm).some((key) => globalForm[key].isChanged)
}

/**
 * Return a new formated customIdentityProvider object with the details data moved into the details attribute
 */
function _formatCustomIdentityProviderForm(customIdentityProvider) {
  let { tenantId, clientId, clientSecret, status, ...others } = customIdentityProvider
  if (typeof status === 'boolean') {
    status = status ? 'active' : 'inactive'
  }

  return {
    ...others,
    status: status,
    details: {
      tenantId,
      clientId,
      clientSecret,
    },
  }
}
