
import { buildGetUrl, parse } from 'utils/api'
import { safeFetch } from 'utils/safeFetch'

import {
  GET_VALUE_STREAMS,
} from './types'

const dataSetName = 'valuestreams'

const initialState = {
  dataSetName,
  fields: getFields(),
  valueStreams: [],
}

export default function valueStreamsReducer(state = initialState, action) {
  const { payload } = action
  switch (action.type) {
  case GET_VALUE_STREAMS: {
    return {
      ...state,
      valueStreams: payload,
    }
  }
  default: {
    return state
  }
  }
}

export function getFields() {
  return {
    'id': { dataSetName, dbField: 'id' },
    'isExist': { dataSetName, dbField: 'exist' },
    'cid': { dataSetName, dbField: 'cid' },
    'title': { dataSetName, dbField: 'title' },
    'description': { dataSetName, dbField: 'description' },
    'ownerId': { dataSetName, dbField: 'ownerid' },
    'createdDate': { dataSetName, dbField: 'created_date' },
    'modifiedDate': { dataSetName, dbField: 'modified_date' },
    'rank': { dataSetName, dbField: 'rank' },
    'color': { dataSetName, dbField: 'color' },
    'createdBy': { dataSetName, dbField: 'created_by' },
    'createdById': { dataSetName, dbField: 'created_by_id' },
    'modifiedBy': { dataSetName, dbField: 'modified_by' },
    'modifiedById': { dataSetName, dbField: 'modified_by_id' },
    'type': { dataSetName, dbField: 'type' },
  }
}

export async function _fetchValueStreams(data = {}) {
  let valueStreams = []
  try {
    const result = await (await safeFetch(buildGetUrl('/new_api/valuestreams', data))).json()
    if (result.isSuccess) {
      valueStreams = result.result
        .map((valueStreams) => parseValueStream(valueStreams))
    }
  } catch (error) {
    console.error(error)
  }

  return valueStreams
}

export function fetchValueStreams(data = {}) {
  return async function fetchValueStreamThunk(dispatch) {
    const valueStreams = await _fetchValueStreams(data)
    dispatch({ type: GET_VALUE_STREAMS, payload: valueStreams })
  }
}

export async function _fetchValueStreamCount(data) {
  let count = 0

  try {
    const result = await (await safeFetch(buildGetUrl('/new_api/valuestreams/count', data))).json()
    if (result.isSuccess) {
      count = +result.result[0].count || 0
    }
  } catch (err) {
    console.error(err)
  }

  return count
}

export async function _fetchValueStreamsIndex(id, data) {
  let index = 0

  if (id) {
    try {
      const result = await (await safeFetch(buildGetUrl(`/new_api/valuestreams/${id}/index`, data))).json()
      if (result.isSuccess) {
        index = +result.result || 0
      }
    } catch (err) {
      console.error(err)
    }
  }

  return index
}

export function parseValueStream(valueStream) {
  const options = {
    defaultData: getDefaultValueStream(),
    fields: initialState.fields,
    dataSetName: dataSetName,
  }
  return parse(valueStream, options)
}

export function getDefaultValueStream() {
  return parse({}, { fields: initialState.fields })
}

export function getValueStreamMultiSelectOptions({ fetchData = {} }) {
  return {
    key: 'valueStreams',
    fetcher: _fetchValueStreams,
    fields: initialState.fields,
    fetchData,
  }
}
