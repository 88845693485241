import React from 'react'

import moment from 'moment'
import { v4 as uuid } from 'uuid'

import { isDarkColor } from './colorUtils'
import { dateToDisplay } from './dateParser'
import { DevLogs } from './devLogs'
import { getEntityObject, getExtraTranslationKey, getFieldTranslationKey } from './entities'
import { getEntityFieldInFields } from './mapperHelper'
import { parseNumber, parseCurrency, parsePercentage } from './numberParser'
import { toCamel } from './stringUtils'
import { convertFromDollarPerBase, convertFromBase } from './unitConverter'

/**
 * field documentation
 *
 * A field in the `getFields()` function can have these properties related to the <SmartHistory /> component:
 *
 * - `type`: The type of the field. Can be one of the following:
 *    - `string`: (default) The field is a string.
 *    - `date` & `timestamp` The field is a date or a timestamp.
 *            For now they are formatted the same way
 *    - `currency`: The field should be formatted as a currency.
 *    - `measure`: The field should be formatted as a measure.
 *    - `boolean`: The field is a boolean.
 *    - `integer`: The field is an integer.
 *    - `float`: The field is a float.
 *    - `id`: The field is an id related to another entity.
 *    - `json`: The field is a json object.
 *    - `array`: The field is an array.
 *    - `html`: The field is a html string.
 *    - `language`: The field is a language string. Will be formatted like `en-US` => `en`
 *
 *
 * - `customEventValueTranslationKey(value | null)`: The field value will be render using the translation key returned.
 *            If you need another namespace than the one of the entity, use the `namespace` property to load it.
 *
 *            The value will be null to indicate that the field is/was empty.
 *
 *
 * - `customFieldTranslationKey(t, options*)`: The field will be render using the translation key returned.
 *
 *
 * -  `customFormattedValue(value | null, options*, entityData)`: The field will be render using the value returned.
 *
 *             The value will be null to indicate that the field is/was empty.
 *
 *
 *     [type `id` only]
 * - `relationEntity`: The name of the entity related to the field.
 *            It should match the `entityName` of the event and the key in the dictionnary `entities`.
 *
 *
 *    [type `json` only]
 * -  `properties`: An another `getFields()` like object. It is used to find the properties of the json object.
 *
 *
 * -  `isNotUserFriendly`: This property is used to indicate that the field
 *                     is not used by the user and we should hide it.
 *
 *                     Ex: `instructions` and `trimmedInstructions`.
 *                     We would set `isNotUserFriendly` to `true` for `trimmedInstructions` to hide it.
 *
 *                     ! Note : This will not hide the entire event, only the event detail for this field.
 *     *options
 *
 *     {
          "primaryLanguage": "Français",
          "secondaryLanguage": "English",
          "language": "en",
          "priceDigits": {
              "minimumFractionDigits": 2,
              "maximumFractionDigits": 5
          },
          "measureDigits": {
              "minimumFractionDigits": 2,
              "maximumFractionDigits": 5
          },
          "unit": {
              "qty": "un",
              "weight": "lb",
              "length": "po",
              "surface": "m2",
              "volume": "L",
              "time": "minute"
          },
          "fetched": {
              "id": [],
              "onExpandCalled": true
          },
          "baseCurrency": {
            "id": 569,
            "exist": true,
            "name": "Canadian Dollar",
            "code": "CAD",
            "symbol": "CAD",
            "precision": 2,
            "isBase": true,
            "exchangeRate": 1
          },
}
 */

export const isHandledEntity = (entityName) => {
  const entity = getEntityObject(entityName)

  if (!entity) {
    return false
  }

  return true
}

export const getNameKeyOfEntity = (entityName, options = {}) => {
  const entity = getEntityObject(entityName)

  if (!entity) {
    DevLogs.error('getNameKeyOfEntity', `Entity \`${entityName}\` not found.`)

    return entityName
  }

  return getFieldTranslationKey(entity, toCamel(getRelationName(entityName)), options)
}

export const getDetailsFromUpdates = (event) => {
  const details = event.transactionDetails
    .sort((a, b) => Date.parse(a.createdDate) - Date.parse(b.createdDate))
    .reduce((acc, event) => [
      ...acc,
      ...(event.details?.updates || [])
        .map((update) => ({
          ...update,
          field: toCamel(update.field),
          entityId: event.entityId,
          entityName: event.entityName,
          type: event.type,
          id: uuid(),
        })),
      ...(event.type === 'create' ? [{
        ...event,
        field: undefined,
      }] : []),
    ], [])

  // If the event has only one detail and it is a create event,
  // we return an empty array because the summary will be enough
  if (
    details.length === 1 &&
      details[0].type === 'create' &&
      details[0].entityName === event.entityName
  ) {
    return []
  }

  return details
}

export const getEntityDataInFetchedEntities = (field, value, entityField, fetched, entityName, detail) => {
  const realEntityKey = entityName

  // ! `==` to match int id as string. ex: '3616' == 3616
  const entityData = (fetched[realEntityKey] ?? []).find((entity) => entity.id == value || entity.relationId == value)

  if (!entityData) {
    // * FOR DEBUG
    DevLogs.error(`No data found for \`${field}\` with id \`${value}\`.`, { fetched, realEntityKey, value })
    return value
  }

  return entityData
}

export const getTitleOfEntity = (field, value, entityField, fetched, entityName, t) => {
  const key = entityField?.relationEntity ?? entityName

  // ! `==` to match int id as string. ex: '3616' == 3616
  const fetchedData = (fetched[key] ?? []).find((entity) => entity.id == value || entity.relationId == value)

  if (!fetchedData) {
    // * FOR DEBUG
    DevLogs.error(`No data found for \`${field}\` with id \`${value}\`.`)
    return null
  }

  const entity = getEntityObject(key)

  if (!entity) {
    DevLogs.error(`No entity found for \`${key}\`.`)

    return null
  }

  return entity.getTitle?.(fetchedData, field, t) || 'N/A'
}

export const getFormattedFieldValue = (t, field, value, entityField, options, fetched, entityData, entityName) => {
  if (!entityField) {
    // * FOR DEBUG
    DevLogs.error(`No entityField for field \`${field}\` with value \`${value}\`.`)

    return value
  }

  if (entityField.customEventValueTranslationKey && typeof entityField.customEventValueTranslationKey === 'function') {
    return (
      handleTranslation(
        t,
        entityField.customEventValueTranslationKey(value),
        value,
      ).toLowerCase()
    )
  }

  if (entityField.customFormattedValue && typeof entityField.customFormattedValue === 'function') {
    return entityField.customFormattedValue(value, options, entityData)
  }

  const type = entityField.type ?? 'string'

  const entityObject = getEntityObject(entityName)

  const dimension = entityObject.getDimension(entityData, field, entityField) ?? 'qty'
  const unit = entityObject.getUnit(entityData, field, entityField) ?? options.defaultUnits[dimension]

  switch (type) {
  case 'status':
  case 'color':
  case 'string':
    if (entityField.translationNameSpace) {
      return handleTranslation(t, `${entityField.translationNameSpace}${value}`).toLowerCase()
    } else if (entityField.translationPath) {
      return handleTranslation(t, `${entityField.translationPath}.${value}`).toLowerCase()
    }
    return value
  case 'date':
  case 'timestamp':
    return Date.parse(value) ? dateToDisplay(
      moment(value),
      { language: options.language, showTime: type === 'timestamp', culture: options.culture },
    ) : value
  case 'currency': {
    const convertFromBase = convertFromDollarPerBase(
      dimension, +value || 0,
      unit)

    return parseCurrency(
      convertFromBase,
      options.baseCurrency.code,
      options.baseCurrency.symbol,
      options.priceDigits.minimumFractionDigits,
      options.culture)
  }
  case 'measure': {
    return `${parseNumber(
      convertFromBase(dimension, +value || 0, unit, false),
      options.measureDigits,
      options.culture,
    )} ${unit}`
  }
  case 'boolean':
    const formattedBool = String(value).toLocaleUpperCase() === 'TRUE' ? true : false

    return t(`common:boolean.${formattedBool}`).toLowerCase()
  case 'integer':
    return value
  case 'float':
    return parseNumber(
      +value,
      options.measureDigits.minimumFractionDigits,
      options.culture)
  case 'id':
    return getTitleOfEntity(field, value, entityField, fetched, entityName, t)
  case 'html': {
    const div = document.createElement('div')

    div.innerHTML = value

    return `\n${div.innerText}`
  }
  case 'text': {
    return `\n${value}`
  }
  case 'array': {
    return Array.isArray(value) ? value.join(', ') : ''
  }
  case 'percentage': {
    const discount = (+value || 0) / 100
    return parsePercentage(discount, 1)
  }
  case 'language': {
    const langKey = value.split('-')[0].toLowerCase()
    return handleTranslation(t, `common:languages.${langKey}`, langKey).toLowerCase()
  }
  default:
    // * FOR DEBUG
    DevLogs.error(`Type \`${entityField.type}\` is not handled (field: \`${field}\` with value \`${value}\`).`)

    return value
  }
}

export const getFormattedDefaultFieldValue = (t, field, entityField, options, fetched, entityData, entityName) => {
  if (!entityField) {
    // * FOR DEBUG
    DevLogs.error(`No entityField for field \`${field}\` with value \`${value}\`.`)

    return handleTranslation(t, 'common:empty').toLowerCase()
  }

  if (entityField.customEventValueTranslationKey && typeof entityField.customEventValueTranslationKey === 'function') {
    return (
      handleTranslation(
        t,
        entityField.customEventValueTranslationKey(null),
        handleTranslation(t, 'common:empty').toLowerCase(),
      ).toLowerCase()
    )
  }

  if (entityField.customFormattedValue && typeof entityField.customFormattedValue === 'function') {
    return entityField.customFormattedValue(null, options, entityData)
  }

  const value = 0

  const entityObject = getEntityObject(entityName)
  const unit = entityObject.getUnit(entityData, field, entityField)
  const dimension = entityObject.getDimension(entityData, field, entityField)

  switch (entityField.type) {
  case 'currency': {
    const convertFromBase = convertFromDollarPerBase(
      dimension, +value || 0,
      unit)

    return parseCurrency(
      convertFromBase,
      options.baseCurrency.code,
      options.baseCurrency.symbol,
      options.priceDigits.minimumFractionDigits,
      options.culture)
  }
  case 'measure': {
    return `${parseNumber(
      convertFromBase(dimension, +value || 0, unit, false),
      options.measureDigits,
      options.culture,
    )} ${unit}`
  }
  case 'boolean':
    return handleTranslation(t, 'common:boolean.false').toLowerCase()
  case 'integer':
    return value
  case 'float':
    return parseNumber(
      +value,
      options.measureDigits.minimumFractionDigits,
      options.culture)
  case 'percentage': {
    const discount = (+value || 0) / 100
    return parsePercentage(discount, 1)
  }
  default:
    return handleTranslation(t, 'common:empty').toLowerCase()
  }
}

export const getFieldComponentWrapper = (value, entityField) => {
  switch (entityField.type ?? 'string') {
  case 'color':
    return (
      <span
        className={
          `a-smart-history-value-color a-smart-history-value-color-${isDarkColor(value) ? 'dark' : 'bright'}`
        }
        style={{ '--field-color': value }}
      />
    )
  default:
    return null
  }
}

export const removeIdOfFieldName = (field) => {
  return field?.replaceAll(/Id$/g, '') ?? ''
}

export const getFieldTranslation = (t, fieldName, eventEntityfields, entityName, options = {
  fallbackToId: false,
  language: 'fr',
}) => {
  const entityField = getEntityFieldInFields(fieldName, eventEntityfields, options)

  if (!entityField) {
    // * FOR DEBUG
    DevLogs.error(`No entityField for field \`${fieldName}\`.`, { entityName, options })

    return fieldName
  }

  const entityObject = getEntityObject(entityName)

  if (!entityObject) return fieldName

  if (entityField.customFieldTranslationKey && typeof entityField.customFieldTranslationKey === 'function') {
    return entityField.customFieldTranslationKey(t, options)
  } else {
    const field = toCamel(entityField?.type === 'id' ? removeIdOfFieldName(fieldName) : fieldName)

    return handleTranslation(
      t,
      getFieldTranslationKey(entityObject, field),
      field,
    )
  }
}

export const getExtraTranslation = (t, fieldName, eventEntityfields, entityName, extraName, options = {
  fallbackToId: false,
  language: 'fr',
}, translationParams = {}) => {
  const entityField = getEntityFieldInFields(fieldName, eventEntityfields, options)

  if (!entityField) {
    // * FOR DEBUG
    DevLogs.error(`No entityField for field \`${fieldName}\`.`, { entityName, options, extraName })

    return extraName
  }

  const entityObject = getEntityObject(entityName)

  if (!entityObject) return extraName

  if (entityField.customExtraTranslationKey && typeof entityField.customExtraTranslationKey === 'function') {
    return entityField.customExtraTranslationKey(t, options, translationParams)
  } else {
    const field = toCamel(entityField?.type === 'id' ? removeIdOfFieldName(fieldName) : fieldName)

    return handleTranslation(
      t,
      getExtraTranslationKey(entityObject, field, extraName),
      field,
      translationParams,
    )
  }
}

export const fetchEntityIds = async(key, ids, fetcherOptions) => {
  if (!ids?.length) {
    return []
  }

  const entity = getEntityObject(key)

  if (!entity) {
    DevLogs.error(`Entity \`${key}\` not found.`)
    return []
  }

  return entity.fetcher?.(ids, fetcherOptions)
}

export const getRelationName = (entityName, entityObject) => {
  const _entityObject = entityObject ?? getEntityObject(entityName)
  return _entityObject?.relationName ?? _entityObject?.coreRelationName ?? entityName
}

/**
 * inventories' => 'inventoryId'
 *
 * @param {string} entityName
 * @return {string}
 */
export const getFieldIdFromRelation = (entityName) => {
  return `${getRelationName(entityName)}Id`
}

export const reduceEventToDetails = (event) => {
  // ! SHIPPED EVENT

  return event.transactionDetails ?? []

  const eventsDetails = event.transactionDetails?.reduce((acc, d) => {
    const updates = d.details.updates ?? []

    if (!updates.length) {
      return [...acc, d]
    } else {
      const mappedUpdates = updates.map(
        (update) => ({
          ...d,
          ...update,
          id: uuid(),
          transactionDetails: [],
        }))

      return [...acc, ...mappedUpdates]
    }
  }, []) ?? []

  return eventsDetails
}

/**
 * Handle the translation of a key.
 *
 * @param {object} t
 * @param {string|string[]} key
 * @param {string} [fallbackText]
 * @param {object} [translationParams]
 * @return {string}
 */
export const handleTranslation = (t, key, fallbackText = null, translationParams = {}) => {
  const options = {
    ...translationParams,
    defaultValue: fallbackText,
  }
  const result = t(key, options)

  if ((
    result?.includes('.') &&
    (key.includes(result) || (Array.isArray(key) && key.some((k) => k.includes(result))))
  ) || result === fallbackText) {
    // * FOR DEBUG

    DevLogs.error(`No translation found for \`${key}\`.`)

    return result
  }

  return result
}

export const camelToSpaces = (str) => {
  return str.replace(/([A-Z]+)/g, ' $1').replace(/([A-Z][a-z])/g, ' $1')
}

export const capitalize = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export const getEntityTranslationFile = (entityName) => {
  return getEntityObject(entityName)?.translationFile ?? `${entityName}s`
}

export const isDeleteEvent = (event, details) => {
  if (!event.type.startsWith('update') || details.length !== 1 ) return false

  const detail = details[0]

  return detail.field === 'exist' && detail.old_value === true && detail.new_value === false
}
