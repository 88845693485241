import { createSlice } from '@reduxjs/toolkit'
import { AppDispatch } from 'store'

import { buildGetUrl, parse } from 'utils/api'
import { isJob, safeFetchJson } from 'utils/safeFetch'

import {
  InventoryCondition,
  InventoryConditionApi,
  InventoryConditionGetFields,
  MapData,
  statuses,
} from './types'

const dataSetName = 'inventoryConditionView'

const initialState = {
  dataSetName,
  fields: getFields(),
  inventoryConditionsCount: 0,
  inventoryConditions: [],
}

const inventoryConditionsSlice = createSlice({
  name: 'inventoryConditions',
  initialState,
  reducers: {
    setInventoryConditions: (state, action) => {
      state.inventoryConditions = action.payload.data
    },
    setInventoryConditionsCount: (state, action) => {
      state.inventoryConditionsCount = action.payload.count
    },
  },
})

export const { setInventoryConditions, setInventoryConditionsCount } = inventoryConditionsSlice.actions

export function getFields(editOnly = false): InventoryConditionGetFields {
  const editFields: InventoryConditionGetFields = {
    id: { dataSetName, dbField: 'id', type: 'string' },
    isPlanable: { dataSetName, dbField: 'is_planable', type: 'boolean', isEdit: true, formDefaultValue: true },
    isSellable: { dataSetName, dbField: 'is_sellable', type: 'boolean', isEdit: true, formDefaultValue: true },
    isConsumable: { dataSetName, dbField: 'is_consumable', type: 'boolean', isEdit: true, formDefaultValue: true },
    iconClass: { dataSetName, dbField: 'icon_class', isEdit: true },
    name: {
      dataSetName,
      dbField: 'name',
      type: 'string',
      isEdit: true,
      headerOptions: { language: 'primaryLanguage' },
      customFieldTranslationKey: (t, options) => t(
        'inventoryConditions:inventoryCondition.fields.name.label',
        { language: options?.primaryLanguage },
      ),
    },
    secondaryName: {
      dataSetName,
      dbField: 'secondary_name',
      type: 'string',
      isEdit: true,
      headerOptions: { language: 'secondaryLanguage' },
      customFieldTranslationKey: (t, options) => t(
        'inventoryConditions:inventoryCondition.fields.name.label',
        { language: options?.secondaryLanguage },
      ),
    },
    color: { dataSetName, dbField: 'color', type: 'color', formDefaultValue: '#a8a8a8', isEdit: true },
    status: {
      dataSetName,
      dbField: 'status',
      type: 'status',
      isEdit: true,
      dictionaryKey: 'inventoryCondition',
      values: [...statuses],
      translationPath: 'inventoryConditions:status',
      customEventValueTranslationKey: (value) => `inventoryConditions:status.${value}`,
    },
    isDefault: { dataSetName, dbField: 'is_default', type: 'boolean', isEdit: false },
  }

  if (editOnly) return editFields

  const fields: InventoryConditionGetFields = {
    exist: { dataSetName, dbField: 'exist', type: 'boolean' },
    createdDate: { dataSetName, dbField: 'created_date', type: 'timestamp' },
    createdBy: { dataSetName, dbField: 'created_by', type: 'string' },
    createdById: { dataSetName, dbField: 'created_by_id', type: 'string' },
    modifiedDate: { dataSetName, dbField: 'modified_date', type: 'timestamp' },
    modifiedBy: { dataSetName, dbField: 'modified_by', type: 'string' },
    modifiedById: { dataSetName, dbField: 'modified_by_id', type: 'string' },
  }

  return { ...fields, ...editFields }
}

export function getInventoryConditionTitle(inventoryCondition: InventoryCondition): string {
  return inventoryCondition.name
}

export async function fetchInventoryConditionsByIds(
  ids: InventoryCondition['id'][],
  mapData?: MapData,
  data?: Record<string, any>,
) {
  if (!ids?.length) return []

  const { isSuccess, result } = await safeFetchJson<InventoryConditionApi>(
    buildGetUrl(`/new_api/inventory-conditions/${ids}`, data),
  )

  return isSuccess && !isJob(result) ?
    result.map((inventoryCondition) => parseInventoryCondition(inventoryCondition, mapData)) :
    []
}

export function fetchInventoryConditions(mapData: MapData, fetchData?: Record<string, any>) {
  return async function fetchInventoryConditionsThunk(dispatch: AppDispatch) {
    const data = await _fetchInventoryConditions(fetchData, mapData)
    dispatch(setInventoryConditions({ data }))
    return data
  }
}

export async function _fetchInventoryConditions(fetchData: Record<string, any>, mapData: MapData) {
  let inventoryConditions = []

  try {
    const { isSuccess, result } = await safeFetchJson<InventoryConditionApi>(
      buildGetUrl('/new_api/inventory-conditions', fetchData),
    )
    if (isSuccess && !isJob(result)) {
      inventoryConditions = result.map((inventoryCondition) => parseInventoryCondition(inventoryCondition, mapData))
    }
  } catch (err) {
    console.error(err)
  }

  return inventoryConditions
}

export function fetchInventoryConditionsCount(fetchData?: Record<string, any>) {
  return async function fetchInventoryConditionsCountThunk(dispatch: AppDispatch) {
    const count = await _fetchInventoryConditionsCount(fetchData)
    dispatch(setInventoryConditionsCount({ count }))
    return count
  }
}

export async function _fetchInventoryConditionsCount(fetchData?: Record<string, any>) {
  let count = 0

  try {
    const { isSuccess, result } = await safeFetchJson<{count: string}>(
      buildGetUrl('/new_api/inventory-conditions/count', fetchData),
    )
    if (isSuccess && !isJob(result)) {
      count = +result[0].count || 0
    }
  } catch (err) {
    console.error(err)
  }

  return count
}

export async function _updateInventoryCondition(
  id: InventoryCondition['id'],
  updateData: Partial<InventoryConditionApi>,
  mapData: MapData,
) {
  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ inventoryCondition: updateData }),
  }

  try {
    const { isSuccess, result } = await safeFetchJson<InventoryConditionApi, false>(
      `/new_api/inventory-conditions/${id}`, requestOptions,
    )
    const inventoryCondition = isSuccess && !isJob<InventoryConditionApi, false>(result) ?
      parseInventoryCondition(result, mapData) :
      null
    const error = !isSuccess ? result : null
    return { isSuccess, inventoryCondition, error }
  } catch (error) {
    console.error(error)
    return { isSuccess: false, error }
  }
}

export function _changeInventoryConditionStatus(ids: InventoryCondition['id'][], status: InventoryCondition['status']) {
  const update = ids.map((id) => ({ id, status }))
  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ inventoryCondition: update }),
  }

  return safeFetchJson(`/new_api/inventory-conditions/batch`, requestOptions)
}

export function deleteInventoryConditions(ids: InventoryCondition['id'][], mapData: MapData) {
  if (!ids?.length) return []

  const requestOptions = {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
  }
  const url = `/new_api/inventory-conditions/${ids}`
  return async function deleteInventoryConditionsThunk(dispatch: AppDispatch) {
    try {
      const { isSuccess, result } = await safeFetchJson<InventoryConditionApi>(url, requestOptions)
      const error = !isSuccess ? result : null
      const _result = { isSuccess, error, inventoryConditions: undefined, inventoryCondition: undefined }
      if (Array.isArray(result)) {
        _result.inventoryConditions = []
        if (isSuccess && !isJob(result)) {
          _result.inventoryConditions = result.map((inventoryCondition) => {
            return parseInventoryCondition(inventoryCondition, mapData)
          })
        }
      } else {
        _result.inventoryCondition = isSuccess && !isJob<InventoryConditionApi, false>(result) ?
          parseInventoryCondition(result, mapData) :
          null
      }

      return _result
    } catch (error) {
      console.error(error)
      return { isSuccess: false, error }
    }
  }
}

export function parseInventoryCondition(
  inventoryCondition: InventoryConditionApi,
  mapData: MapData,
): InventoryCondition {
  const options = {
    defaultData: getDefaultInventoryCondition(),
    fields: initialState.fields,
    dataSetName: dataSetName,
    ...mapData,
  }

  return parse(inventoryCondition, options)
}

function getDefaultInventoryCondition(): InventoryCondition {
  return parse({}, { fields: initialState.fields })
}

export function canBeDeleted(inventoryCondition: InventoryCondition): boolean {
  return inventoryCondition && !inventoryCondition.isDefault
}

export function canBeActivated(inventoryCondition: InventoryCondition): boolean {
  return inventoryCondition && inventoryCondition.status !== 'active'
}

export function canBeDeactivated(inventoryCondition: InventoryCondition): boolean {
  return inventoryCondition && !inventoryCondition.isDefault && inventoryCondition.status !== 'inactive'
}

export default inventoryConditionsSlice.reducer
