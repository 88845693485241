import { createSlice } from '@reduxjs/toolkit'

import { buildGetUrl, parse } from 'utils/api'
import { roundNumber } from 'utils/numberParser'
import { isJob, parseError, safeFetch, safeFetchJson } from 'utils/safeFetch'
import { convertFromBase, convertFromDollarPerBase, convertToBase } from 'utils/unitConverter'

import { Bill, BillApi, BillGetFields, BillItem, BillItemApi, BillItemGetFields, MapData, statuses } from './types'

const dataSetName = 'billView'
const itemDataSetName = 'billItemView'
const initialState = {
  dataSetName,
  fields: getFields(),
  bills: [],
  billsCount: 0,
  itemFields: getItemFields(),
  billItemsCount: 0,
  billItems: [],
}

const billsSlice = createSlice({
  name: 'bills',
  initialState,
  reducers: {
    setBills: (state, action) => {
      state.bills = action.payload.data
    },
    setBillsCount: (state, action) => {
      state.billsCount = action.payload.count
    },
    clearBills: (state) => {
      state.bills = []
      state.billsCount = 0
    },
    setBillItems: (state, action) => {
      state.billItems = action.payload.data
    },
    setBillItemsCount: (state, action) => {
      state.billItemsCount = action.payload.count
    },
    clearBillItems: (state) => {
      state.billItems = []
      state.billItemsCount = 0
    },
  },
})

export const {
  setBills,
  setBillsCount,
  clearBills,
  setBillItems,
  setBillItemsCount,
  clearBillItems,
} = billsSlice.actions

export function getFields(): BillGetFields {
  return {
    'id': { dataSetName, dbField: 'id', type: 'id' },
    'createdDate': { dataSetName, dbField: 'created_date', type: 'date' },
    'modifiedDate': { dataSetName, dbField: 'modified_date', type: 'date' },
    'createdBy': { dataSetName, dbField: 'created_by' },
    'createdById': { dataSetName, dbField: 'created_by_id', type: 'id' },
    'modifiedBy': { dataSetName, dbField: 'modified_by' },
    'modifiedById': { dataSetName, dbField: 'modified_by_id', type: 'id' },
    'companyId': { dataSetName, dbField: 'company_id', type: 'id', relationEntity: 'companies' },
    'vendorId': { dataSetName, dbField: 'vendor_id', type: 'id', relationEntity: 'contacts' },
    'billToAddressId': { dataSetName, dbField: 'bill_to_address_id', type: 'id', relationEntity: 'addresses' },
    'name': { dataSetName, dbField: 'name' },
    'notes': { dataSetName, dbField: 'notes' },
    'referenceNumber': { dataSetName, dbField: 'reference_number' },
    'billDate': { dataSetName, dbField: 'bill_date', type: 'date' },
    'status': {
      dataSetName,
      dbField: 'status',
      type: 'status',
      dictionaryKey: 'bill',
      values: [...statuses],
      translationPath: 'bills:status',
    },
    'paymentTerms': { dataSetName, dbField: 'payment_terms' },
    'termsAndConditions': { dataSetName, dbField: 'terms_and_conditions' },
    'currencyId': { dataSetName, dbField: 'currency_id', type: 'id' },
    'exchangeRate': { dataSetName, dbField: 'exchange_rate' },
    'externalId': { dataSetName, dbField: 'external_id', type: 'id' },
    'config': { dataSetName, dbField: 'config', type: 'json' },
    'dueDate': { dataSetName, dbField: 'due_date', type: 'date' },
    'isOverdue': { dataSetName, dbField: 'is_overdue', type: 'boolean' },
    'vendorDisplayName': { dataSetName, dbField: 'vendor_display_name' },
    'vendorCompanyName': { dataSetName, dbField: 'vendor_company_name' },
    'currencyCode': { dataSetName, dbField: 'currency_code' },
    'currencySymbol': { dataSetName, dbField: 'currency_symbol' },
    'lineItems': {
      dataSetName,
      dbField: 'line_items',
      parse: (bill) => {
        return (bill?.line_items || []).map((item) => parseBillItem(item))
      },
    },

    'total': { dataSetName, parseWithParsedData: getBillTotal },
  }
}

export function getItemFields(): BillItemGetFields {
  return {
    'id': { dataSetName: itemDataSetName, dbField: 'id', type: 'id' },
    'name': { dataSetName: itemDataSetName, dbField: 'name' },
    'billId': { dataSetName: itemDataSetName, dbField: 'bill_id', type: 'id', relationEntity: 'bills' },
    'billName': { dataSetName: itemDataSetName, dbField: 'bill_name' },
    'billStatus': { dataSetName: itemDataSetName, dbField: 'bill_status' },
    'billIsOverdue': { dataSetName: itemDataSetName, dbField: 'bill_is_overdue', type: 'boolean' },
    'billExternalId': { dataSetName: itemDataSetName, dbField: 'bill_external_id', type: 'id' },
    'modifiedDate': { dataSetName: itemDataSetName, dbField: 'modified_date', type: 'date' },
    'modifiedBy': { dataSetName: itemDataSetName, dbField: 'modified_by' },
    'modifiedById': { dataSetName: itemDataSetName, dbField: 'modified_by_id', type: 'id' },
    'purchaseOrderBilled': { dataSetName: itemDataSetName, dbField: 'purchase_order_billed_count' },
    'unit': { dataSetName: itemDataSetName, dbField: 'measure_unit', parse: getUnit },
    'dimension': { dataSetName: itemDataSetName, dbField: 'dimension_to_display' },
    'templateInventoryManagementType': {
      dataSetName: itemDataSetName,
      dbField: 'template_inventory_management_type',
      type: 'string',
    },
    'templateId': { dataSetName: itemDataSetName, dbField: 'template_id', type: 'id' },
    'item': { dataSetName: itemDataSetName, dbField: 'template_title' },
    'conversionFactor': { dataSetName: itemDataSetName, dbField: 'conversion_factor', parse: getConversionFactor },
    'unitCost': {
      dataSetName: itemDataSetName,
      dbField: 'unit_cost',
      type: 'currency',
      parseWithParsedData: getUnitCost,
    },
    'purchaseOrderMeasure': { dataSetName: itemDataSetName, dbField: 'purchase_order_measure', type: 'measure' },
    'accountId': {
      dataSetName: itemDataSetName,
      dbField: 'account_id',
      type: 'id',
      relationEntity: 'chart-of-accounts',
    },
    'purchaseOrderId': { dataSetName: itemDataSetName, dbField: 'purchase_order_id', type: 'id' },
    'purchaseOrder': { dataSetName: itemDataSetName, dbField: 'purchase_order_name' },
    'accountName': { dataSetName: itemDataSetName, dbField: 'account_name' },
    'billed': { dataSetName: itemDataSetName, dbField: 'measure', type: 'measure' },
    'convertedMeasure': { dataSetName: itemDataSetName, type: 'measure', parseWithParsedData: getConvertedMeasure },
    'totalCost': { type: 'currency', parseWithParsedData: getTotalCost },
  }
}

export async function _fetchBillsCount(data: Record<string, any>): Promise<number> {
  let count = 0

  try {
    const { isSuccess, result } = await safeFetchJson<{ count: string }>(buildGetUrl('/new_api/bills/count', data))

    if (isSuccess && !isJob(result)) {
      count = +result[0].count || 0
    }
  } catch (err) {
    console.error(err)
  }

  return count
}

export function fetchBillsCount(data: Record<string, any>) {
  return async function fetchBillsCountThunk(dispatch): Promise<number> {
    const count = await _fetchBillsCount(data)
    dispatch(setBillsCount({ count }))
    return count
  }
}

export async function _fetchBills(data: Record<string, any>): Promise<Bill[]> {
  let bills: Bill[] = []

  try {
    const { isSuccess, result } = await safeFetchJson<BillApi>(buildGetUrl('/new_api/bills', data))

    if (isSuccess && !isJob(result)) {
      bills = result.map((bill) => parseBill(bill))
    }
  } catch (err) {
    console.error(err)
  }

  return bills
}

export function fetchBills(data: Record<string, any>) {
  return async function fetchBillsThunk(dispatch): Promise<Bill[]> {
    const bills = await _fetchBills(data)
    dispatch(setBills({ data: bills }))
    return bills
  }
}

export async function fetchBillsByIds(ids: Bill['id'][], data?: Record<string, any>): Promise<Bill[]> {
  if (!ids?.length) return []

  const { isSuccess, result } = await safeFetchJson<BillApi>(
    buildGetUrl(`/new_api/bills/${ids}`, data),
  )

  if (isSuccess && !isJob(result)) {
    return result.map((bill) => parseBill(bill))
  }

  return []
}

export function getBillTitle(bill): Bill['name'] {
  return bill.name
}

export async function updateBills(bills: Partial<BillApi>[]) {
  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ bills }),
  }

  try {
    return (await safeFetch(`/api/integrations/bills/batch`, requestOptions)).json()
  } catch (error) {
    console.error(error)
    return parseError(error)
  }
}

export async function _fetchBillItemsCount(data: Record<string, any>): Promise<number> {
  let count = 0

  try {
    const { isSuccess, result } = await safeFetchJson<{ count: string }>(
      buildGetUrl('/new_api/bills/items/count', data),
    )

    if (isSuccess && !isJob(result)) {
      count = +result[0].count || 0
    }
  } catch (err) {
    console.error(err)
  }

  return count
}

export function fetchBillItemsCount(data: Record<string, any>) {
  return async function fetchBillItemsCountThunk(dispatch): Promise<number> {
    const count = await _fetchBillItemsCount(data)
    dispatch(setBillItemsCount({ count }))
    return count
  }
}

export async function _fetchBillItems(data: Record<string, any>, mapData: MapData): Promise<BillItem[]> {
  let billItems: BillItem[] = []

  try {
    const { isSuccess, result } = await safeFetchJson<BillItemApi>(buildGetUrl('/new_api/bills/items', data))

    if (isSuccess && !isJob(result)) {
      billItems = result.map((billItem) => parseBillItem(billItem, mapData))
    }
  } catch (err) {
    console.error(err)
  }

  return billItems
}

export function fetchBillItems(data: Record<string, any>, mapData: MapData) {
  return async function fetchBillItemsThunk(dispatch): Promise<BillItem[]> {
    const billItems = await _fetchBillItems(data, mapData)
    dispatch(setBillItems({ data: billItems }))
    return billItems
  }
}

export async function fetchBillItemsByIds(ids: BillItem['id'][], data?: Record<string, any>): Promise<BillItem[]> {
  if (!ids?.length) return []

  const { isSuccess, result } = await safeFetchJson<BillItemApi>(
    buildGetUrl(`/new_api/bills/items/${ids}`, data),
  )

  if (isSuccess && !isJob(result)) {
    return result.map((billItem) => parseBillItem(billItem))
  }

  return []
}

export function getBillItemTitle(billItem: BillItem): BillItem['id'] {
  return billItem.name
}

export function parseBill(bill: BillApi): Bill {
  const options = {
    defaultData: getDefaultBill(),
    fields: initialState.fields,
    dataSetName,
  }
  return parse(bill, options)
}

export function parseBillItem(billItem: BillItemApi, mapData: MapData = {}): BillItem {
  const options = {
    defaultData: getDefaultBillItem(),
    fields: initialState.itemFields,
    dataSetName: itemDataSetName,
    defaultUnits: mapData.defaultUnits,
  }
  return parse(billItem, options)
}

function getUnit(billItem: BillItemApi, options: MapData = {}): string {
  const defaultUnits = options.defaultUnits || {}
  return billItem.measure_unit || defaultUnits[billItem.dimension_to_display]
}

function getDefaultBill(): Bill {
  return parse({}, { fields: initialState.fields })
}

function getDefaultBillItem(): BillItem {
  return parse({}, { fields: initialState.itemFields })
}

function getConversionFactor(lineItem: BillItemApi, options: MapData = {}, isInitial: boolean = false): number {
  if (isInitial) {
    return +lineItem.conversion_factor || 0
  }

  const dimension = lineItem.dimension_to_display
  const templateUnit = getTemplateUnit(lineItem, options)

  return +lineItem.conversion_factor > 0 ? +lineItem.conversion_factor : convertToBase(dimension, 1, templateUnit)
}

function getTemplateUnit(lineItem: BillItemApi, options: MapData = {}): string {
  const dimension = lineItem.dimension_to_display
  const defaultUnits = options.defaultUnits || {}

  return lineItem.measure_unit || defaultUnits[dimension]
}

function getUnitCost(data: BillItem, options: MapData, apiData: BillItemApi) {
  if (data.conversionFactor) {
    return +apiData.unit_cost * +data.conversionFactor
  }

  return convertFromDollarPerBase(
    apiData.dimension_to_display?.toString(),
    +apiData.unit_cost,
    getUnit(apiData, options),
  )
}

function getConvertedMeasure(data: BillItem, options: MapData, apiData: BillItemApi) {
  if (data.conversionFactor) {
    return +apiData.measure / +data.conversionFactor
  }

  return +convertFromBase(
    apiData.dimension_to_display?.toString(),
    +apiData.measure,
    getUnit(apiData, options),
    true,
  )
}

function getTotalCost(data: BillItem, options: MapData, apiData: BillItemApi) {
  return +roundNumber(+data.convertedMeasure * +data.unitCost, 2)
}

function getBillTotal(data: Bill, options: MapData, apiData: BillApi): number {
  if (!data.lineItems) return 0

  let total: number = 0

  data.lineItems.forEach((data) => {
    total += data.totalCost
  })

  return total
}

export default billsSlice.reducer
