import { AppDispatch } from 'store'

import { buildGetUrl } from 'utils/api'
import { delayPromise } from 'utils/fetchLoad'
import { safeFetch } from 'utils/safeFetch'

import { parseResource } from 'reducers/resources/resourcesSlice'

import {
  GET_LOGIN_USER,
  SET_RULES,
  PUT_LOGIN_USER_LANGUAGE,
  PUT_LOGIN_USER_PASSWORD,
  PUT_LOGIN_USER_EMAIL,
} from './types'

const initialState = {
  cid: null,
  resourceid: null,
  groupname: null,
  language: null,
  passwordResetDate: null,
  isActivated: null,
  activationDate: null,
  activationData: null,
  isRedirect: null,
  userid: null,
  username: null,
  company_name: null,
  groupid: null,
  enterpriseType: null,
  firstname: null,
  lastname: null,
  pin: null,
  position: null,
  department: null,
  resource: null,
  fullName: null,
  email: null,
  phone: null,
  rules: {},
}

const _getLoginUserFullname = (loginUser) => {
  let fullName = ''
  if (loginUser.fullName) {
    fullName = loginUser.fullName
  } else if (loginUser.firstname && loginUser.lastname) {
    fullName = `${loginUser.firstname} ${loginUser.lastname}`
  } else if (loginUser.username) {
    fullName = loginUser.username
  }

  return fullName
}

export default function loginUserReducer(state = initialState, action) {
  const { payload } = action
  switch (action.type) {
  case GET_LOGIN_USER: {
    return { ...state, ...payload }
  }
  case SET_RULES: {
    return { ...state, rules: { ...state.rules, ...payload } }
  }
  case PUT_LOGIN_USER_LANGUAGE: {
    return {
      ...state,
      isRedirect: action.isRedirect || false,
    }
  }
  default:
    return state
  }
}

export async function fetchLoginUser(dispatch: AppDispatch) {
  try {
    let loginUser = await (await safeFetch('/api/login/info')).json()
    loginUser = {
      ...loginUser,
      language: loginUser.defaultLanguage,
      passwordResetDate: loginUser.password_reset_date,
      isActivated: loginUser.is_activated,
      activationDate: loginUser.activation_date,
      activationData: loginUser.activation_data,
      isRedirect: false,
      fullName: _getLoginUserFullname(loginUser),
      email: loginUser.email,
      phone: loginUser.resource ? loginUser.resource.phone : null,
      resource: parseResource(loginUser.resource),
    }
    delete loginUser.defaultLanguage
    dispatch({ type: GET_LOGIN_USER, payload: loginUser })
  } catch (err) {
    console.error(err)
  }
}

export function saveLoginUserLanguage(language = 'fr', skipRedirect = false) {
  return async function saveLoginUserLanguageThunk(dispatch: AppDispatch) {
    const requestOptions = { method: 'PUT' }
    let isSuccess = false

    try {
      const result = await (await safeFetch(`/new_api/users/language/${language}`, requestOptions)).json()
      const error = !result.isSuccess ? result.result : null
      dispatch({ type: PUT_LOGIN_USER_LANGUAGE, isRedirect: !skipRedirect && result.isSuccess, error })
      isSuccess = result.isSuccess
    } catch (error) {
      dispatch({ type: PUT_LOGIN_USER_LANGUAGE, error })
    }

    return isSuccess
  }
}

type PasswordData = {
  oldPassword: string
  newPassword: string
  confirmPassword: string
}
export function saveLoginUserPassword(passwordData: PasswordData) {
  return async function saveLoginUserPasswordThunk(dispatch: AppDispatch) {
    const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        oldPassword: passwordData.oldPassword,
        newPassword: passwordData.newPassword,
        confirmPassword: passwordData.confirmPassword,
      }),
    }

    let isSuccess = false
    try {
      const result = await delayPromise((await safeFetch(`/new_api/users/password`, requestOptions)).json())

      if (result.isSuccess) {
        await fetchLoginUser(dispatch)
      }

      isSuccess = result.isSuccess
      dispatch({ type: PUT_LOGIN_USER_PASSWORD, error: !result.isSuccess ? result.result : null })
    } catch (error) {
      dispatch({ type: PUT_LOGIN_USER_PASSWORD, error })
    }

    return isSuccess
  }
}

export function fetchRule(name: string, userId: string, defaultValue?: any) {
  return async function(dispatch) {
    let rule
    try {
      const result = await (await safeFetch(buildGetUrl(`/new_api/users/rules/${name}`, { userId }))).json()
      rule = result?.result?.value ?? defaultValue
      if (typeof(rule) === 'string') {
        rule = JSON.parse(rule)
      }
      dispatch({ type: SET_RULES, payload: { [name]: rule } })
    } catch (err) {}
    return rule
  }
}

export async function fetchPreference(name: string, userId: string) {
  let preference
  try {
    const result = await (await safeFetch(buildGetUrl(`/new_api/users/preferences/${name}`, { userId }))).json()
    preference = result?.result?.value
    if (typeof(preference) === 'string') {
      preference = JSON.parse(preference)
    }
  } catch (err) {}
  return preference
}

export async function setPreference(name: string, value: any) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ value }),
  }

  let preference
  try {
    const result = await (await safeFetch(`/new_api/users/preferences/${name}`, requestOptions)).json()
    preference = result?.result?.value
    if (typeof(preference) === 'string') {
      preference = JSON.parse(preference)
    }
  } catch (err) {}
  return preference
}

export function activateUser(isActivated: boolean, firstName: string, email: string, userId: string = 'me') {
  const { url, body } = _getActivateUserInfo(isActivated, firstName, email, userId)
  return async function activateUserThunk(dispatch: AppDispatch): Promise<boolean> {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body,
    }

    let result
    try {
      result = await delayPromise((await safeFetch(url, requestOptions)).json())

      if (result.isSuccess) {
        await fetchLoginUser(dispatch)
      }

      dispatch({ type: PUT_LOGIN_USER_EMAIL, error: !result.isSuccess ? result.result : null })
    } catch (error) {
      dispatch({ type: PUT_LOGIN_USER_EMAIL, error })
    }
    return result
  }
}

function _getActivateUserInfo(isActivated: boolean, firstName: string, email: string, userId: string = 'me') {
  if (isActivated && userId === 'me') {
    return {
      url: `/new_api/users/me/change-email`,
      body: JSON.stringify({ firstname: firstName, newEmail: email }),
    }
  }

  return { url: `/new_api/users/${userId}/activate`, body: JSON.stringify({ firstname: firstName, email }) }
}
