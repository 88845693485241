
import { createSlice } from '@reduxjs/toolkit'

import { buildGetUrl, parse } from 'utils/api'
import { getFirstString } from 'utils/getFirstOfType'
import { roundNumber } from 'utils/numberParser'
import { isJob, parseError, safeFetch, safeFetchJson } from 'utils/safeFetch'
import { convertFromBase, convertFromDollarPerBase } from 'utils/unitConverter'

import {
  Invoice,
  InvoiceApi,
  InvoiceGetFields,
  InvoiceItem,
  InvoiceItemApi,
  InvoiceItemGetFields,
  MapData,
  statuses,
} from './types'

const dataSetName = 'invoiceView'
const itemDataSetName = 'invoiceItemView'
const initialState = {
  dataSetName,
  fields: getFields(),
  invoices: [],
  invoicesCount: 0,
  itemFields: getItemFields(),
  invoiceItemsCount: 0,
  invoiceItems: [],
}

const invoicesSlice = createSlice({
  name: 'invoices',
  initialState,
  reducers: {
    setInvoices: (state, action) => {
      state.invoices = action.payload.data
    },
    setInvoicesCount: (state, action) => {
      state.invoicesCount = action.payload.count
    },
    clearInvoices: (state) => {
      state.invoices = []
      state.invoicesCount = 0
    },
    setInvoiceItems: (state, action) => {
      state.invoiceItems = action.payload.data
    },
    setInvoiceItemsCount: (state, action) => {
      state.invoiceItemsCount = action.payload.count
    },
    clearInvoiceItems: (state) => {
      state.invoiceItems = []
      state.invoiceItemsCount = 0
    },
  },
})

export const {
  setInvoices,
  setInvoicesCount,
  clearInvoices,
  setInvoiceItems,
  setInvoiceItemsCount,
  clearInvoiceItems,
} = invoicesSlice.actions

export function getFields(): InvoiceGetFields {
  return {
    'id': { dataSetName, dbField: 'id', type: 'id' },
    'createdDate': { dataSetName, dbField: 'created_date', type: 'date' },
    'modifiedDate': { dataSetName, dbField: 'modified_date', type: 'date' },
    'createdBy': { dataSetName, dbField: 'created_by' },
    'createdById': { dataSetName, dbField: 'created_by_id', type: 'id' },
    'modifiedBy': { dataSetName, dbField: 'modified_by' },
    'modifiedById': { dataSetName, dbField: 'modified_by_id', type: 'id' },
    'companyId': { dataSetName, dbField: 'company_id', type: 'id' },
    'customerId': { dataSetName, dbField: 'customer_id', type: 'id' },
    'shipToAddressId': { dataSetName, dbField: 'ship_to_address_id', type: 'id', relationEntity: 'addresses' },
    'billToAddressId': { dataSetName, dbField: 'bill_to_address_id', type: 'id', relationEntity: 'addresses' },
    'name': { dataSetName, dbField: 'name' },
    'notes': { dataSetName, dbField: 'notes', type: 'text' },
    'referenceNumber': { dataSetName, dbField: 'reference_number' },
    'invoiceDate': { dataSetName, dbField: 'invoice_date', type: 'date', isTimezoned: false },
    'status': {
      dataSetName,
      dbField: 'status',
      type: 'status',
      dictionaryKey: 'invoice',
      values: [...statuses],
      translationPath: 'invoices:status',
    },
    'paymentTerms': { dataSetName, dbField: 'payment_terms' },
    'subject': { dataSetName, dbField: 'subject' },
    'termsAndConditions': { dataSetName, dbField: 'terms_and_conditions', type: 'text' },
    'currencyId': { dataSetName, dbField: 'currency_id', type: 'id' },
    'exchangeRate': { dataSetName, dbField: 'exchange_rate', type: 'currency' },
    'externalId': { dataSetName, dbField: 'external_id', type: 'id' },
    'config': { dataSetName, dbField: 'config', type: 'json' },
    'dueDate': { dataSetName, dbField: 'due_date', type: 'date', isTimezoned: false },
    'isOverdue': { dataSetName, dbField: 'is_overdue', type: 'boolean' },
    'customerCompanyName': { dataSetName, dbField: 'customer_company_name' },
    'customerDisplayName': { dataSetName, dbField: 'customer_display_name' },
    'currencyCode': { dataSetName, dbField: 'currency_code' },
    'currencySymbol': { dataSetName, dbField: 'currency_symbol' },
    'lineItems': {
      dataSetName,
      dbField: 'line_items',
      parse: (invoice) => {
        return (invoice?.line_items || []).map((item) => parseInvoiceItem(item))
      },
    },
    'total': { dataSetName, parseWithParsedData: getInvoiceTotal },
  }
}

export function getItemFields(): InvoiceItemGetFields {
  return {
    'invoiceId': { dataSetName: itemDataSetName, dbField: 'invoice_id', type: 'id', relationEntity: 'invoices' },
    'invoiceName': { dataSetName: itemDataSetName, dbField: 'invoice_name' },
    'invoiceStatus': { dataSetName: itemDataSetName, dbField: 'invoice_status' },
    'invoiceIsOverdue': { dataSetName: itemDataSetName, dbField: 'invoice_is_overdue', type: 'boolean' },
    'invoiceExternalId': { dataSetName: itemDataSetName, dbField: 'invoice_external_id', type: 'id' },

    'id': { dataSetName: itemDataSetName, dbField: 'id', type: 'id' },
    'name': { dataSetName: itemDataSetName, dbField: 'name' },
    'createdDate': { dataSetName: itemDataSetName, dbField: 'created_date', type: 'date' },
    'modifiedDate': { dataSetName: itemDataSetName, dbField: 'modified_date', type: 'date' },
    'createdBy': { dataSetName: itemDataSetName, dbField: 'created_by' },
    'createdById': { dataSetName: itemDataSetName, dbField: 'created_by_id', type: 'id' },
    'modifiedBy': { dataSetName: itemDataSetName, dbField: 'modified_by' },
    'modifiedById': { dataSetName: itemDataSetName, dbField: 'modified_by_id', type: 'id' },
    'unit': { dataSetName: itemDataSetName, dbField: 'measure_unit', parse: getUnit },
    'dimension': { dataSetName: itemDataSetName, dbField: 'dimension_to_display' },

    'templateId': { dataSetName: itemDataSetName, dbField: 'template_id', type: 'id' },
    'unitPrice': { dataSetName: itemDataSetName, dbField: 'unit_selling_price', parse: getUnitPrice },
    'discount': { dataSetName: itemDataSetName, dbField: 'discount', type: 'measure' },
    'taxId': { dataSetName: itemDataSetName, dbField: 'tax_id', type: 'id', relationEntity: 'taxes' },
    'item': { dataSetName: itemDataSetName, dbField: 'template_title' },
    'salesOrderId': {
      dataSetName: itemDataSetName,
      dbField: 'sales_order_id',
      type: 'id',
      relationEntity: 'sales-orders',
    },
    'salesOrder': { dataSetName: itemDataSetName, dbField: 'sales_order_name', relationEntity: 'sales-orders' },
    'salesOrderMeasure': { dataSetName: itemDataSetName, dbField: 'sales_order_measure', type: 'measure' },
    'salesOrderInvoiced': { dataSetName: itemDataSetName, dbField: 'sales_order_invoiced_count', type: 'measure' },
    'salesOrderShipped': { dataSetName: itemDataSetName, dbField: 'sales_order_shipped_count', type: 'measure' },
    'tax': { dataSetName: itemDataSetName, dbField: 'tax', relationEntity: 'taxes' },
    'incomeAccountId': {
      dataSetName: itemDataSetName,
      dbField: 'income_account_id',
      type: 'id',
      relationEntity: 'chart-of-accounts',
    },
    'templateSku': { dataSetName: itemDataSetName, dbField: 'sku' },
    'contractNumber': { dataSetName: itemDataSetName, dbField: 'contract_number' },
    'promisedDate': { dataSetName: itemDataSetName, dbField: 'promised_date', type: 'date' },
    'accountName': { dataSetName: itemDataSetName, dbField: 'account_name' },
    'invoiced': { dataSetName: itemDataSetName, dbField: 'measure', type: 'measure' },

    'longDescription': { dataSetName: itemDataSetName, parse: getLongDescription },
    'convertedMeasure': { dataSetName: itemDataSetName, type: 'measure', parse: getConvertedMeasure },
    'taxe': { dataSetName: itemDataSetName, parse: getTax },
    'discountedPrice': { dataSetName: itemDataSetName, type: 'currency', parseWithParsedData: getDiscountedPrice },
    'totalPrice': { dataSetName: itemDataSetName, type: 'currency', parseWithParsedData: getTotalPrice },
  }
}

export async function _fetchInvoicesCount(data: Record<string, any>): Promise<number> {
  let count = 0

  try {
    const { isSuccess, result } = await safeFetchJson<{ count: string }>(buildGetUrl('/new_api/invoices/count', data))

    if (isSuccess && !isJob(result)) {
      count = +result[0].count || 0
    }
  } catch (err) {
    console.error(err)
  }

  return count
}

export function fetchInvoicesCount(data: Record<string, any>) {
  return async function fetchInvoicesCountThunk(dispatch): Promise<number> {
    const count = await _fetchInvoicesCount(data)
    dispatch(setInvoicesCount({ count }))
    return count
  }
}

export async function _fetchInvoices(data: Record<string, any>): Promise<Invoice[]> {
  let invoices: Invoice[] = []

  try {
    const { isSuccess, result } = await safeFetchJson<InvoiceApi>(buildGetUrl('/new_api/invoices', data))

    if (isSuccess && !isJob(result)) {
      invoices = result.map((invoice) => parseInvoice(invoice))
    }
  } catch (err) {
    console.error(err)
  }

  return invoices
}

export function fetchInvoices(data: Record<string, any>) {
  return async function fetchInvoicesThunk(dispatch): Promise<Invoice[]> {
    const invoices = await _fetchInvoices(data)
    dispatch(setInvoices({ data: invoices }))
    return invoices
  }
}

export async function fetchInvoicesByIds(ids: Invoice['id'][], data?: Record<string, any>): Promise<Invoice[]> {
  if (!ids?.length) return []

  const { isSuccess, result } = await safeFetchJson<InvoiceApi>(
    buildGetUrl(`/new_api/invoices/${ids}`, data),
  )

  if (isSuccess && !isJob(result)) {
    return result.map((invoice) => parseInvoice(invoice))
  }

  return []
}

export function getInvoiceTitle(invoice: Invoice): Invoice['name'] | Invoice['referenceNumber'] {
  return getFirstString(
    invoice.name,
    invoice.referenceNumber,
  )
}

export async function updateInvoices(invoices: Partial<InvoiceApi>[]) {
  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ invoices }),
  }

  try {
    return (await safeFetch(`/api/integrations/invoices/batch`, requestOptions)).json()
  } catch (error) {
    console.error(error)
    return parseError(error)
  }
}

async function _fetchInvoiceItemsCount(data: Record<string, any>): Promise<number> {
  let count = 0

  try {
    const { isSuccess, result } = await safeFetchJson<{ count: string }>(
      buildGetUrl('/new_api/invoices/items/count', data),
    )

    if (isSuccess && !isJob(result)) {
      count = +result[0].count || 0
    }
  } catch (err) {
    console.error(err)
  }

  return count
}

export function fetchInvoiceItemsCount(data: Record<string, any>) {
  return async function fetchInvoiceItemsCountThunk(dispatch): Promise<number> {
    const count = await _fetchInvoiceItemsCount(data)
    dispatch(setInvoiceItemsCount({ count }))
    return count
  }
}

async function _fetchInvoiceItems(data: Record<string, any>, mapData: MapData): Promise<InvoiceItem[]> {
  let invoiceItems: InvoiceItem[] = []

  try {
    const { isSuccess, result } = await safeFetchJson<InvoiceItemApi>(buildGetUrl('/new_api/invoices/items', data))

    if (isSuccess && !isJob(result)) {
      invoiceItems = result.map((invoiceItem) => parseInvoiceItem(invoiceItem, mapData))
    }
  } catch (err) {
    console.error(err)
  }

  return invoiceItems
}

export function fetchInvoiceItems(data: Record<string, any>, mapData: MapData) {
  return async function fetchInvoiceItemsThunk(dispatch): Promise<InvoiceItem[]> {
    const invoiceItems = await _fetchInvoiceItems(data, mapData)
    dispatch(setInvoiceItems({ data: invoiceItems }))
    return invoiceItems
  }
}

export async function fetchInvoiceItemsByIds(
  ids: InvoiceItem['id'][],
  data?: Record<string, any>,
): Promise<InvoiceItem[]> {
  if (!ids?.length) return []

  const { isSuccess, result } = await safeFetchJson<InvoiceItemApi>(
    buildGetUrl(`/new_api/invoices/items/${ids}`, data),
  )

  if (isSuccess && !isJob(result)) {
    return result.map((invoiceItem) => parseInvoiceItem(invoiceItem))
  }

  return []
}

export function getInvoiceItemTitle(invoiceItem: InvoiceItem): string {
  return 'TODO'
}

export function parseInvoice(invoice: InvoiceApi): Invoice {
  const options = {
    defaultData: getDefaultInvoice(),
    fields: initialState.fields,
    dataSetName,
  }
  return parse(invoice, options)
}

export function parseInvoiceItem(invoiceItem: InvoiceItemApi, mapData: MapData = {}): InvoiceItem {
  const options = {
    defaultData: getDefaultInvoiceItem(),
    fields: initialState.itemFields,
    dataSetName: itemDataSetName,
    defaultUnits: mapData.defaultUnits,
  }
  return parse(invoiceItem, options)
}

function getUnit(invoiceItem: InvoiceItemApi, options: MapData = {}): string {
  const defaultUnits = options.defaultUnits || {}
  return invoiceItem.measure_unit || defaultUnits[invoiceItem.dimension_to_display]
}

function getUnitPrice(apiData: InvoiceItemApi, options: MapData = {}): number {
  return convertFromDollarPerBase(
    apiData.dimension_to_display?.toString(),
    +apiData.unit_selling_price || 0,
    getUnit(apiData, options),
  )
}

function getConvertedMeasure(apiData: InvoiceItemApi, options: MapData = {}): number {
  return +convertFromBase(
    apiData.dimension_to_display?.toString(),
    +apiData.measure,
    getUnit(apiData, options),
    true,
  )
}

function getDiscountedPrice(data: InvoiceItem, options: MapData, apiData: InvoiceItemApi) {
  return +data.unitPrice - ((data.discount / 100) * +data.unitPrice)
}

function getTotalPrice(data: InvoiceItem, options: MapData, apiData: InvoiceItemApi): number {
  return +roundNumber((+data.convertedMeasure * +data.discountedPrice), 2)
}

function getTax(apiData: InvoiceItemApi, options: MapData = {}): InvoiceItem['taxe'] {
  if (apiData.tax) {
    return `${apiData.tax.tax_name} [${apiData.tax.tax_percentage}%]`
  }

  return ''
}

function getLongDescription(apiData: InvoiceItemApi, options: MapData = {}): InvoiceItem['longDescription'] {
  return apiData?.overwritten_notes || (
    options.primaryLanguage ?
      apiData?.primary_notes || apiData?.secondary_notes :
      apiData?.secondary_notes || apiData?.primary_notes
  )
}

function getDefaultInvoice(): Invoice {
  return parse({}, { fields: initialState.fields })
}

function getDefaultInvoiceItem(): InvoiceItem {
  return parse({}, { fields: initialState.itemFields })
}

function getInvoiceTotal(data: Invoice, options: MapData, apiData: InvoiceApi): number {
  let total: number = 0

  if (!data.lineItems) return 0

  data.lineItems.forEach((data) => {
    let subtotal = data.totalPrice

    if (data.tax) {
      subtotal += +((data.tax.tax_percentage / 100) * subtotal).toFixed(2)
    }

    total += subtotal
  })

  return total
}

export default invoicesSlice.reducer
