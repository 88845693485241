export const GET_CARDS_COUNT = 'GET_CARDS_COUNT'
export const GET_CARDS = 'GET_CARDS'
export const CLEAR_CARDS = 'CLEAR_CARDS'

export const SET_SCAN_ERROR = 'SET_CARD_SCAN_ERROR'
export const CLEAR_SCAN_ERROR = 'CLEAR_CARD_SCAN_ERROR'

export const SET_CARD_REPORTING_TAGS_FORM = 'SET_CARD_REPORTING_TAGS_FORM'
export const INIT_CARD_REPORTING_TAGS_FORM = 'INIT_CARD_REPORTING_TAGS_FORM'
export const DELETE_CARD_REPORTING_TAGS_FROM_SELECTION =
  'DELETE_CARD_REPORTING_TAGS_FROM_SELECTION'
export const SET_CARD_REPORTING_TAGS_FORM_SELECTION =
  'SET_CARD_REPORTING_TAGS_FORM_SELECTION'
