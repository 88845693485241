import { TFuncKey } from 'i18next'
import { FieldType } from 'types/slices'
import { DefaultRecord } from 'types/utils'

import { HandlerOptions } from 'components/alix-front/smart-grid/columns/utils'

import { valueOrDefault } from 'utils/defaultValueHelper'
import {
  getEntityTranslationFile,
  reduceEventToDetails,
  removeIdOfFieldName,
} from 'utils/history'

import { fetchAddressByIds, getAddressTitle, parseAddress } from 'reducers/addresses/addressesSlice'
import { getFields as getAddressFields } from 'reducers/addresses/shared'
import {
  fetchAttributesByIds,
  getFields as getAttributeFields,
  getAttributeTitle,
  parseAttribute,
  fetchAttributeOptionsByIds,
  getOptionsFields as getAttributeOptionsFields,
  parseAttributeOption,
  getAttributeOptionTitle,
} from 'reducers/attributes/attributesSlice'
import {
  fetchBillsByIds,
  fetchBillItemsByIds,
  getBillTitle,
  getFields as getBillFields,
  getItemFields as getBillItemFields,
  parseBill,
  getBillItemTitle,
  parseBillItem,
} from 'reducers/bills/billsSlice'
import {
  fetchCardGroupsByIds,
  getFields as getCardGroupFields,
  getCardGroupTitle,
  parseCardGroup,
} from 'reducers/card-groups/cardGroupsSlice'
import {
  fetchCardsByIds,
  getFields as getCardFields,
  getCardtitle,
  parseCard,
  updateCards,
} from 'reducers/cards/cardsSlice'
import {
  getFields as getCategoryFields,
  getTitle as getCategoryTitle,
  fetchCategoriesByIds,
  parseCategory,
} from 'reducers/categories/categoriesSlice'
import {
  fetchChartsOfAccountByIds,
  getFields as getChartOfAccountFields,
  parseDisplayTitle,
  parseChartOfAccounts,
} from 'reducers/charts-of-accounts/chartsOfAccountsSlice'
import {
  _fetchChecklists,
  getCheckListTitle,
  getFields as getCheckListFields,
  getItemFields as getCheckListItemFields,
  _fetchChecklistTemplates,
  getTemplateFields as getCheckListTemplateFields,
  parseCheckList,
  parseCheckListTemplate,
  parseCheckListItem,
  getCheckListItemTitle,
  _fetchChecklistItems,
} from 'reducers/checklists/checkListsSlice'
import { ClientEntity } from 'reducers/clients/clientsSlice'
import {
  getCompanyTitle,
  fetchCompaniesByIds,
  parseCompany,
  getFields as getCompanyFields,
} from 'reducers/companies/companiesSlice'
import {
  fetchConsignmentItemsByIds,
  getLineItemFields as getConsignmentItemFields,
  getConsignmentItemTitle,
  parseConsignmentItem,
} from 'reducers/consignment-items/consignmentItemsSlice'
import {
  fetchContactCarrierAccountsByIds,
  getFields as getContactCarrierAccountFields,
  getContactCarrierAccountTitle,
  parseContactCarrierAccount,
} from 'reducers/contact-carrier-account/contactCarrierAccountsSlice'
import {
  fetchContactCategoriesByIds,
  getContactCategoryTitle,
  getFields as getContactCategoryFields,
  parseContactCategory,
} from 'reducers/contact-categories/contactCategoriesSlice'
import {
  fetchContactPersonByIds,
  getContactPersonTitle,
  getFields as getContactPersonFields,
  parseContactPerson,
} from 'reducers/contact-people/contactPeopleSlice'
import {
  fetchContactAddressesByIds,
  fetchContactByIds,
  getContactAddressFields,
  getContactTitle,
  getFields as getContactFields,
  parseContact,
} from 'reducers/contacts/contactsSlice'
import {
  fetchCountriesByIds,
  getFields as getCountryFields,
  getCountryTitle,
  parseCountry,
} from 'reducers/countries/countriesSlice'
import {
  fetchCountryOfOriginsByIds,
  getFields as getCountryOfOriginFields,
  getCountryOfOriginTitle,
  parseCountryOfOrigin,
} from 'reducers/country-of-origins/countryOfOriginsSlice'
import {
  getFields as getCustomFieldFields,
  parseCustomField,
} from 'reducers/custom-fields/customFieldsSlice'
import {
  fetchCustomIdentityProvidersByIds,
  getCustomIdentityProviderTitle,
  getFields as getCustomIdentityProvidersFields,
  parseCustomIdentityProvider,
} from 'reducers/custom-identity-providers/customIdentityProvidersSlice'
import {
  fetchDashboardsByIds,
  getFields as getDashboardFields,
  getDashboardTitle,
  parseDashboard,
  MapData as DashboardMapData,
} from 'reducers/dashboards/dashboardsSlice'
import { EmailTemplateEntity } from 'reducers/email-templates/emailTemplatesSlice'
import {
  getEquipmentTitle,
  getFields as getEquipmentFields,
  parseEquipment,
  fetchEquipmentsByIds,
} from 'reducers/equipments/equipmentsSlice'
import {
  fetchHarmonizedSystemCodesByIds,
  getFields as getHarmonizedSystemCodeFields,
  getHarmonizedSystemCodeTitle,
  parseHarmonizedSystemCode,
} from 'reducers/harmonized-system-codes/harmonizedSystemCodesSlice'
import {
  fetchIncotermsByIds,
  getFields as getIncotermFields,
  getIncotermTitle,
  parseIncoterm,
} from 'reducers/incoterms/incotermsSlice'
import {
  getFields as getInventoriesFields,
  getInventoryTitle,
  fetchInventoryByIds,
  parseInventory,
  getOutputFields,
  fetchInventoryOutputsById,
  getOutputTitle,
  getOutputUnit,
  parseOutput,
  _updateInventories,
} from 'reducers/inventories/inventoriesSlice'
import {
  parseInventoryCondition,
  getFields as getInventoryConditionFields,
  getInventoryConditionTitle,
  fetchInventoryConditionsByIds,
} from 'reducers/inventory-conditions/inventoryConditionsSlice'
import {
  fetchInvoicesByIds,
  getFields as getInvoiceFields,
  getInvoiceTitle,
  parseInvoice,
  fetchInvoiceItemsByIds,
  getItemFields as getInvoiceItemFields,
  getInvoiceItemTitle,
  parseInvoiceItem,
} from 'reducers/invoices/invoicesSlice'
import {
  _fetchItemByIds,
  getItemTitle,
  getFields,
  parseItem,
  _updateItems,
} from 'reducers/items/itemsSlice'
import {
  fetchLabelsByIds,
  getFields as getLabelFields,
  getLabelTitle,
  parseLabel,
} from 'reducers/labels/labelsSlice'
import {
  fetchLocationByIds,
  getFields as getLocationFields,
  getLocationTitle,
  parseLocation,
} from 'reducers/locations/locationsSlice'
import {
  getFields as getMaterialFields,
  getTitle as getMaterialTitle,
  parseMaterial,
  fetchMaterialsByIds,
} from 'reducers/materials/materialsSlice'
import {
  getFields as getPlannedLedgerFields,
  fetchPlannedLedgerByIds,
  getPlannedLedgerTitle,
  parsePlannedLedger,
} from 'reducers/planned-ledgers/plannedLedgersSlice'
import {
  getFields as getPlantFields,
  getPlantTitle,
  parsePlant,
} from 'reducers/plants/plantsSlice'
import { fetchPlantByIds } from 'reducers/plants/plantsSlice'
import {
  fetchPriceListsByIds,
  getFields as getPriceListFields,
  getItemFields as getPriceListItemFields,
  getPriceListItemTitle,
  getPriceListTitle,
  parsePriceList,
  parseLineItem as parsePriceListItem,
} from 'reducers/price-lists/priceListsSlice'
import {
  fetchProjectByIds,
  getFields as getProjectFields,
  getProjectTitle,
  parseProject,
} from 'reducers/projects/projectsSlice'
import { fetchPurchaseOrderItemByIds } from 'reducers/purchase-orders/purchaseOrderItemsSlice'
import {
  fetchPurchaseOrderByIds,
  getPurchaseOrderItemTitle,
  getPurchaseOrderTitle,
} from 'reducers/purchase-orders/purchaseOrdersSlice'
import {
  getFields as getPurchaseOrdersFields,
  getLineItemFields as getPurchaseOrderItemsFields,
  parsePurchaseOrder,
  parsePurchaseOrderLineItem,
} from 'reducers/purchase-orders/shared'
import {
  getFields as getRawFields,
  parseRaw,
  getRawTitle,
  fetchRawsByIds,
} from 'reducers/raws/rawsSlice'
import { ReadingRegisterEntity } from 'reducers/readings-register/readingRegisterSlice'
import {
  ReceptionItemEntity,
} from 'reducers/receptions/receptionItemsSlice'
import {
  ReceptionEntity,
} from 'reducers/receptions/receptionsSlice'
import {
  getReportingTagFields as getReportingTagAssociationFields,
  itemReportingTagDataSetName,
  parseReportingTag as parseReportingTagAssociation,
  getReportingTagAssociationTitle,
  salesOrderItemReportingTagDataSetName,
  purchaseOrderItemReportingTagDataSetName,
  cardReportingTagDataSetName,
  fetchItemReportingTagsByIds,
  fetchPurchaseOrderItemsReportingTagsByIds,
  fetchSalesOrderItemsReportingTagsByIds,
} from 'reducers/reporting-tags/reportingTagAssociationSlice'
import {
  fetchReportingTagsByIds,
  fetchReportingTagOptionsByIds,
  getFields as getReportingTagFields,
  getReportingTagOptionFields,
  getReportingTagTitle,
  getReportingTagOptionTitle,
  parseReportingTag,
  parseReportingTagOption,
} from 'reducers/reporting-tags/reportingTagsSlice'
import {
  fetchResourceByIds,
  getFields as getResourceFields,
  getResourceTitle,
  parseResource,
} from 'reducers/resources/resourcesSlice'
import { fetchSalesOrderItemByIds } from 'reducers/sales-orders/salesOrderItemsSlice'
import {
  _fetchSalesOrderByIds,
  fetchSalesOrderItemGroupByids,
  getSalesOrderGroupTitle as getSalesOrderItemGroupTitle,
  getSalesOrderItemTitle,
  getSalesOrderTitle,
} from 'reducers/sales-orders/salesOrdersSlice'
import {
  getFields as getSalesOrderFields,
  getLineItemFields as getSalesOrderItemsFields,
  getGroupFields as getSalesOrderItemGroupFields,
  parseSalesOrder,
  parseSalesOrderGroup,
  parseSalesOrderLineItem,
} from 'reducers/sales-orders/shared'
import {
  getFields as getShiftsFields,
  fetchShiftsByIds,
  getTitle as getShiftTitle,
  parseShift,
} from 'reducers/shifts/shiftSlices'
import {
  fetchShipmentListsByIds,
  getShipmentListTitle,
  parseShipmentList,
  getFields as getShipmentListFields,
} from 'reducers/shipment-lists/shipmentListsSlice'
import {
  ShipmentPlanningEntity,
} from 'reducers/shipment-planning/shipmentPlanningSlice'
import { fetchShipmentItemsbyIds } from 'reducers/shipments/shipmentItemsSlice'
import {
  fetchShipmentsByIds,
  getFields as getShipmentsFields,
  getShipmentItemTitle,
  getShipmentTitle,
  parseShipment,
  getLineItemFields,
  parseShipmentItem,
} from 'reducers/shipments/shipmentsSlice'
import {
  getFields as getStateFields,
  fetchStatesByIds,
  getStateTitle,
  parseState,
} from 'reducers/states/statesSlice'
import {
  fetchSynonymsByIds,
  getFields as getSynonymFields,
  getSynonymTitle,
  parseSynonym,
} from 'reducers/synonyms/synonymsSlice'
import {
  fetchTaresByIds,
  getFields as getTareFields,
  getTareTitle,
  parseTare,
} from 'reducers/tares/taresSlice'
import {
  getFields as getTaxByRegionFields,
  fetchTaxesByRegionByIds,
  getTaxByRegionTitle,
  parseTaxByRegion,
} from 'reducers/taxes/taxesByRegionSlice'
import {
  fetchTaxesByIds,
  getFields as getTaxFields,
  getTaxTitle,
  parseTax,
} from 'reducers/taxes/taxesSlice'
import {
  fetchTaxRulesByIds,
  getFields as getTaxRuleFields,
  getTaxRuleTitle,
  parseTaxRule,
} from 'reducers/taxes/taxRulesSlice'
import {
  getTemplateSuppliersById,
  getFields as getTemplateSupplierFields,
  getTemplateSupplierTitle,
  parseTemplateSupplier,
} from 'reducers/template-suppliers/templateSuppliersSlice'
import {
  fetchTimeEntriesByIds,
  getFields as getTimeEntryFields,
  getTitle as getTiemEntryTitle,
  parseTimeEntry,
} from 'reducers/time-entries/timeEntriesSlice'
import {
  fetchTransactionsByIds,
  getFields as getTransactionFields,
  getTransactionTitle,
  parseTransaction,
  fetchTransactionItemsByIds,
  getItemFields as getTransactionItemFields,
  getTransactionItemTitle,
  parseTransactionItem,
} from 'reducers/transactions/transactionsSlice'
import {
  fetchTreatmentsByIds,
  getTitle as getTreatmentTitle,
  getFields as getTreatmentFields,
  parseTreatment,
} from 'reducers/treatments/treatmentsSlice'
import {
  fetchUnitedNationsNumbersByIds,
  getFields as getUnitedNationsNumberFields,
  getUnitedNationsNumberTitle,
  parseUnitedNationsNumber,
} from 'reducers/united-nations-numbers/unitedNationsNumbersSlice'

import { DevLogs } from './devLogs'
import { upperCaseFirstLetter } from './stringUtils'

export type EntityLinkType = ({
  type: 'fullcard',
} | {
  type: 'navigation',
  url: string,
  name?: LinkTypeName
})[]

export type LinkTypeName = 'vendors' | 'customers'

export type EntityType<
  Entity extends DefaultRecord = any,
  EntityApi extends DefaultRecord = any,
> = ({
  /**
   * The back-end data-source-dictionnary.js name.
   */
  coreRelationName: string;
  /**
   * The new renamed relation name, defaults to coreRelationName.
   */
  relationName?: string;
} | {
  /**
   * The back-end data-source-dictionnary.js name.
   */
  coreRelationName?: string;
  /**
   * The new renamed relation name, defaults to coreRelationName.
   */
  relationName: string;
}) & {
  /**
   * Overwrites socket path in socket.ts for function useSocketEntityUpdates.
   * Defaults to entity name.
   */
  socketPath?: string
  /**
   * Overwrites entityName when set. Used when core still uses an old entityName in transaction and events.
   */
  legacyEntityName?: string;
  /**
   * The translation file for the entity (in the ./locales/{language} folder)
   */
  translationFile: string;
  /**
   * The function that returns the fields of the entity.
   */
  getFields: Function;
  /**
   * The namespace used to translate the entity's fields.
   *
   * In more complex cases, you can pass a function that will return the namespace
   * i.e: the path in not `path.{field}` but `path.{some value based on the options}.{field}`
   */
  namespace:
  | string | string[]
  | ((entityType: EntityType, fieldKey: string, options: any) => string | string[]);
  /**
   * The translation files for the relations of the entity.
   *
   * If the entity used locales from another file, but the file here.
   *
   */
  relationTranslationFiles: string[];

  /**
   * The additional information needed to display the rich text editor.
   */
  richTextEditorFields?: {
    textValue: keyof EntityApi;
    htmlValue: keyof EntityApi;
  }

  /**
   * The function that fetches the entity by ids.
   * @param ids
   */
  fetcher: (ids: (string | number)[], data?: any) => Promise<Entity[]>;

  /**
   * The function to update the entity
   */
  updater?(ids: (string | number)[], data: Partial<EntityApi>[], mapData?: Record<string, any>): Promise<Entity[]>
  /**
   * The function that returns the title of the entity.
   * Used to display the entity instead of the id.
   *
   * @param entity
   */
  getTitle: (entity: Entity, field?: string, t?: TFuncKey) => string;
  /**
   * The function that returns the dimension of the entity.
   * Used to parse some measure fields.
   *
   * @param entity The parsed entity data from core
   * @param entityField The field of the entity that needs the dimension
   */
  getDimension: (entity: Entity, entityField: keyof Entity | null, field: FieldType<any, any>) => string | null;
  /**
   * The function that returns the unit of the entity.
   * Used to parse some measure fields.
   *
   * @param entity The parsed entity data from core
   * @param entityField The field of the entity that needs the unit
   */
  getUnit: (entity: Entity, entityField: keyof Entity | null, field: FieldType<any, any>) => string | null;
  /**
   * The function that parses the details of the entity.
   * Used to hide some details or to parse some fields.
   *
   * @param details
   */
  parseDetails?: (details: any[]) => object[];
  /**
   * The function that parses the event.
   * Used to hide entire events or parse it.
   *
   * @param event the event from core
   */
  formatEvent?: (event: any) => { handled: boolean; event: any };
  /**
   * The function that parses the icon of the entity.
   * Used to add a custom icon to an event.
   *
   * @param event the event from core
   * @param icon the default icon based on the type (See SmartHistory.js getIcon())
   */
  parseIcon?: (event: any, icon: string) => string;
  /**
   * The function that parses the entity from core.
   *
   * @param Object the entity from core
   */
  parser: (data: EntityApi, options?: Record<string, any>) => Entity;
  /**
   * The type of the link.
   */
  linkType?: EntityLinkType;
}

export type EntityName = 'sales-orders' | 'purchase-orders' | 'inventories' | 'planned-ledgers' | 'shipments'
  | 'shipment-line-items' | 'items' | 'equipments' | 'cards' | 'card-groups' | 'receptions' | 'reception-items'
  | 'purchase-order-items' | 'sales-order-items' | 'sales-order-item-groups' | 'contact-addresses' | 'addresses'
  | 'locations' | 'projects' | 'bills' | 'bill-items' | 'invoices' | 'invoice-items' | 'contacts' | 'contact-categories'
  | 'plants' | 'tares' | 'contact-persons' | 'resources' | 'checklists' | 'checklist-items' | 'checklist-templates'
  | 'price-lists' | 'time-entries' | 'shifts' | 'email-templates' | 'attributes' | 'attribute-options'
  | 'chart-of-accounts' | 'consignment-items' | 'custom-identity-providers' | 'reporting-tags'| 'reporting-tag-options'
  | 'countries' | 'country-of-origins'| 'harmonized-system-codes' | 'contact-carrier-accounts' | 'item-reporting-tags'
  | 'incoterms' | 'sales-order-item-reporting-tags' | 'purchase-order-item-reporting-tags' | 'card-reporting-tags'
  | 'labels'| 'shipment-lists' | 'dashboards' | 'companies' | 'outputs' | 'raws' | 'materials' | 'categories'
  | 'treatments' | 'shipment-planning' | 'clients' | 'readings-register' | 'taxes' | 'taxes-by-region' | 'tax-rules'
  | 'states' | 'price-list-items' | 'united-nations-numbers' | 'synonyms' | 'planned-consumptions' | 'custom-fields'
  | 'transactions' | 'transaction-items' | 'template-suppliers' | 'inventory-conditions'
export const entities: Record<EntityName, EntityType> = {
  'sales-orders': {
    translationFile: 'salesOrders',
    coreRelationName: 'salesOrder',
    getFields: getSalesOrderFields,
    namespace: 'salesOrders:salesOrder.fields',
    fetcher: _fetchSalesOrderByIds,
    getTitle: getSalesOrderTitle,
    linkType: [{
      type: 'navigation',
      url: '/sales-orders',
    }],
    relationTranslationFiles: [
      'addresses',
      'contacts',
      'projects',
      'shipments',
      'inventories',
      'consignmentItems',
    ],
    getDimension: (entity, entityField) => null,
    getUnit: (entity, entityField) => null,
    parseDetails: (details) => {
      const parsedDetails = [
        ...details.filter((detail) => detail.field != 'config'),
        ...details
          .filter((detail) => detail.field === 'config')
          .reduce(
            (acc, detail) => [
              ...acc,
              ...Object.keys(detail.new_value)
                .filter((key) => detail.new_value[key] != detail.old_value[key])
                .map((key) => ({
                  ...detail,
                  field: key,
                  new_value: detail.new_value[key],
                  old_value: detail.old_value[key],
                })),
            ],
            [],
          )
          .map((detail: any) => ({
            ...detail,
            nameSpace:
              detail.field === 'validUntil' ?
                'common:document' :
                'salesOrders:document',
          })),
      ].filter((detail) => detail.entityName != 'items')

      return parsedDetails
    },
    formatEvent: (event) => {
      // sometimes the type is the new status
      switch (event.type) {
      case 'cancel':
      case 'close':
      case 'draft':
      case 'hold':
      case 'open': {
        return {
          handled: true,
          event: {
            ...event,
            type: 'custom-transaction',
            oldType: event.type,
            summaryKey: `salesOrders:status.changes.${event.type}`,
            details: [],
          },
        }
      }
      case 'increment-shipped-count': // ? Old name for the `ship` event
      case 'ship': {
        const details = reduceEventToDetails(event).filter(
          (event: any) =>
            event.entityName != 'ledgers' && event.entityName != 'planned-ledgers',
        )

        return {
          handled: true,
          event: {
            ...event,
            type: 'custom-transaction',
            oldType: event.type,
            summaryKey: `shipments:status.changes.4`,
            icon: 'truck-arrow-right',
            details: [],
            transactionDetails: details,
          },
        }
      }
      }

      const events = [
        ...event.transactionDetails.map((transactionDetail: any) => {
          return (transactionDetail.details?.updates ?? []).map((event: any) => ({
            entityName: transactionDetail.transactionEntityName,
            ...event,
          }))
        }),
        ...(event.details?.updates ?? []),
      ].flat()

      const statusEvent = events.find((event) => event.field === 'status')

      const newStatus = statusEvent?.new_value

      if (!newStatus) {
        return {
          handled: false,
          event,
        }
      }

      const summaryKey =
        `${getEntityTranslationFile(statusEvent.entityName ?? 'salesOrders')}` +
        `:status.changes.${newStatus}`

      return {
        handled: true,
        event: {
          ...event,
          type: newStatus,
          oldType: event.type,
          summaryKey,
          icon:
            newStatus === 'quote_submitted' ?
              'envelope-circle-check' :
              undefined,
        },
      }
    },
    parseIcon: (event, icon) => {
      switch (event.type) {
      case 'create-transaction':
        if (event.transactionEntityName === 'invoices') {
          return 'edit'
        }
        return icon
      default:
        return icon
      }
    },
    parser: parseSalesOrder,
  },
  'purchase-orders': {
    translationFile: 'purchaseOrders',
    coreRelationName: 'purchaseOrder',
    getFields: getPurchaseOrdersFields,
    namespace: 'purchaseOrders:purchaseOrder.fields',
    fetcher: fetchPurchaseOrderByIds,
    getTitle: getPurchaseOrderTitle,
    relationTranslationFiles: [
      'addresses',
      'contacts',
      'plants',
      'inventories',
    ],
    linkType: [{
      type: 'navigation',
      url: '/purchase-orders',
    }],
    getDimension: (entity, entityField) => null,
    getUnit: (entity, entityField) => null,
    parseDetails: (details) => {
      return [
        ...details.filter((detail) => detail.field != 'config'),
        ...details
          .filter((detail) => detail.field === 'config')
          .reduce(
            (acc, detail) => [
              ...acc,
              ...Object.keys(detail.new_value)
                .filter((key) => detail.new_value[key] != detail.old_value[key])
                .map((key) => ({
                  ...detail,
                  field: key,
                  new_value: detail.new_value[key],
                  old_value: detail.old_value[key],
                })),
            ],
            [],
          ),
      ].filter((detail) => detail.entityName != 'ledgers')
    },
    formatEvent: (event) => {
      switch (event.type) {
      case 'issue':
      case 'close':
      case 'reopen':
      case 'cancel': {
        return {
          handled: true,
          event: {
            ...event,
            type: 'custom-transaction',
            oldType: event.type,
            summaryKey: `purchaseOrders:status.changes.${event.type}`,
            details: [],
          },
        }
      }
      case 'receive': {
        return {
          handled: true,
          event: {
            ...event,
            type: 'custom-transaction',
            oldType: event.type,
            summaryKey: `inventories:status.changes.${event.type}`,
          },
        }
      }
      case 'split-lines': {
        return {
          handled: false,
          event: {
            ...event,
            type: 'update-transaction',
            oldType: event.type,
            summaryKey:
                'purchaseOrders:purchaseOrder.actions.split.eventTitle',
            icon: 'split',
          },
        }
      }
      default:
        return {
          handled: false,
          event,
        }
      }
    },
    parser: parsePurchaseOrder,
  },
  'inventories': {
    translationFile: 'inventories',
    coreRelationName: 'inventory',
    getFields: getInventoriesFields,
    namespace: 'inventories:inventory.fields',
    fetcher: ((ids: string[], data) => fetchInventoryByIds(ids, undefined, data, 'POST')) as EntityType['fetcher'],
    getTitle: getInventoryTitle as EntityType['getTitle'],
    relationTranslationFiles: ['contacts', 'purchaseOrders', 'consignmentItems', 'salesOrders'],
    getDimension: (entity, entityField) => entity?.dimension,
    getUnit: (entity, entityField) => entity?.measureUnit,
    parser: (data, options) => parseInventory(data, options?.defaultUnits, options?.dataSetName),
    updater: (ids, data, mapData) => _updateInventories(ids as string[], data, mapData),
    richTextEditorFields: {
      textValue: 'trimmed_instructions',
      htmlValue: 'instructions',
    },
    linkType: [{
      type: 'fullcard',
    }],
    socketPath: 'raw-materials',
  },
  'planned-ledgers': {
    translationFile: 'plannedLedgers',
    coreRelationName: 'plannedLedger',
    getFields: getPlannedLedgerFields,
    namespace: 'plannedLedgers:plannedLedger.fields',
    fetcher: fetchPlannedLedgerByIds as EntityType['fetcher'],
    getTitle: getPlannedLedgerTitle as EntityType['getTitle'],
    relationTranslationFiles: ['inventories', 'items', 'salesOrders', 'addresses'],
    getDimension: (entity, entityField) => null,
    getUnit: (entity, entityField) => null,
    parser: parsePlannedLedger,
  },
  'shipments': {
    translationFile: 'shipments',
    coreRelationName: 'shipment',
    getFields: getShipmentsFields,
    namespace: 'shipments:shipment.fields',
    fetcher: fetchShipmentsByIds as EntityType['fetcher'],
    getTitle: getShipmentTitle as EntityType['getTitle'],
    relationTranslationFiles: [
      'addresses',
      'contacts',
      'salesOrders',
      'inventories',
      'shipmentLists',
    ],
    linkType: [{
      type: 'fullcard',
    }],
    getDimension: (entity, entityField) => null,
    getUnit: (entity, entityField) => null,
    parser: (data, options) => parseShipment(data, options?.onlyParseAvailableFields),
  },
  'shipment-line-items': {
    translationFile: 'shipments',
    coreRelationName: 'shipmentLineItem',
    getFields: getLineItemFields,
    namespace: 'shipments:shipment.item',
    fetcher: fetchShipmentItemsbyIds,
    getTitle: getShipmentItemTitle as EntityType['getTitle'],
    relationTranslationFiles: ['addresses', 'contacts'],
    getDimension: (entity, entityField) => entity?.dimension,
    getUnit: (entity, entityField) => entity?.unit,
    parser: (data, options) => parseShipmentItem(data, options, options?.onlyParseAvailableFields),
  },
  'items': {
    legacyEntityName: 'templates',
    socketPath: 'templates',
    translationFile: 'items',
    coreRelationName: 'template',
    relationName: 'item',
    getFields: getFields,
    namespace: 'items:item.fields',
    fetcher: _fetchItemByIds,
    getTitle: getItemTitle,
    relationTranslationFiles: ['cards', 'reportingTags'],
    getDimension: (entity, entityField) => entity?.dimension,
    getUnit: (entity, entityField) => entity?.unit,
    parser: parseItem,
    updater: _updateItems,
    richTextEditorFields: {
      textValue: 'trimmed_instructions',
      htmlValue: 'instructions',
    },
    linkType: [{
      type: 'fullcard',
    }],
  },
  'equipments': {
    translationFile: 'equipment',
    coreRelationName: 'equipment',
    getFields: getEquipmentFields,
    namespace: 'equipment:equipment',
    fetcher: fetchEquipmentsByIds,
    getTitle: getEquipmentTitle as EntityType['getTitle'],
    relationTranslationFiles: ['addresses', 'contacts'],
    getDimension: (entity, entityField) => entity?.dimension,
    getUnit: (entity, entityField) => entity?.unit,
    parser: (data, options) => parseEquipment(data, options?.equipmentsNumberConfig),
  },
  'cards': {
    legacyEntityName: 'items',
    translationFile: 'cards',
    coreRelationName: 'item',
    relationName: 'card',
    getFields: getCardFields,
    namespace: 'cards:card.fields',
    fetcher: fetchCardsByIds,
    getTitle: getCardtitle,
    relationTranslationFiles: ['addresses', 'contacts'],
    getDimension: (entity, entityField) => entity?.dimension,
    getUnit: (entity, entityField) => entity?.unit,
    parser: parseCard,
    updater: (ids, data, mapData) => updateCards(ids, data, mapData),
    richTextEditorFields: {
      textValue: 'trimmed_instructions',
      htmlValue: 'instructions',
    },
    linkType: [{
      type: 'fullcard',
    }],
  },
  'card-groups': {
    socketPath: 'items/groups',
    translationFile: 'cardGroups',
    coreRelationName: 'cardGroup',
    getFields: getCardGroupFields,
    namespace: (entity, field, options = {}) => {
      const fieldState = valueOrDefault(options.fieldState, 'label')
      return `${entity.translationFile}:${entity.coreRelationName}.fields.${field}.${fieldState}`
    },
    fetcher: fetchCardGroupsByIds,
    getTitle: getCardGroupTitle,
    relationTranslationFiles: [],
    getDimension: (entity, entityField) => null,
    getUnit: (entity, entityField) => null,
    parser: parseCardGroup,
  },
  'receptions': ReceptionEntity,
  'reception-items': ReceptionItemEntity,
  'purchase-order-items': {
    translationFile: 'purchaseOrders',
    coreRelationName: 'purchaseOrderItem',
    getFields: getPurchaseOrderItemsFields,
    namespace: 'purchaseOrders:item.fields',
    fetcher: fetchPurchaseOrderItemByIds,
    getTitle: getPurchaseOrderItemTitle,
    relationTranslationFiles: ['addresses'],
    getDimension: (entity, entityField) => entity?.dimension,
    getUnit: (entity, entityField) => entity?.unit,
    parser: (data, options) => parsePurchaseOrderLineItem(
      data,
      options?.defaultUnits,
      options?.isPrimaryLanguage,
      options?.isDocumentPrimaryLanguage,
    ),
  },
  'sales-order-items': {
    translationFile: 'salesOrders',
    coreRelationName: 'salesOrderItem',
    getFields: getSalesOrderItemsFields,
    namespace: 'salesOrders:salesOrder.item',
    fetcher: fetchSalesOrderItemByIds,
    getTitle: getSalesOrderItemTitle,
    relationTranslationFiles: ['addresses', 'items'],
    getDimension: (entity, entityField) => entity?.dimension,
    getUnit: (entity, entityField) => entity?.unit,
    parser: (data, options) => parseSalesOrderLineItem(
      data,
      {
        defaultUnits: options?.defaultUnits,
        isPrimaryLanguage: options?.isPrimaryLanguage,
        isDocumentPrimaryLanguage: options?.isDocumentPrimaryLanguage,
        taxDict: options?.taxDict,
        priceMaxDigits: options?.priceMaxDigits,
        measureMaxDigits: options?.measureMaxDigits,
      },
    ),
  },
  'sales-order-item-groups': {
    translationFile: 'salesOrders',
    coreRelationName: 'quoteGroupingRow',
    getFields: getSalesOrderItemGroupFields,
    namespace: 'salesOrders:salesOrder.group',
    fetcher: fetchSalesOrderItemGroupByids,
    getTitle: getSalesOrderItemGroupTitle,
    relationTranslationFiles: [],
    getDimension: (entity, entityField) => null,
    getUnit: (entity, entityField) => null,
    parser: parseSalesOrderGroup,
  },
  'contact-addresses': {
    translationFile: 'addresses',
    coreRelationName: 'contactAddressRel',
    getFields: getContactAddressFields,
    namespace: (entity, field, options = {}) => {
      const fieldState = valueOrDefault(options.fieldState, 'label')
      return `${entity.translationFile}:address.fields.${field}.${fieldState}`
    },
    fetcher: fetchContactAddressesByIds,
    getTitle: getAddressTitle,
    relationTranslationFiles: [],
    getDimension: (entity, entityField) => null,
    getUnit: (entity, entityField) => null,
    parser: parseAddress,
  },
  'addresses': {
    translationFile: 'addresses',
    coreRelationName: 'address',
    getFields: getAddressFields,
    namespace: (entity, field, options = {}) => {
      const fieldState = valueOrDefault(options.fieldState, 'label')
      return `${entity.translationFile}:${entity.coreRelationName}.fields.${field}.${fieldState}`
    },
    fetcher: fetchAddressByIds,
    getTitle: getAddressTitle,
    relationTranslationFiles: [],
    getDimension: (entity, entityField) => null,
    getUnit: (entity, entityField) => null,
    parser: parseAddress,
  },
  'locations': {
    translationFile: 'locations',
    coreRelationName: 'location',
    getFields: getLocationFields,
    namespace: 'locations:location.fields',
    fetcher: fetchLocationByIds,
    getTitle: getLocationTitle,
    relationTranslationFiles: [],
    getDimension: (entity, entityField) => null,
    getUnit: (entity, entityField) => null,
    parser: parseLocation,
  },
  'projects': {
    translationFile: 'projects',
    coreRelationName: 'project',
    getFields: getProjectFields,
    namespace: 'projects:project.fields',
    fetcher: fetchProjectByIds,
    getTitle: getProjectTitle,
    relationTranslationFiles: ['plants'],
    getDimension: (entity, entityField) => null,
    getUnit: (entity, entityField) => null,
    parser: parseProject,
  },
  'bills': {
    translationFile: 'bills',
    coreRelationName: 'bill',
    getFields: getBillFields,
    namespace: (entity, field, options = {}) => {
      const fieldState = valueOrDefault(options.fieldState, 'label')
      return `${entity.translationFile}:bill.fields.${field}.${fieldState}`
    },
    fetcher: fetchBillsByIds,
    getTitle: getBillTitle,
    relationTranslationFiles: ['addresses'],
    getDimension: (entity, entityField) => null,
    getUnit: (entity, entityField) => null,
    parser: parseBill,
    linkType: [{
      type: 'navigation',
      url: '/bills',
    }],
  },
  'bill-items': {
    translationFile: 'bills',
    coreRelationName: 'billItem',
    getFields: getBillItemFields,
    namespace: (entity, field, options = {}) => {
      const fieldState = valueOrDefault(options.fieldState, 'label')
      return `${entity.translationFile}:billItem.fields.${field}.${fieldState}`
    },
    fetcher: fetchBillItemsByIds,
    getTitle: getBillItemTitle,
    relationTranslationFiles: [],
    getDimension: (entity) => entity?.dimension,
    getUnit: (entity) => entity?.unit,
    parser: (data) => parseBillItem(data),
  },
  'invoices': {
    translationFile: 'invoices',
    coreRelationName: 'invoice',
    getFields: getInvoiceFields,
    namespace: (entity, field, options = {}) => {
      const fieldState = valueOrDefault(options.fieldState, 'label')
      return `${entity.translationFile}:invoice.fields.${field}.${fieldState}`
    },
    fetcher: fetchInvoicesByIds,
    getTitle: getInvoiceTitle,
    relationTranslationFiles: ['addresses'],
    getDimension: (entity, entityField) => null,
    getUnit: (entity, entityField) => null,
    parser: parseInvoice,
    linkType: [{
      type: 'navigation',
      url: '/invoices',
    }],
  },
  'invoice-items': {
    translationFile: 'invoices',
    coreRelationName: 'invoiceItem',
    getFields: getInvoiceItemFields,
    namespace: (entity, field, options = {}) => {
      const fieldState = valueOrDefault(options.fieldState, 'label')
      return `${entity.translationFile}:invoiceItem.fields.${field}.${fieldState}`
    },
    fetcher: fetchInvoiceItemsByIds,
    getTitle: getInvoiceItemTitle,
    relationTranslationFiles: [],
    getDimension: (entity) => entity?.dimension,
    getUnit: (entity) => entity?.unit,
    parser: (data) => parseInvoiceItem(data),
  },
  'contacts': {
    translationFile: 'contacts',
    coreRelationName: 'contact',
    getFields: getContactFields,
    linkType: [{
      type: 'navigation',
      url: '/vendors',
      name: 'vendors',
    },
    {
      type: 'navigation',
      url: '/customers',
      name: 'customers',
    }],
    namespace: (entity, field, options = {}) => {
      const columnsToCustomMap = ['relatedContactDisplayName', 'displayName']

      const customRelationName = options.type ?? entity.coreRelationName

      const relationName = columnsToCustomMap.includes(field) ?
        customRelationName :
        entity.coreRelationName

      const fieldState = valueOrDefault(options.fieldState, 'label')

      return `${entity.translationFile}:${relationName}.fields.${field}.${fieldState}`
    },
    fetcher: fetchContactByIds,
    getTitle: getContactTitle,
    relationTranslationFiles: ['addresses', 'taxes', 'taxRules'],
    getDimension: (entity, entityField) => null,
    getUnit: (entity, entityField) => null,
    parseIcon: (event, icon) => {
      switch (event.type) {
      case 'unlink':
        return 'unlink'
      case 'link':
        return 'link'
      case 'activate': {
        return 'user-plus'
      }
      case 'inactivate': {
        return 'user-minus'
      }
      default:
        return icon
      }
    },
    formatEvent: (event) => {
      switch (event.type) {
      case 'contacts-merge':
      case 'primary-contact-change':
      case 'link':
      case 'unlink': {
        return {
          handled: true,
          event: {
            ...event,
            summaryKey: `contacts:events.${event.type}.title`,
          },
        }
      }

      default:
        return {
          handled: false,
          event: event,
        }
      }
    },
    parseDetails: (details) => {
      return details.filter((detail) => {
        const { entityName, type } = detail

        return entityName !== 'contact-addresses' || type !== 'create'
      })
    },
    parser: parseContact,
  },
  'contact-categories': {
    translationFile: 'contactCategories',
    coreRelationName: 'contactCategory',
    getFields: getContactCategoryFields,
    namespace: 'contactCategories:contactCategory.fields',
    fetcher: fetchContactCategoriesByIds,
    getTitle: getContactCategoryTitle,
    relationTranslationFiles: [],
    getDimension: (entity, entityField) => null,
    getUnit: (entity, entityField) => null,
    parser: (data, options) => parseContactCategory(data, options?.defaultUnits),
  },
  'plants': {
    translationFile: 'plants',
    coreRelationName: 'plant',
    getFields: getPlantFields,
    namespace: 'plants:plant.fields',
    fetcher: fetchPlantByIds,
    getTitle: getPlantTitle,
    relationTranslationFiles: [],
    getDimension: (entity, entityField) => null,
    getUnit: (entity, entityField) => null,
    parser: parsePlant,
  },
  'tares': {
    translationFile: 'tares',
    coreRelationName: 'tare',
    getFields: getTareFields,
    namespace: 'tares:tare.fields',
    fetcher: fetchTaresByIds,
    getTitle: getTareTitle,
    relationTranslationFiles: [],
    getDimension: (entity, entityField) => null,
    getUnit: (entity, entityField) => null,
    parser: parseTare,
  },
  'contact-persons': {
    translationFile: 'contacts',
    coreRelationName: 'contactPerson',
    getFields: getContactPersonFields,
    namespace: (entity, field, options = {}) => {
      const fieldState = valueOrDefault(options.fieldState, 'label')
      return `${entity.translationFile}:${entity.coreRelationName}.fields.${field}.${fieldState}`
    },
    fetcher: fetchContactPersonByIds,
    getTitle: getContactPersonTitle,
    relationTranslationFiles: [],
    getDimension: (entity, entityField) => null,
    getUnit: (entity, entityField) => null,
    parser: parseContactPerson,
  },
  'resources': {
    translationFile: 'profile',
    coreRelationName: 'resource',
    getFields: getResourceFields,
    namespace: 'profile:profile',
    fetcher: fetchResourceByIds,
    getTitle: getResourceTitle as EntityType['getTitle'],
    relationTranslationFiles: [],
    getDimension: (entity, entityField) => null,
    getUnit: (entity, entityField) => null,
    parser: parseResource,
  },
  'checklists': {
    translationFile: 'checklists',
    coreRelationName: 'checklist',
    getFields: getCheckListFields,
    namespace: (entity, field, options = {}) => {
      const fieldState = valueOrDefault(options.fieldState, 'label')
      return `${entity.translationFile}:${entity.coreRelationName}.fields.${field}.${fieldState}`
    },
    fetcher: (ids, data) => _fetchChecklists(data, ids.map((id) => String(id))).then(({ checklists }) => checklists),
    getTitle: getCheckListTitle,
    relationTranslationFiles: [],
    getDimension: (entity, entityField) => null,
    getUnit: (entity, entityField) => null,
    parser: parseCheckList,
  },
  'checklist-items': {
    socketPath: 'checklists/items',
    translationFile: 'checklists',
    coreRelationName: 'checklistItems',
    getFields: getCheckListItemFields,
    namespace: (entity, field, options = {}) => {
      const fieldState = valueOrDefault(options.fieldState, 'label')
      return `${entity.translationFile}:${entity.coreRelationName}.fields.${field}.${fieldState}`
    },
    fetcher: (ids, data) => _fetchChecklistItems(ids.map((id) => String(id)), data)
      .then(({ checklistItems }) => checklistItems),
    getTitle: getCheckListItemTitle,
    relationTranslationFiles: [],
    getDimension: (entity, entityField) => null,
    getUnit: (entity, entityField) => null,
    parser: parseCheckListItem,
  },
  'checklist-templates': {
    translationFile: '',
    coreRelationName: 'checklistTemplate',
    getFields: getCheckListTemplateFields,
    namespace: '',
    fetcher: (ids) => _fetchChecklistTemplates(undefined, ids.map((id) => String(id))),
    getTitle: getCheckListTitle,
    relationTranslationFiles: [],
    getDimension: (entity, entityField) => null,
    getUnit: (entity, entityField) => null,
    parser: parseCheckListTemplate,
  },
  'price-lists': {
    translationFile: 'priceLists',
    coreRelationName: 'priceList',
    getFields: getPriceListFields,
    namespace: 'priceLists:priceList.fields',
    fetcher: fetchPriceListsByIds,
    getTitle: getPriceListTitle,
    relationTranslationFiles: [],
    getDimension: (entity, entityField) => null,
    getUnit: (entity, entityField) => null,
    parser: parsePriceList,
  },
  'price-list-items': {
    translationFile: 'priceLists',
    coreRelationName: 'priceListItem',
    getFields: getPriceListItemFields,
    namespace: 'priceLists:priceList.fields',
    fetcher: () => Promise.resolve([]), // TODO (bzoretic): finish implementation
    getTitle: getPriceListItemTitle,
    relationTranslationFiles: [],
    getDimension: (entity, entityField) => null,
    getUnit: (entity, entityField) => null,
    parser: parsePriceListItem,
  },
  'time-entries': {
    translationFile: 'timeEntries',
    coreRelationName: 'timeEntry',
    getFields: getTimeEntryFields,
    namespace: 'timeEntries:timeEntry.fields',
    fetcher: fetchTimeEntriesByIds,
    getTitle: getTiemEntryTitle,
    relationTranslationFiles: [
      'shifts', 'resources',
    ],
    getDimension: (entity, entityField) => null,
    getUnit: (entity, entityField) => null,
    parser: parseTimeEntry,
    formatEvent: (event) => {
      const filteredTransactionDetails = event.
        transactionDetails.map((details) => {
          return {
            ...details,
            details: {
              ...details.details,
              updates: details.details.updates?.map((update) => {
                /**
                   * To correcly parse the field name, we need the `id` part to be `Id`
                   *
                   * See the `removeIdOfFieldName()` function
                   */
                if (update.field === 'resourceid') {
                  update.field = 'resourceId'
                }

                return update
              }),
            },
          }
        })

      event = {
        ...event,
        transactionDetails: filteredTransactionDetails,
      }

      if (event.type.startsWith('clock-')) {
        /**
         * The format of the event is : `clock-{action}[-{breakType}]` (ex: `clock-in` or `clock-in-diner`)
         */
        const parts = event.type.split('-')
        const action = parts[1]
        const breakType = parts.length > 2 ? parts[2] : null

        const summaryKey = `timeEntries:actions.clock` +
          `${upperCaseFirstLetter(action)}${breakType ? upperCaseFirstLetter(breakType) : ''}.eventTitle`

        let icon

        if (breakType === 'break') icon = action === 'in' ? 'pause' : 'play'
        else if (breakType === 'diner') icon = action === 'in' ? 'utensils' : 'play'
        else icon = action === 'in' ? 'right-to-bracket' : 'right-from-bracket'

        return {
          handled: true,
          event: {
            ...event,
            summaryKey,
            icon: icon,
            type: 'create-transaction',
          },
        }
      }

      return {
        handled: false,
        event,
      }
    },
  },
  'shifts': {
    translationFile: 'shifts',
    coreRelationName: 'shift',
    getFields: getShiftsFields,
    namespace: 'shifts:shift.fields',
    fetcher: fetchShiftsByIds,
    getTitle: getShiftTitle,
    relationTranslationFiles: [],
    getDimension: (entity, entityField) => null,
    getUnit: (entity, entityField) => null,
    parser: parseShift,
  },
  'email-templates': EmailTemplateEntity,
  'custom-identity-providers': {
    translationFile: 'customIdentityProviders',
    coreRelationName: 'customIdentityProvider',
    getFields: getCustomIdentityProvidersFields,
    namespace: (entity, field, options = {}) => {
      const fieldState = valueOrDefault(options.fieldState, 'label')
      return `${entity.translationFile}:${entity.coreRelationName}.fields.${field}.${fieldState}`
    },
    fetcher: fetchCustomIdentityProvidersByIds,
    getTitle: getCustomIdentityProviderTitle,
    relationTranslationFiles: [],
    getDimension: (entity, entityField) => null,
    getUnit: (entity, entityField) => null,
    parser: parseCustomIdentityProvider,
  },
  'consignment-items': {
    translationFile: 'consignmentItems',
    coreRelationName: 'consignmentItem',
    getFields: getConsignmentItemFields,
    namespace: (entity, field, options = {}) => {
      const fieldState = valueOrDefault(options.fieldState, 'label')
      return `${entity.translationFile}:${entity.coreRelationName}.fields.${field}.${fieldState}`
    },
    fetcher: fetchConsignmentItemsByIds,
    getTitle: getConsignmentItemTitle,
    relationTranslationFiles: [],
    getDimension: (entity, entityField) => entity?.dimension,
    getUnit: (entity, entityField) => entity?.unit,
    parser: (data, options) => parseConsignmentItem(data, options),
  },
  'attributes': {
    translationFile: 'attributes',
    coreRelationName: 'attribute',
    getFields: getAttributeFields,
    namespace: (entity, field, options = {}) => {
      const fieldState = valueOrDefault(options.fieldState, 'label')
      return `${entity.translationFile}:${entity.coreRelationName}.fields.${field}.${fieldState}`
    },
    fetcher: fetchAttributesByIds,
    getTitle: getAttributeTitle,
    relationTranslationFiles: [],
    getDimension: (entity, entityField) => null,
    getUnit: (entity, entityField) => null,
    parser: parseAttribute,
  },
  'attribute-options': {
    translationFile: 'attributes',
    coreRelationName: 'attributeOption',
    getFields: getAttributeOptionsFields,
    namespace: (entity, field, options = {}) => {
      const fieldState = valueOrDefault(options.fieldState, 'label')
      return `${entity.translationFile}:${entity.coreRelationName}.fields.${field}.${fieldState}`
    },
    fetcher: fetchAttributeOptionsByIds,
    getTitle: getAttributeOptionTitle,
    relationTranslationFiles: [
      'items',
    ],
    getDimension: (entity, entityField) => null,
    getUnit: (entity, entityField) => null,
    parser: parseAttributeOption,
  },
  'chart-of-accounts': {
    translationFile: 'accounting',
    coreRelationName: 'chartOfAccounts',
    getFields: getChartOfAccountFields,
    namespace: (entity, field, options = {}) => {
      const fieldState = valueOrDefault(options.fieldState, 'label')
      return `${entity.translationFile}:${entity.coreRelationName}.fields.${field}.${fieldState}`
    },
    fetcher: fetchChartsOfAccountByIds,
    getTitle: parseDisplayTitle,
    relationTranslationFiles: [],
    getDimension: (entity, entityField) => null,
    getUnit: (entity, entityField) => null,
    parser: parseChartOfAccounts,
  },
  'reporting-tags': {
    translationFile: 'reportingTags',
    coreRelationName: 'reportingTag',
    getFields: getReportingTagFields,
    namespace: (entity, field, options = {}) => {
      const fieldState = valueOrDefault(options.fieldState, 'label')
      return `${entity.translationFile}:${entity.coreRelationName}.fields.${field}.${fieldState}`
    },
    fetcher: fetchReportingTagsByIds,
    getTitle: getReportingTagTitle,
    relationTranslationFiles: [],
    getDimension: (entity, entityField) => null,
    getUnit: (entity, entityField) => null,
    parser: (data, options) => parseReportingTag(data, options),
  },
  'reporting-tag-options': {
    translationFile: 'reportingTags',
    coreRelationName: 'reportingTagOption',
    getFields: getReportingTagOptionFields,
    namespace: (entity, field, options = {}) => {
      const fieldState = valueOrDefault(options.fieldState, 'label')
      return `${entity.translationFile}:${entity.coreRelationName}.fields.${field}.${fieldState}`
    },
    fetcher: fetchReportingTagOptionsByIds,
    getTitle: getReportingTagOptionTitle,
    relationTranslationFiles: [],
    getDimension: (entity, entityField) => null,
    getUnit: (entity, entityField) => null,
    parser: (data, options) => parseReportingTagOption(data, options),
  },
  'dashboards': {
    translationFile: 'dashboards',
    relationName: 'dashboard',
    getFields: getDashboardFields,
    namespace: (entity, field, options = {}) => {
      const fieldState = valueOrDefault(options.fieldState, 'label')
      return `${entity.translationFile}:${entity.relationName}.fields.${field}.${fieldState}`
    },
    fetcher: fetchDashboardsByIds,
    getTitle: getDashboardTitle,
    relationTranslationFiles: [],
    getDimension: (entity, entityField) => null,
    getUnit: (entity, entityField) => null,
    parser: (data, options) => parseDashboard(data, options as DashboardMapData),
  },
  'item-reporting-tags': {
    legacyEntityName: 'template-reporting-tags',
    translationFile: 'reportingTags',
    coreRelationName: itemReportingTagDataSetName,
    getFields: () => getReportingTagAssociationFields('items'),
    namespace: (entity, field, options = {}) => {
      const fieldState = valueOrDefault(options.fieldState, 'label')
      return `${entity.translationFile}:reportingTag.fields.${field}.${fieldState}`
    },
    fetcher: fetchItemReportingTagsByIds,
    getTitle: getReportingTagAssociationTitle,
    relationTranslationFiles: [],
    getDimension: (entity, entityField) => null,
    getUnit: (entity, entityField) => null,
    parser: (data, options) => parseReportingTagAssociation('items', data, options),
  },
  'sales-order-item-reporting-tags': {
    translationFile: 'reportingTags',
    coreRelationName: salesOrderItemReportingTagDataSetName,
    getFields: () => getReportingTagAssociationFields('sales-order-items'),
    namespace: (entity, field, options = {}) => {
      const fieldState = valueOrDefault(options.fieldState, 'label')
      return `${entity.translationFile}:reportingTag.fields.${field}.${fieldState}`
    },
    fetcher: fetchSalesOrderItemsReportingTagsByIds,
    getTitle: getReportingTagAssociationTitle,
    relationTranslationFiles: [],
    getDimension: (entity, entityField) => null,
    getUnit: (entity, entityField) => null,
    parser: (data, options) => parseReportingTagAssociation('sales-order-items', data, options),
  },
  'purchase-order-item-reporting-tags': {
    translationFile: 'reportingTags',
    coreRelationName: purchaseOrderItemReportingTagDataSetName,
    getFields: () => getReportingTagAssociationFields('purchase-order-items'),
    namespace: (entity, field, options = {}) => {
      const fieldState = valueOrDefault(options.fieldState, 'label')
      return `${entity.translationFile}:reportingTag.fields.${field}.${fieldState}`
    },
    fetcher: fetchPurchaseOrderItemsReportingTagsByIds,
    getTitle: getReportingTagAssociationTitle,
    relationTranslationFiles: [],
    getDimension: (entity, entityField) => null,
    getUnit: (entity, entityField) => null,
    parser: (data, options) => parseReportingTagAssociation('purchase-order-items', data, options),
  },
  'card-reporting-tags': {
    legacyEntityName: 'item-reporting-tags',
    translationFile: 'reportingTags',
    coreRelationName: cardReportingTagDataSetName,
    getFields: () => getReportingTagAssociationFields('cards'),
    namespace: (entity, field, options = {}) => {
      const fieldState = valueOrDefault(options.fieldState, 'label')
      return `${entity.translationFile}:reportingTag.fields.${field}.${fieldState}`
    },
    fetcher: () => Promise.resolve([]), // TODO (egaulin): à finir lorsque l'historique des cartes sera en react,
    getTitle: getReportingTagAssociationTitle,
    relationTranslationFiles: [],
    getDimension: (entity, entityField) => null,
    getUnit: (entity, entityField) => null,
    parser: (data, options) => parseReportingTagAssociation('cards', data, options),
  },
  'countries': {
    translationFile: 'countries',
    coreRelationName: 'country',
    getFields: getCountryFields,
    namespace: (entity, field, options = {}) => {
      const fieldState = valueOrDefault(options.fieldState, 'label')
      return `${entity.translationFile}:${entity.coreRelationName}.fields.${field}.${fieldState}`
    },
    fetcher: fetchCountriesByIds,
    getTitle: getCountryTitle,
    relationTranslationFiles: [],
    getDimension: (entity, entityField) => null,
    getUnit: (entity, entityField) => null,
    parser: (data, options) => parseCountry(data, options),
  },
  'country-of-origins': {
    translationFile: 'countryOfOrigins',
    coreRelationName: 'countryOfOrigin',
    getFields: getCountryOfOriginFields,
    namespace: (entity, field, options = {}) => {
      const fieldState = valueOrDefault(options.fieldState, 'label')
      return `${entity.translationFile}:${entity.coreRelationName}.fields.${field}.${fieldState}`
    },
    fetcher: fetchCountryOfOriginsByIds,
    getTitle: getCountryOfOriginTitle,
    relationTranslationFiles: [],
    getDimension: (entity, entityField) => null,
    getUnit: (entity, entityField) => null,
    parser: (data, options) => parseCountryOfOrigin(data, options),
  },
  'transactions': {
    translationFile: 'transactions',
    coreRelationName: 'transaction',
    getFields: getTransactionFields,
    namespace: (entity, field, options = {}) => {
      const fieldState = valueOrDefault(options.fieldState, 'label')
      return `${entity.translationFile}:${entity.coreRelationName}.fields.${field}.${fieldState}`
    },
    fetcher: fetchTransactionsByIds,
    getTitle: getTransactionTitle,
    relationTranslationFiles: [],
    getDimension: (entity, entityField) => null,
    getUnit: (entity, entityField) => null,
    parser: (data) => parseTransaction(data),
  },
  'transaction-items': {
    translationFile: 'transactions',
    coreRelationName: 'transactionItem',
    getFields: getTransactionItemFields,
    namespace: (entity, field, options = {}) => {
      const fieldState = valueOrDefault(options.fieldState, 'label')
      return `${entity.translationFile}:${entity.coreRelationName}.fields.${field}.${fieldState}`
    },
    fetcher: fetchTransactionItemsByIds,
    getTitle: getTransactionItemTitle,
    relationTranslationFiles: [],
    getDimension: (entity, entityField) => null,
    getUnit: (entity, entityField) => null,
    parser: (data) => parseTransactionItem(data),
  },
  'incoterms': {
    translationFile: 'incoterms',
    relationName: 'incoterm',
    getFields: getIncotermFields,
    namespace: (entity, field, options = {}) => {
      const fieldState = valueOrDefault(options.fieldState, 'label')
      return `${entity.translationFile}:${entity.relationName}.fields.${field}.${fieldState}`
    },
    fetcher: fetchIncotermsByIds,
    getTitle: getIncotermTitle,
    relationTranslationFiles: [],
    getDimension: (entity, entityField) => null,
    getUnit: (entity, entityField) => null,
    parser: (data) => parseIncoterm(data),
  },
  'harmonized-system-codes': {
    translationFile: 'harmonizedSystemCodes',
    coreRelationName: 'harmonizedSystemCode',
    getFields: getHarmonizedSystemCodeFields,
    namespace: (entity, field, options = {}) => {
      const fieldState = valueOrDefault(options.fieldState, 'label')
      return `${entity.translationFile}:${entity.coreRelationName}.fields.${field}.${fieldState}`
    },
    fetcher: fetchHarmonizedSystemCodesByIds,
    getTitle: getHarmonizedSystemCodeTitle,
    relationTranslationFiles: [],
    getDimension: (entity, entityField) => null,
    getUnit: (entity, entityField) => null,
    parser: (data) => parseHarmonizedSystemCode(data),
  },
  'contact-carrier-accounts': {
    translationFile: 'contacts',
    coreRelationName: 'contactCarrierAccount',
    getFields: getContactCarrierAccountFields,
    namespace: (entity, field, options = {}) => {
      const fieldState = valueOrDefault(options.fieldState, 'label')
      return `${entity.translationFile}:${entity.coreRelationName}.fields.${field}.${fieldState}`
    },
    fetcher: fetchContactCarrierAccountsByIds,
    getTitle: getContactCarrierAccountTitle,
    relationTranslationFiles: [],
    getDimension: (entity, entityField) => null,
    getUnit: (entity, entityField) => null,
    parser: (data) => parseContactCarrierAccount(data),
  },
  'labels': {
    translationFile: 'labels',
    coreRelationName: 'label',
    getFields: getLabelFields,
    namespace: (entity, field, options = {}) => {
      const fieldState = valueOrDefault(options.fieldState, 'label')
      if (options.entityName) {
        return `${entity.translationFile}:${entity.coreRelationName}.${field}.${fieldState}`
      }
      return `${entity.translationFile}:${entity.coreRelationName}.fields.${field}.${fieldState}`
    },
    fetcher: fetchLabelsByIds,
    getTitle: getLabelTitle,
    relationTranslationFiles: ['companies', 'outputs'],
    getDimension: (entity, entityField) => null,
    getUnit: (entity, entityField) => null,
    parser: (data) => parseLabel(data),
  },
  'shipment-lists': {
    translationFile: 'shipmentLists',
    coreRelationName: 'shipmentList',
    getFields: getShipmentListFields,
    namespace: (entity, field, options = {}) => {
      const fieldState = valueOrDefault(options.fieldState, 'label')
      return `${entity.translationFile}:${entity.coreRelationName}.fields.${field}.${fieldState}`
    },
    fetcher: fetchShipmentListsByIds,
    getTitle: getShipmentListTitle,
    relationTranslationFiles: [],
    getDimension: (entity, entityField) => null,
    getUnit: (entity, entityField) => null,
    parser: parseShipmentList,
  },
  'clients': ClientEntity,
  'companies': {
    translationFile: 'companies',
    coreRelationName: 'company',
    getFields: getCompanyFields,
    namespace: (entity, field, options = {}) => {
      const fieldState = valueOrDefault(options.fieldState, 'label')
      return `${entity.translationFile}:${entity.coreRelationName}.fields.${field}.${fieldState}`
    },
    fetcher: fetchCompaniesByIds,
    getTitle: getCompanyTitle,
    relationTranslationFiles: [],
    getDimension: (entity, entityField) => null,
    getUnit: (entity, entityField) => null,
    parser: (data) => parseCompany(data),
  },
  'outputs': {
    translationFile: 'outputs',
    coreRelationName: 'output',
    getFields: getOutputFields,
    namespace: (entity, field, options = {}) => {
      const fieldState = valueOrDefault(options.fieldState, 'label')
      return `${entity.translationFile}:${entity.coreRelationName}.fields.${field}.${fieldState}`
    },
    fetcher: fetchInventoryOutputsById,
    getTitle: getOutputTitle,
    relationTranslationFiles: [],
    getDimension: () => null,
    getUnit: (entity, entityField) => getOutputUnit(entity, {}),
    parser: (data) => parseOutput(data, {}),
  },
  'readings-register': ReadingRegisterEntity,
  'shipment-planning': ShipmentPlanningEntity,
  'states': {
    translationFile: 'states',
    coreRelationName: 'state',
    getFields: getStateFields,
    namespace: (entity, field, options = {}) => {
      const fieldState = valueOrDefault(options.fieldState, 'label')
      return `${entity.translationFile}:${entity.coreRelationName}.fields.${field}.${fieldState}`
    },
    fetcher: fetchStatesByIds,
    getTitle: getStateTitle,
    relationTranslationFiles: [],
    getDimension: () => null,
    getUnit: () => null,
    parser: (data, options) => parseState(data, options),
  },
  'synonyms': {
    translationFile: 'synonyms',
    coreRelationName: 'synonym',
    getFields: getSynonymFields,
    namespace: (entity, field, options = {}) => {
      const fieldState = valueOrDefault(options.fieldState, 'label')
      return `${entity.translationFile}:${entity.coreRelationName}.fields.${field}.${fieldState}`
    },
    fetcher: fetchSynonymsByIds,
    getTitle: getSynonymTitle,
    relationTranslationFiles: [],
    getDimension: () => null,
    getUnit: () => null,
    parser: (data) => parseSynonym(data),
  },
  'taxes-by-region': {
    translationFile: 'taxesByRegion',
    coreRelationName: 'taxByRegion',
    getFields: getTaxByRegionFields,
    namespace: (entity, field, options = {}) => {
      const fieldState = valueOrDefault(options.fieldState, 'label')
      return `${entity.translationFile}:${entity.coreRelationName}.fields.${field}.${fieldState}`
    },
    fetcher: fetchTaxesByRegionByIds,
    getTitle: getTaxByRegionTitle,
    relationTranslationFiles: [],
    getDimension: () => null,
    getUnit: () => null,
    parser: (data, options) => parseTaxByRegion(data, options),
  },
  'taxes': {
    translationFile: 'taxes',
    coreRelationName: 'taxes',
    getFields: getTaxFields,
    namespace: (entity, field, options = {}) => {
      const fieldState = valueOrDefault(options.fieldState, 'label')
      return `${entity.translationFile}:${entity.coreRelationName}.fields.${field}.${fieldState}`
    },
    fetcher: fetchTaxesByIds,
    getTitle: getTaxTitle,
    relationTranslationFiles: [],
    getDimension: () => null,
    getUnit: () => null,
    parser: (data) => parseTax(data),
  },
  'tax-rules': {
    translationFile: 'taxRules',
    coreRelationName: 'taxRules',
    getFields: getTaxRuleFields,
    namespace: (entity, field, options = {}) => {
      const fieldState = valueOrDefault(options.fieldState, 'label')
      return `${entity.translationFile}:${entity.coreRelationName}.fields.${field}.${fieldState}`
    },
    fetcher: fetchTaxRulesByIds,
    getTitle: getTaxRuleTitle,
    relationTranslationFiles: [],
    getDimension: () => null,
    getUnit: () => null,
    parser: (data) => parseTaxRule(data),
  },
  'raws': {
    translationFile: 'raws',
    coreRelationName: 'raw',
    getFields: getRawFields,
    namespace: 'raws:raw.fields',
    fetcher: fetchRawsByIds,
    getTitle: getRawTitle,
    relationTranslationFiles: [],
    getDimension: (entity, entityField) => null,
    getUnit: (entity, entityField) => null,
    parser: (data) => parseRaw(data),
  },
  'materials': {
    translationFile: 'materials',
    coreRelationName: 'material',
    getFields: getMaterialFields,
    namespace: 'materials:material.fields',
    fetcher: fetchMaterialsByIds,
    getTitle: getMaterialTitle,
    relationTranslationFiles: [],
    getDimension: (entity, entityField) => null,
    getUnit: (entity, entityField) => null,
    parser: (data) => parseMaterial(data),
  },
  'categories': {
    translationFile: 'categories',
    coreRelationName: 'category',
    getFields: getCategoryFields,
    namespace: 'categories:category.fields',
    fetcher: fetchCategoriesByIds,
    getTitle: getCategoryTitle,
    relationTranslationFiles: [],
    getDimension: (entity, entityField) => null,
    getUnit: (entity, entityField) => null,
    parser: (data) => parseCategory(data),
  },
  'treatments': {
    translationFile: 'treatments',
    coreRelationName: 'treatment',
    getFields: getTreatmentFields,
    namespace: 'treatments:treatment.fields',
    fetcher: fetchTreatmentsByIds,
    getTitle: getTreatmentTitle,
    relationTranslationFiles: [],
    getDimension: (entity, entityField) => null,
    getUnit: (entity, entityField) => null,
    parser: (data) => parseTreatment(data),
  },
  'united-nations-numbers': {
    translationFile: 'unitedNationsNumbers',
    relationName: 'unNumber',
    getFields: getUnitedNationsNumberFields,
    namespace: 'unitedNationsNumbers:unitedNationsNumber.fields',
    fetcher: fetchUnitedNationsNumbersByIds,
    getTitle: getUnitedNationsNumberTitle,
    relationTranslationFiles: [],
    getDimension: (entity, entityField) => null,
    getUnit: (entity, entityField) => null,
    parser: parseUnitedNationsNumber,
  },
  // TODO (bzoretic): the following entity is a placeholder. Complete when plannedConsumptionsSlice exists.
  'planned-consumptions': {
    translationFile: 'plannedConsumptions',
    coreRelationName: 'plannedConsumption',
    getFields: () => ({}),
    namespace: (entity, field, options = {}) => {
      const fieldState = valueOrDefault(options.fieldState, 'label')
      return `${entity.translationFile}:${entity.coreRelationName}.fields.${field}.${fieldState}`
    },
    fetcher: async() => [],
    getTitle: () => '',
    relationTranslationFiles: [],
    getDimension: (entity, entityField) => null,
    getUnit: (entity, entityField) => null,
    parser: () => ({}),
  },
  'template-suppliers': {
    translationFile: 'templateSuppliers',
    coreRelationName: 'templateSupplier',
    getFields: getTemplateSupplierFields,
    namespace: 'templateSuppliers:templateSupplier.fields',
    fetcher: getTemplateSuppliersById,
    getTitle: getTemplateSupplierTitle,
    relationTranslationFiles: [],
    getDimension: (entity, entityField) => null,
    getUnit: (entity, entityField) => null,
    parser: parseTemplateSupplier,
  },
  'custom-fields': {
    translationFile: 'customFields',
    coreRelationName: 'customField',
    getFields: getCustomFieldFields,
    namespace: 'customFields:customField.fields',
    fetcher: async() => [],
    getTitle: () => '',
    relationTranslationFiles: [],
    getDimension: (entity, entityField) => null,
    getUnit: (entity, entityField) => null,
    parser: parseCustomField,
  },
  'inventory-conditions': {
    translationFile: 'inventoryConditions',
    coreRelationName: 'inventoryCondition',
    getFields: getInventoryConditionFields,
    namespace: 'inventoryConditions:inventoryCondition.fields',
    fetcher: fetchInventoryConditionsByIds,
    getTitle: getInventoryConditionTitle,
    relationTranslationFiles: [],
    getDimension: (entity, entityField) => null,
    getUnit: (entity, entityField) => null,
    parser: parseInventoryCondition,
  },
}

export function getFieldTranslationKey(
  entity: EntityType,
  fieldName: string,
  options: HandlerOptions = {},
): string | string[] {
  const field = removeIdOfFieldName(fieldName)

  if (typeof entity.namespace === 'function') {
    return entity.namespace(entity, field, options)
  }

  return [`${entity.namespace}.${field}.label`, `${entity.namespace}.${field}`]
}

export function getExtraTranslationKey(
  entity: EntityType,
  fieldName: string,
  extraName: string,
): string | string[] {
  const field = removeIdOfFieldName(fieldName)
  const fieldState = `extras.${extraName}.label`

  if (typeof entity.namespace === 'function') {
    return entity.namespace(entity, field, { fieldState })
  }

  return `${entity.namespace}.${field}.${fieldState}`
}

export function getEntityNamespaces(entityName: EntityName) {
  const entityObject = getEntityObject(entityName)

  if (!entityObject) return []

  const namespaces = [entityObject.translationFile]

  if (entityObject.relationTranslationFiles) {
    namespaces.push(...entityObject.relationTranslationFiles)
  }

  return namespaces
}

export function getNamespaces(entity: EntityType) {
  const namespaces = [entity.translationFile]

  if (entity.relationTranslationFiles) {
    namespaces.push(...entity.relationTranslationFiles)
  }

  return namespaces
}

export const getEntityObject = (entityName: EntityName) => {
  const entity = (
    entities[entityName] ??
    Object.values(entities).find((entity) => entity.legacyEntityName === entityName)
  )

  if (!entity) {
    DevLogs.error(`Entity \`${entityName}\` not found.`)

    return null
  }

  return entity
}

export const getEntityFields = (entityName: EntityName) => {
  return getEntityObject(entityName)?.getFields() ?? {}
}

export const getEntitySocketPath = (entityName: EntityName) => {
  return getEntityObject(entityName)?.socketPath ?? entityName
}
