import { createSlice } from '@reduxjs/toolkit'
import { AppDispatch } from 'store'

import { buildGetUrl, parse } from 'utils/api'
import { isJob, parseError, safeFetchJson } from 'utils/safeFetch'

import {
  CardGroup,
  CardGroupApi,
  CardGroupGetFields,
} from './types'

const dataSetName = 'cardGroupView'

const initialState = {
  dataSetName,
  fields: getFields(),
  cardGroupsCount: 0,
  cardGroups: [],
}

const cardGroupsSlice = createSlice({
  name: 'cardGroups',
  initialState,
  reducers: {
    setCardGroups: (state, action) => {
      state.cardGroups = action.payload.data
    },
    setCardGroupsCount: (state, action) => {
      state.cardGroupsCount = action.payload.count
    },
  },
})

export const { setCardGroups, setCardGroupsCount } = cardGroupsSlice.actions

export function getFields(): CardGroupGetFields {
  return {
    'id': { dataSetName, dbField: 'id', type: 'string' },
    'exist': { dataSetName, dbField: 'exist', type: 'boolean' },
    'createdDate': { dataSetName, dbField: 'created_date', type: 'timestamp' },
    'createdBy': { dataSetName, dbField: 'created_by', type: 'string' },
    'createdById': { dataSetName, dbField: 'created_by_id', type: 'string' },
    'modifiedDate': { dataSetName, dbField: 'modified_date', type: 'timestamp' },
    'modifiedBy': { dataSetName, dbField: 'modified_by', type: 'string' },
    'modifiedById': { dataSetName, dbField: 'modified_by_id', type: 'string' },
    'name': { dataSetName, dbField: 'name' },
    'cardCount': { dataSetName, dbField: 'card_count', type: 'integer' },
  }
}

export function getCardGroupTitle(cardGroup: CardGroup): string {
  return cardGroup.name
}

export async function fetchCardGroupsByIds(ids: string[], data?: Record<string, any>) {
  if (!ids?.length) return []

  const { isSuccess, result } = await safeFetchJson<CardGroupApi>(
    buildGetUrl(`/new_api/items/groups/${ids}`, data),
  )

  return isSuccess && !isJob(result) ?
    result.map((cardGroup) => parseCardGroup(cardGroup)) :
    []
}

export function fetchCardGroups(fetchData?: Record<string, any>) {
  return async function fetchCardGroupsThunk(dispatch: AppDispatch) {
    const data = await _fetchCardGroups(fetchData)
    dispatch(setCardGroups({ data }))
    return data
  }
}

export async function _fetchCardGroups(fetchData: Record<string, any>) {
  let cardGroups = []

  try {
    const { isSuccess, result } = await safeFetchJson<CardGroupApi>(
      buildGetUrl('/new_api/items/groups', fetchData),
    )
    if (isSuccess && !isJob(result)) {
      cardGroups = result.map((cardGroup) => parseCardGroup(cardGroup))
    }
  } catch (err) {
    console.error(err)
  }

  return cardGroups
}

export function fetchCardGroupsCount(fetchData?: Record<string, any>) {
  return async function fetchCardGroupsCountThunk(dispatch: AppDispatch) {
    const count = await _fetchCardGroupsCount(fetchData)
    dispatch(setCardGroupsCount({ count }))
    return count
  }
}

export async function _fetchCardGroupsCount(fetchData?: Record<string, any>) {
  let count = 0

  try {
    const { isSuccess, result } = await safeFetchJson<{count: string}>(
      buildGetUrl('/new_api/items/groups/count', fetchData),
    )
    if (isSuccess && !isJob(result)) {
      count = +result[0].count || 0
    }
  } catch (err) {
    console.error(err)
  }

  return count
}

export async function createCardGroup(cardIds: string[]) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ cardGroup: { cardIds } }),
  }

  try {
    return await safeFetchJson<CardGroupApi>('/new_api/items/groups', requestOptions)
  } catch (error) {
    console.error(error)
    return parseError(error)
  }
}

export async function deleteCardGroups(ids: CardGroup['id'][]) {
  const requestOptions = {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
  }

  try {
    return await safeFetchJson<CardGroupApi>(`/new_api/items/groups/${ids}`, requestOptions)
  } catch (error) {
    console.error(error)
    return parseError(error)
  }
}

export function parseCardGroup(cardGroup: CardGroupApi): CardGroup {
  const options = {
    defaultData: getDefaultCardGroup(),
    fields: initialState.fields,
    dataSetName: dataSetName,
  }

  return parse(cardGroup, options)
}

function getDefaultCardGroup(): CardGroup {
  return parse({}, { fields: initialState.fields })
}

export default cardGroupsSlice.reducer
