import {
  CREATE_INVENTORIES,
  CLEAR_CREATE_SUCCESS,
  WASTE_INVENTORIES,
  CLEAR_WASTE_SUCCESS,
  DELETE_INVENTORIES,
  CLEAR_DELETE_SUCCESS,
} from './types'

const initState = {
  isCreateSuccess: null,
  isWasteSuccess: null,
  isDeleteSuccess: null,
  isOrderSuccess: null,
}

export default function inventoriesSuccessReducer(state = initState, action) {
  const { payload } = action

  switch (action.type) {
  case CREATE_INVENTORIES: {
    return {
      ...state,
      isCreateSuccess: payload?.length > 0,
    }
  }
  case CLEAR_CREATE_SUCCESS: {
    return {
      ...state,
      isCreateSuccess: false,
    }
  }
  case WASTE_INVENTORIES: {
    return {
      ...state,
      isWasteSuccess: payload,
    }
  }
  case CLEAR_WASTE_SUCCESS: {
    return {
      ...state,
      isWasteSuccess: false,
    }
  }
  case DELETE_INVENTORIES: {
    return {
      ...state,
      isDeleteSuccess: payload,
    }
  }
  case CLEAR_DELETE_SUCCESS: {
    return {
      ...state,
      isDeleteSuccess: false,
    }
  }
  default:
    return state
  }
}

export function clearCreateSuccess(dispatch) {
  dispatch({ type: CLEAR_CREATE_SUCCESS })
}

export function clearWasteSuccess(dispatch) {
  dispatch({ type: CLEAR_WASTE_SUCCESS })
}

export function clearDeleteSuccess(dispatch) {
  dispatch({ type: CLEAR_DELETE_SUCCESS })
}
