
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { SmartToastRef } from 'components/alix-front/smart-toast/types'

interface ToastState {
  ref: SmartToastRef | null;
}

const initialState: ToastState = {
  ref: null,
}

const toastSlice = createSlice({
  name: 'toast',
  initialState,
  reducers: {
    setToastRef: (state, action: PayloadAction<{ ref: SmartToastRef | null }>) => {
      state.ref = action.payload.ref
    },
  },
})

export const { setToastRef } = toastSlice.actions
export default toastSlice.reducer

