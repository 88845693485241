import {
  UPDATE_INVENTORIES,
  CLEAR_UPDATE_ERROR,
  SET_SCAN_ERROR,
  CLEAR_SCAN_ERROR,
} from './types'

const initState = {
  updateError: null,
  scanError: null,
}

export default function inventoriesErrorReducer(state = initState, action) {
  const { error } = action

  switch (action.type) {
  case UPDATE_INVENTORIES: {
    return {
      ...state,
      updateError: error,
    }
  }
  case CLEAR_UPDATE_ERROR: {
    return {
      ...state,
      updateError: null,
    }
  }
  case SET_SCAN_ERROR: {
    return {
      ...state,
      scanError: error,
    }
  }
  case CLEAR_SCAN_ERROR: {
    return {
      ...state,
      scanError: null,
    }
  }
  default:
    return state
  }
}

export function clearUpdateError(dispatch) {
  dispatch({ type: CLEAR_UPDATE_ERROR })
}

export function setScanError(name, tag) {
  return function setScanErrorThunk(dispatch) {
    dispatch({ type: SET_SCAN_ERROR, error: { error: { name }, tag } })
  }
}

export function clearScanError(dispatch) {
  dispatch({ type: CLEAR_SCAN_ERROR })
}
