import {
  CREATE_PRICE_LIST,
  CLEAR_CREATE_SUCCESS,
  UPDATE_PRICE_LIST,
  CLEAR_UPDATE_SUCCESS,
} from './types'

const initState = {
  isCreateSuccess: null,
  isUpdateSuccess: null,
}

export default function priceListsSuccessReducer(state = initState, action) {
  const { payload } = action

  switch (action.type) {
  case CREATE_PRICE_LIST: {
    return {
      ...state,
      isCreateSuccess: payload && payload.id ? true : false,
    }
  }
  case CLEAR_CREATE_SUCCESS: {
    return {
      ...state,
      isCreateSuccess: false,
    }
  }
  case UPDATE_PRICE_LIST: {
    return {
      ...state,
      isUpdateSuccess: payload && payload.id ? true : false,
    }
  }
  case CLEAR_UPDATE_SUCCESS: {
    return {
      ...state,
      isUpdateSuccess: false,
    }
  }
  default:
    return state
  }
}

export function clearCreateSuccess(dispatch) {
  dispatch({ type: CLEAR_CREATE_SUCCESS })
}

export function clearUpdateSuccess(dispatch) {
  dispatch({ type: CLEAR_UPDATE_SUCCESS })
}
