export class DevLogs {
  static _preCheck() {
    return process.env.NODE_ENV === 'development'
  }

  /**
   * @param {any[]} data
   */
  static log(...data) {
    this._handleLog('log', ...data)
  }

  /**
   * @param {any[]} data
   */
  static error(...data) {
    this._handleLog('error', ...data)
  }

  /**
   * @param {any[]} data
   */
  static warn(...data) {
    this._handleLog('warn', ...data)
  }

  static _styles = {
    log: 'color: black; background-color: green;padding: 2px',
    error: 'color: black; background-color: red;padding: 2px',
    warn: 'color: black; background-color: yellow;padding: 2px',
  }

  static _handleLog(level, ...data) {
    if (!this._preCheck()) return

    console.info(
      `%cDevUtils.${level}():`,
      this._styles[level],
      ...data,
    )
  }

  static assert(condition, ...messages) {
    if (condition) return

    this.error(...messages)
  }
}
