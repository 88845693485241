import { ApiToSlice, BaseEntityApi, GetFields, Modify } from 'types/slices'

export const transactionTypes = ['sales_shipping', 'sales_invoicing'] as const
export const referenceEntities = ['shipping', 'invoice'] as const
export const reversalReasons = ['update', 'delete', 'void', 're_draft', 'restore'] as const
export const syncStatuses = ['not_planned', 'to_sync', 'pending', 'success', 'failed'] as const
export type TransactionApi = Modify<{
  company_id: string
  name: string
  notes: string
  transaction_date: Date
  transaction_type: typeof transactionTypes[number]
  posting_date: Date
  generated_number: string
  reference_number: string
  reference_entity: typeof referenceEntities[number]
  reference_id: string
  is_reversal: boolean
  reversal_reason: typeof reversalReasons[number]
  linked_transaction_id: string
  currency_id: string
  exchange_rate: number
  sync_status: typeof syncStatuses[number]
  sync_date: Date
  external_transaction_id: string
  currency_code: string
  currency_symbol: string
  currency_external_id: string
  is_reversed: boolean
  debit_total: number
  credit_total: number
  lineItems: TransactionItemApi[]
}, BaseEntityApi>

export type Transaction = ApiToSlice<Modify<TransactionApi, { lineItems: TransactionItem[] }>>

export type TransactionGetFields = GetFields<TransactionApi, Transaction>

export type TransactionItemApi = Modify<{
  transaction_id: TransactionApi['id']
  account_id: string
  debit: number
  credit: number
  account_code: string
  account_name: string
  account_external_id: string
}, BaseEntityApi>

export type TransactionItem = ApiToSlice<TransactionItemApi>

export type TransactionItemGetFields = GetFields<TransactionItemApi, TransactionItem>
