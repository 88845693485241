import { createSlice } from '@reduxjs/toolkit'
import { HubAppDispatch } from 'store'

import { ProcessResult } from 'components/alix-front/smart-toast/process/useProcessToast'

import { buildGetUrl, parse } from 'utils/api'
import { valueOrDefault } from 'utils/defaultValueHelper'
import { EntityType } from 'utils/entities'
import { isJob, parseError, safeFetchJson } from 'utils/safeFetch'

import { Client, ClientApi, ClientGetFields, ClientType } from 'reducers/clients/types'

// alix-hub dataSetName
const dataSetName = 'client'

const initialState = {
  dataSetName,
  fields: getClientFields(),
  count: 0,
  data: [],
}

const clientSlice = createSlice({
  name: 'clients',
  initialState,
  reducers: {
    setData: (state, action) => {
      state.data = action.payload.data
    },
    setCount: (state, action) => {
      state.count = action.payload.count
    },
    addTestEnvironment: (state, action) => {
      state.count++
      state.data.push(action.payload.client)
      state.data.find((client) => client.id === action.payload.parentClientId).testId = action.payload.client.id
    },
    removeTestEnvironment: (state, action) => {
      state.count--
      state.data = state.data.filter((client) => client.id !== action.payload.clientId)
      state.data.find((client) => client.testId === action.payload.clientId).testId = null
    },
    removeTrialEnvironment: (state, action) => {
      state.count--
      // First, delete the transitive related clients of the initial client
      const relatedTestClient = state.data.find((client) => client.id === action.payload.testId)
      const relatedSandboxClient = state.data.find((client) => client.id === action.payload.sandboxId)
      if (relatedTestClient) {
        state.data = state.data.filter((client) => client.id !== relatedTestClient.testId)
        state.data = state.data.filter((client) => client.id !== relatedTestClient.sandboxId)
      }

      if (relatedSandboxClient) {
        state.data = state.data.filter((client) => client.id !== relatedSandboxClient.testId)
        state.data = state.data.filter((client) => client.id !== relatedSandboxClient.sandboxId)
      }
      // Then, delete the initial client and its directly related clients
      state.data = state.data.filter((client) => client.id !== action.payload.clientId)
      state.data = state.data.filter((client) => client.id !== action.payload.testId)
      state.data = state.data.filter((client) => client.id !== action.payload.sandboxId)
    },
  },
})

export const {
  setData: setClients,
  setCount: setClientsCount,
  addTestEnvironment,
  removeTestEnvironment,
  removeTrialEnvironment,
} = clientSlice.actions

export function getClientFields(): ClientGetFields {
  return {
    'id': { dataSetName, dbField: 'id', type: 'string' },
    'title': { dataSetName, dbField: 'title', type: 'string' },
    'isactive': { dataSetName, dbField: 'isactive', type: 'boolean' },
    'hostname': { dataSetName, dbField: 'hostname', type: 'string' },
    'alixAppVersionId': { dataSetName, dbField: 'alix_app_version_id', type: 'integer' },
    'hubAppVersionId': { dataSetName, dbField: 'hub_app_version_id', type: 'integer' },
    'clientType': { dataSetName, dbField: 'client_type', type: 'string' },
    'alixDatabaseId': { dataSetName, dbField: 'alix_database_id', type: 'integer' },
    'exist': { dataSetName, dbField: 'exist', type: 'boolean' },

    'type': {
      dataSetName,
      dbField: 'type',
      type: 'status',
      dictionaryKey: 'client',
      values: ClientType,
      translationPath: 'clients:client.fields.type.values',
      dictionaryType: 'type',
    },

    'tenantCode': { dataSetName, dbField: 'tenant_code', type: 'string' },
    'trialStartDate': { dataSetName, dbField: 'trial_start_date', type: 'string' },
    'trialDuration': { dataSetName, dbField: 'trial_duration', type: 'integer' },
    'isOfflinePayment': { dataSetName, dbField: 'is_offline_payment', type: 'boolean' },
    'zohoSubscriptionId': { dataSetName, dbField: 'zoho_subscription_id', type: 'string' },
    'customIdentityProviderId': { dataSetName, dbField: 'custom_identity_provider_id', type: 'string' },
    'sandboxId': { dataSetName, dbField: 'sandbox_id', type: 'string' },
    'testId': { dataSetName, dbField: 'test_id', type: 'string' },
    'appversionId': { dataSetName, dbField: 'appversion_id', type: 'integer' },
    'appversionExist': { dataSetName, dbField: 'appversion_exist', type: 'boolean' },
    'appversionCreatedDate': { dataSetName, dbField: 'appversion_created_date', type: 'string' },
    'appversionModifiedDate': { dataSetName, dbField: 'appversion_modified_date', type: 'string' },
    'appversionScope': { dataSetName, dbField: 'appversion_scope', type: 'string' },
    'appversionName': { dataSetName, dbField: 'appversion_name', type: 'string' },
    'appversionDescription': { dataSetName, dbField: 'appversion_description', type: 'string' },
    'appversionDefaultAlixDatabaseId': { dataSetName, dbField: 'appversion_default_alix_database_id', type: 'integer' },
    'databaseStatus': { dataSetName, dbField: 'database_status', type: 'string' },
    'subscriptionEndDate': { dataSetName, dbField: 'subscription_end_date', type: 'string' },
  }
}

export function getClientTitle(client: Client): string {
  return client.title
}

export function fetchClients(fetchData?: Record<string, any>) {
  return async function fetchClientsThunk(dispatch: HubAppDispatch) {
    const data = await _fetchClients(fetchData)
    dispatch(setClients({ data }))
    return data
  }
}

export async function _fetchClients(fetchData: Record<string, any> = {}) {
  let clients = []

  try {
    const { isSuccess, result } = await safeFetchJson<ClientApi, true>(
      buildGetUrl('/api/admin/clients', fetchData),
    )
    if (isSuccess && !isJob(result)) {
      clients = result.map((client) => parseClient(client))
    }
  } catch (err) {
    console.error(err)
  }

  return clients
}

export function parseClient(client: ClientApi): Client {
  const options = {
    defaultData: getDefaultClient(),
    fields: initialState.fields,
    dataSetName: dataSetName,
  }

  return parse(client, options)
}

function getDefaultClient(): Client {
  return parse({}, { fields: initialState.fields })
}

export async function createTestEnvironment(clientId: string): Promise<ProcessResult> {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ clientId }),
  }
  try {
    const result = await safeFetchJson<ClientApi, false>('/api/admin/test-environment', requestOptions)
    const client = result.isSuccess && !isJob<ClientApi, false>(result.result) ? parseClient(result.result) : null
    return { ...result, result: { ...result.result, client } }
  } catch (error) {
    return parseError(error)
  }
}

export async function refreshTestEnvironment(clientId: string): Promise<ProcessResult> {
  try {
    return await safeFetchJson(`/api/admin/test-environment/refresh/${clientId}`, { method: 'POST' })
  } catch (error) {
    return parseError(error)
  }
}

export async function deleteTrial(clientId: string): Promise<ProcessResult> {
  try {
    return await safeFetchJson(`/api/admin/trial-environment/${clientId}`, { method: 'DELETE' })
  } catch (error) {
    return parseError(error)
  }
}

export async function deleteTestEnvironment(clientId: string) {
  try {
    return await safeFetchJson(`/api/admin/test-environment/${clientId}`, { method: 'DELETE' })
  } catch (error) {
    return parseError(error)
  }
}

export async function pauseClientEnvironment(clientId: string) {
  try {
    return await safeFetchJson(`/api/admin/pause-client/${clientId}`, { method: 'POST' })
  } catch (error) {
    return parseError(error)
  }
}

export async function promoteClient(clientId: string): Promise<ProcessResult> {
  try {
    return await safeFetchJson(`/api/admin/promote-trial/${clientId}`, { method: 'POST' })
  } catch (error) {
    return parseError(error)
  }
}

export const ClientEntity: EntityType<Client> = {
  translationFile: 'clients',
  coreRelationName: 'client',
  getFields: getClientFields,
  // (odeschenes) : Entity only used in the admin panel, no need for a fetcher for the events
  fetcher: async() => [],
  getTitle: getClientTitle,
  namespace: (entity, field, options = {}) => {
    const fieldState = valueOrDefault(options.fieldState, 'label')

    return `${entity.translationFile}:${entity.coreRelationName}.fields.${field}.${fieldState}`
  },
  relationTranslationFiles: [],
  getDimension: (entity, entityField) => null,
  getUnit: (entity, entityField) => null,
  parser: parseClient,
}

export default clientSlice.reducer
