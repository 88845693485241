import { AppDispatch } from 'store'
import { ApiToSlice, BaseEntityApi, GetFields, Modify } from 'types/slices'
import { CamelCasePrefixedRecord, PrefixedRecord } from 'types/utils'

import { buildGetUrl, parse } from 'utils/api'
import { safeFetch, safeFetchJson, isJob, parseError } from 'utils/safeFetch'
import { convertFromBase } from 'utils/unitConverter'

import { Inventory, inventoryStatuses, fetchInventoryByIds } from 'reducers/inventories/inventoriesSlice'
import { ItemApi } from 'reducers/items/apiType'
import {
  getFields as getItemFields,
  parseItem,
} from 'reducers/items/itemsSlice'
import {
  GetSalesOrderFields,
  getFields as getSalesOrderFields,
  parseSalesOrder,
} from 'reducers/sales-orders/shared'
import { SalesOrderApi } from 'reducers/sales-orders/types'
import { ShipmentApi } from 'reducers/shipments/types'
import { formatFields } from 'reducers/utils/common'

import {
  GET_PLANNED_LEDGERS_COUNT,
  GET_PLANNED_LEDGERS,
  CLEAR_PLANNED_LEDGERS,
} from './types'

export const plannedLedgerItemApiPrefix = 'template'
export const plannedLedgerItemFieldPrefix = 'template'
export const plannedLedgerSalesOrderApiPrefix = 'sales_order'
export const plannedLedgerSalesOrderFieldPrefix = 'salesOrder'

const dataSetName = 'plannedLedger'
const itemDataSetName = 'templateView'
const itemTrimAlias = 'template'
const salesOrderDataSetName = 'salesOrderView'
const salesOrderDataSetAlias = 'sales_order'
const salesOrderTrimAlias = 'salesOrder'

const plannedLedgerStatuses = ['active', 'resolved', 'canceled'] as const
type PlannedLedgerStatus = typeof plannedLedgerStatuses[number]

export type MapData = {
  defaultUnits?: {
    qty: string
    weight: string
    length: string
    surface: string
    volume: string
  }
  inventoryDict?: Record<string, Inventory>
  shipments?: ShipmentApi[]
  vendorDetails?: Record<string, any>
  customerDetails?: Record<string, any>
  preferredCompany?: Record<string, any>
  matchingVendor?: Record<string, any>
}

export type PlannedLedgerApi = Modify<{
  dimension_to_display: string
  measure: number
  inventory_id: string
  purchase_order_item_id: string
  planned_measure: number
  resolution_ledger_id: string
  sales_order_item_id: string
  template_id: string
  measure_unit: string
  name: string
  planned_type: (
    'sales' | 'consumption' | 'purchase' | 'loose_demand' | 'loose_supply' | 'on_hand' |'production' | 'reception'
  )
  status: PlannedLedgerStatus
  resolution_date: Date
  resolution_planned_date: Date
  shipment_id: string
  shipment_number: string
  shipment_formated_number: string
  shipment_line_item_id: string
  shipment_line_item_planned_measure: number
  template_tolerance_over_factor: number
  inventory_tag: Inventory['tag']
  inventory_status: Inventory['status']
  inventory_current_measure: Inventory['currentMeasure']
  inventory_invoice_title: Inventory['invoice']
  purchaseorderitemview_purchase_order_id: string
  sales_order_customer_display_name: string
}, BaseEntityApi>

export type FullPlannedLedgerApi = PlannedLedgerApi &
  PrefixedRecord<ItemApi, `${typeof plannedLedgerItemApiPrefix}_`> &
  PrefixedRecord<SalesOrderApi, `${typeof plannedLedgerSalesOrderApiPrefix}_`>

type Exceptions = {
  dimension_to_display: 'dimension'
  inventory_tag: 'source'
  inventory_invoice_title: 'po'
  shipment_number: 'shipment'
  shipment_formated_number: 'shipmentFormattedNumber'
  template_tolerance_over_factor: 'toleranceOverFactor'
  purchaseorderitemview_purchase_order_id: 'purchaseOrderId'
}

type PlannedLedgerFrontEndFields = {
  inventory: Inventory
  converted_planned_measure: number
  available: number
  unit: string
}

export type PlannedLedger = ApiToSlice<Modify<PlannedLedgerApi, PlannedLedgerFrontEndFields>, Exceptions>
type FullPlannedLedger = ApiToSlice<Modify<FullPlannedLedgerApi, PlannedLedgerFrontEndFields>, Exceptions>

type AcceptedDataSetNames = 'inventory' | 'shipment' | 'purchaseorderitemview' | 'sales_order' |
  'sales_order_customer' | 'template'

const plannedLedgerFields: GetFields<PlannedLedgerApi, PlannedLedger, null, AcceptedDataSetNames> = {
  'dimension': { dataSetName, dbField: 'dimension_to_display' },
  'measure': { dataSetName, dbField: 'measure', type: 'float' },
  'createdById': { dataSetName, dbField: 'created_by_id', type: 'id' },
  'id': { dataSetName, dbField: 'id', type: 'id' },
  'inventoryId': { dataSetName, dbField: 'inventory_id', type: 'id', relationEntity: 'inventories' },
  'modifiedById': { dataSetName, dbField: 'modified_by_id', type: 'id' },
  'purchaseOrderItemId': {
    dataSetName,
    dbField: 'purchase_order_item_id',
    type: 'id',
    relationEntity: 'purchase-order-items',
  },
  'plannedMeasure': { dataSetName, dbField: 'planned_measure', type: 'measure' },
  'resolutionLedgerId': { dataSetName, dbField: 'resolution_ledger_id', type: 'id' },
  'salesOrderItemId': {
    dataSetName,
    dbField: 'sales_order_item_id',
    type: 'id',
    relationEntity: 'sales-order-items',
  },
  'templateId': { dataSetName, dbField: 'template_id', type: 'id' },
  'createdBy': { dataSetName, dbField: 'created_by', type: 'string' },
  'measureUnit': { dataSetName, dbField: 'measure_unit', type: 'string' },
  'modifiedBy': { dataSetName, dbField: 'modified_by', type: 'string' },
  'name': { dataSetName, dbField: 'name', type: 'string' },
  'plannedType': { dataSetName, dbField: 'planned_type', type: 'string' },
  'status': {
    dataSetName,
    dbField: 'status',
    type: 'status',
    dictionaryKey: 'plannedLedger',
    dictionaryType: 'reservationStatus',
    values: [...plannedLedgerStatuses],
  },
  'createdDate': { dataSetName, dbField: 'created_date', type: 'date' },
  'modifiedDate': { dataSetName, dbField: 'modified_date', type: 'date' },
  'resolutionDate': { dataSetName, dbField: 'resolution_date', type: 'date', isTimezoned: false },
  'resolutionPlannedDate': { dataSetName, dbField: 'resolution_planned_date', type: 'date', isTimezoned: false },

  'source': { dataSetName: 'inventory', dbField: 'tag' },
  'inventoryStatus': {
    dataSetName: 'inventory',
    dbField: 'status',
    type: 'status',
    dictionaryKey: 'inventory',
    values: [...inventoryStatuses],
  },
  'inventoryCurrentMeasure': { dataSetName: 'inventory', dbField: 'current_measure', type: 'measure' },
  'po': { dataSetName: 'inventory', dbField: 'invoice_title' },

  'shipmentId': { dataSetName, dbField: 'shipment_id', relationEntity: 'shipments' },
  'shipment': { dataSetName, dbField: 'shipment_number' },
  'shipmentFormattedNumber': { dataSetName: 'shipment', dbField: 'formated_number', dataSetAlias: 'shipment' },
  'shipmentLineItemId': {
    dataSetName,
    dbField: 'shipment_line_item_id',
    type: 'id',
    relationEntity: 'shipment-line-items',
  },
  'shipmentLineItemPlannedMeasure': { dataSetName, dbField: 'shipment_line_item_planned_measure', type: 'float' },
  'toleranceOverFactor': { dataSetName, dbField: 'template_tolerance_over_factor', type: 'float' },

  'purchaseOrderId': {
    dataSetName: 'purchaseOrderItemView',
    dbField: 'purchase_order_id',
    type: 'id',
    relationEntity: 'purchase-orders',
  },

  // Parsed fields
  'inventory': { parseWithParsedData: parseInventory },
  'convertedPlannedMeasure': { parseWithParsedData: convertMeasure },
  'available': { parseWithParsedData: parseAvailable, type: 'measure' },
  'unit': { parseWithParsedData: getUnit },

  'salesOrderCustomerDisplayName': {
    dataSetName: 'contact',
    dbField: 'display_name',
    dataSetAlias: 'sales_order_customer',
  },
}

export function getFields(): GetFields<FullPlannedLedgerApi, FullPlannedLedger, null, AcceptedDataSetNames> {
  const itemFields = formatFields(
    getItemFields(),
    plannedLedgerItemFieldPrefix,
    plannedLedgerItemApiPrefix,
    {
      dataSetName: itemDataSetName,
      trimAlias: itemTrimAlias,
    },
    { isCamelCase: true },
  ) as any // TODO (lleduc): Replace once Item is typed

  // ! (bzoretic) the following fields are filled only when joinToSalesOrder is set to true during fetch
  const salesOrderFields = formatFields(
    getSalesOrderFields(),
    plannedLedgerSalesOrderFieldPrefix,
    plannedLedgerSalesOrderApiPrefix,
    {
      dataSetName: salesOrderDataSetName,
      dataSetAlias: salesOrderDataSetAlias,
      trimAlias: salesOrderTrimAlias,
    },
    { isCamelCase: true },
  ) as CamelCasePrefixedRecord<GetSalesOrderFields, `${typeof plannedLedgerSalesOrderFieldPrefix}`>

  return {
    ...itemFields,
    ...salesOrderFields,
    ...plannedLedgerFields,
  }
}

const fields = getFields()
const itemFields = getItemFields()
const salesOrderFields = getSalesOrderFields()
const initialState = {
  dataSetName,
  fields,
  itemFields,
  salesOrderFields,
  plannedLedgersCount: 0,
  plannedLedgers: [],
}

export default function plannedLedgersReducer(state = initialState, action: any) {
  const { payload } = action
  switch (action.type) {
  case GET_PLANNED_LEDGERS_COUNT: {
    return {
      ...state,
      plannedLedgersCount: payload,
    }
  }
  case GET_PLANNED_LEDGERS: {
    return {
      ...state,
      plannedLedgers: payload,
    }
  }
  case CLEAR_PLANNED_LEDGERS: {
    return {
      ...state,
      plannedLedgersCount: 0,
      plannedLedgers: [],
    }
  }
  default: {
    return state
  }
  }
}

export function fetchPlannedLedgersCount(data = {}) {
  return async function fetchPlannedLedgersCountThunk(dispatch: AppDispatch) {
    const count = await _fetchPlannedLedgersCount(data)
    dispatch({ type: GET_PLANNED_LEDGERS_COUNT, payload: count })
    return count
  }
}

export async function _fetchPlannedLedgersCount(data = {}) {
  let count = 0

  try {
    const result = await (await safeFetch(buildGetUrl('/new_api/planned-ledgers/count', data))).json()
    if (result.isSuccess) {
      count = +result.result[0].count || 0
    }
  } catch (err) {
    console.error(err)
  }

  return count
}

export function fetchPlannedLedgers(data = {}, mapData: MapData) {
  return async function fetchSalesOrdersThunk(dispatch: AppDispatch) {
    const plannedLedgers = await _fetchPlannedLedgers(data, mapData)
    dispatch({ type: GET_PLANNED_LEDGERS, payload: plannedLedgers })
    return plannedLedgers
  }
}

export async function fetchPlannedLedgerByIds(ids: string[], mapData?: MapData) {
  let plannedLedgers = []

  if (ids?.length > 0) {
    try {
      const {
        isSuccess,
        result,
      } = await safeFetchJson<FullPlannedLedgerApi>(buildGetUrl(`/new_api/planned-ledgers/${ids}`))
      if (isSuccess && !isJob(result)) {
        const inventoryDict = await _getInventoryDict(result, mapData)
        plannedLedgers = result.map((plannedLedger) => parsePlannedLedger(plannedLedger, { ...mapData, inventoryDict }))
      }
    } catch (err) {
      console.error(err)
    }
  }

  return plannedLedgers
}

export async function _fetchPlannedLedgers(data = {}, mapData: MapData = {}, skipInventoryDict = false) {
  let plannedLedgers: FullPlannedLedger[] = []
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  }
  try {
    const {
      isSuccess,
      result,
    } = await safeFetchJson<FullPlannedLedgerApi>(`/new_api/planned-ledgers/fetch`, requestOptions)
    if (isSuccess && !isJob(result)) {
      const inventoryDict = !skipInventoryDict ? await _getInventoryDict(result, mapData) : {}
      plannedLedgers = result.map((plannedLedger) => parsePlannedLedger(plannedLedger, { ...mapData, inventoryDict }))
    }
  } catch (err) {
    console.error(err)
  }

  return plannedLedgers
}

async function _getInventoryDict(plannedLedgers: any[], mapData) {
  const inventoryIds = plannedLedgers
    .filter((plannedLedger: any) => plannedLedger.inventory_id)
    .map((plannedLedger: any) => plannedLedger.inventory_id)

  const inventories = inventoryIds.length ?
    await fetchInventoryByIds(inventoryIds, mapData, undefined, 'POST') :
    []
  return inventories.reduce((acc, inventory) => {
    acc[inventory.id] = inventory
    return acc
  }, {})
}

export async function updatePlannedLedgers(plannedLedgers: FullPlannedLedger[]) {
  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ plannedLedgers }),
  }

  try {
    return (await safeFetch(`/new_api/planned-ledgers/batch`, requestOptions)).json()
  } catch (error) {
    console.error(error)
    return parseError(error)
  }
}

export async function deletePlannedLedgers(ids: FullPlannedLedger['id'][]) {
  const requestOptions = {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
  }

  try {
    return (await safeFetch(`/new_api/planned-ledgers/${ids}`, requestOptions)).json()
  } catch (error) {
    console.error(error)
  }
}

export function clearPlannedLedgers(dispatch: AppDispatch) {
  dispatch({ type: CLEAR_PLANNED_LEDGERS })
}

export function parsePlannedLedger(plannedLedger: FullPlannedLedgerApi, mapData: MapData): FullPlannedLedger {
  mapData.shipments?.forEach((shipment: any) => {
    if (shipment.shipmentlineitem_id == plannedLedger.shipment_line_item_id) {
      plannedLedger.shipment_number = shipment.formated_number
      plannedLedger.shipment_id = shipment.id
      plannedLedger.shipment_line_item_planned_measure = shipment.shipmentlineitem_planned_measure
    }
  })

  const options = {
    fields: initialState.fields,
    dataSetName,
    inventoryDict: mapData.inventoryDict,
    defaultUnits: mapData.defaultUnits,

    vendorDetails: mapData.vendorDetails || {},
    customerDetails: mapData.customerDetails || {},
    preferredCompany: mapData.preferredCompany || {},
    matchingVendor: mapData.matchingVendor || {},
  }

  const parsedItem = parseItem({ ...plannedLedger }, {
    ...options,
    apiPrefix: `${plannedLedgerItemApiPrefix}_`,
    outPrefix: plannedLedgerItemFieldPrefix,
    skipOutPrefixCamelCaseKeys: false,
  }, initialState.itemFields)

  const parsedSalesOrder = parseSalesOrder({ ...plannedLedger } as any, {
    ...options,
    apiPrefix: `${plannedLedgerSalesOrderApiPrefix}_`,
    outPrefix: plannedLedgerSalesOrderFieldPrefix,
    skipOutPrefixCamelCaseKeys: false,
  }, initialState.salesOrderFields)

  const parsedPlannedLedger = parse({ ...plannedLedger }, {
    ...options,
    defaultData: getDefaultPlannedLedger(options),
    fields: plannedLedgerFields,
  })

  return { ...parsedPlannedLedger, ...parsedItem, ...parsedSalesOrder }
}

function getDefaultPlannedLedger(options): PlannedLedger {
  return parse({}, options)
}

function parseInventory(plannedLedger: PlannedLedger, options: MapData) {
  return options?.inventoryDict?.[plannedLedger.inventoryId] || {} as Inventory
}

function parseAvailable(plannedLedger: PlannedLedger, options: MapData) {
  const currentMeasure = +plannedLedger.inventoryCurrentMeasure || 0
  const inventory = options?.inventoryDict?.[plannedLedger.inventoryId]
  const activePlannedInventorySum = +inventory?.reservedMeasure || 0
  return currentMeasure - activePlannedInventorySum
}

function getUnit(plannedLedger: PlannedLedger, options: MapData): string {
  const dimension = plannedLedger.dimension
  return plannedLedger.measureUnit || options?.defaultUnits?.[dimension]
}

function convertMeasure(plannedLedger: PlannedLedger, options: MapData) {
  const dimension = plannedLedger.dimension
  const measure = +plannedLedger.plannedMeasure || 0
  const unit = plannedLedger.unit ?? getUnit(plannedLedger, options)
  return +convertFromBase(dimension?.toString(), measure, unit?.toString(), true)
}

export function getPlannedLedgerTitle(plannedLedger: PlannedLedger) {
  return plannedLedger.source
}
