export const GET_SHIPMENTS_COUNT = 'GET_SHIPMENTS_COUNT'
export const GET_SHIPMENTS = 'GET_SHIPMENTS'
export const CLEAR_SHIPMENTS = 'CLEAR_SHIPMENTS'
export const CREATE_SHIPMENT = 'CREATE_SHIPMENTS'
export const DELETE_SHIPMENT = 'DELETE_SHIPMENTS'

export const CLEAR_CREATE_ERROR = 'CLEAR_SHIPMENT_CREATE_ERROR'
export const CLEAR_CREATE_SUCCESS = 'CLEAR_SHIPMENT_CREATE_SUCCESS'
export const CLEAR_DELETE_ERROR = 'CLEAR_SHIPMENT_DELETE_ERROR'
export const CLEAR_DELETE_SUCCESS = 'CLEAR_SHIPMENT_DELETE_SUCCESS'

export const GET_SHIPMENT_ITEMS_COUNT = 'GET_SHIPMENT_ITEMS_COUNT'
export const GET_SHIPMENT_ITEMS = 'GET_SHIPMENT_ITEMS'
export const CLEAR_SHIPMENT_ITEMS = 'CLEAR_SHIPMENT_ITEMS'

export const UPDATE_SHIPMENT = 'UPDATE_SHIPMENT'
export const CLEAR_UPDATE_ERROR = 'CLEAR_SHIPMENT_UPDATE_ERROR'
export const CLEAR_UPDATE_SUCCESS = 'CLEAR_SHIPMENT_UPDATE_SUCCESS'
