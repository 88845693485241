import { ApiToSlice, GetFields } from 'types/slices'

export const ClientTypeEnum = {
  SANDBOX: 'sandbox',
  TEST: 'test',
  PRODUCTION: 'production',
  DEMO: 'demo',
} as const

export const ClientType = Object.values(ClientTypeEnum)

export type ClientApi = {
  id: string;
  title: string;
  isactive: boolean;
  hostname: string;
  alix_app_version_id: number;
  hub_app_version_id: number;
  client_type: string;
  alix_database_id: number;
  exist: boolean;
  type: typeof ClientType[number];
  tenant_code: string;
  trial_start_date: string | null;
  trial_duration: number;
  is_offline_payment: boolean;
  zoho_subscription_id: string;
  custom_identity_provider_id: string | null;
  sandbox_id: string | null;
  test_id: string | null;
  appversion_id: number;
  appversion_exist: boolean;
  appversion_created_date: string;
  appversion_modified_date: string;
  appversion_scope: string;
  appversion_name: string;
  appversion_description: string | null;
  appversion_default_alix_database_id: number | null;
  database_status: string;
  subscription_end_date: string;
}

export type Client = ApiToSlice<ClientApi>

export type ClientGetFields = GetFields<ClientApi, Client>
