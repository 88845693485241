export const GET_PRICE_LISTS_COUNT = 'GET_PRICE_LISTS_COUNT'
export const GET_PRICE_LISTS = 'GET_PRICE_LISTS'
export const GET_PRICE_LIST = 'GET_PRICE_LIST'
export const CREATE_PRICE_LIST = 'CREATE_PRICE_LIST'
export const UPDATE_PRICE_LIST = 'UPDATE_PRICE_LIST'

export const GET_ITEMS_COUNT = 'GET_PRICE_LIST_ITEMS_COUNT'
export const GET_ITEMS = 'GET_PRICE_LIST_ITEMS'

export const GET_CURRENCIES = 'GET_PRICE_LIST_CURRENCIES'

export const CLEAR_PRICE_LIST = 'CLEAR_PRICE_LIST'
export const CLEAR_CREATE_SUCCESS = 'CLEAR_PRICE_LIST_CREATE_SUCCESS'
export const CLEAR_UPDATE_SUCCESS = 'CLEAR_PRICE_LIST_UPDATE_SUCCESS'
export const CLEAR_CREATE_ERROR = 'CLEAR_PRICE_LIST_CREATE_ERROR'
export const CLEAR_UPDATE_ERROR = 'CLEAR_PRICE_LIST_UPDATE_ERROR'

export const SET_IS_CREATE = 'SET_PRICE_LIST_IS_CREATE'
export const SET_GLOBAL_FORM = 'SET_PRICE_LIST_GLOBAL_FORM'
export const SET_LINE_ITEMS_FORM = 'SET_PRICE_LIST_LINE_ITEMS_FORM'
export const DELETE_LINE_ITEMS_FROM_SELECTION = 'DELETE_PRICE_LIST_LINE_ITEMS_FROM_SELECTION'
export const RESET_FORM = 'RESET_PRICE_LIST_FORM'
