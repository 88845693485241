import Joi from 'joi'
import { v4 as uuid } from 'uuid'

import { mapOverContents, updateItemFromUpdates } from 'components/alix-front/smart-form/utils'

import { valueOrDefault } from 'utils/defaultValueHelper'
import { DevLogs } from 'utils/devLogs'
import { getEntityObject } from 'utils/entities'
import { parseSchema } from 'utils/joiHelper'
import {
  dataToFormData,
  formDataToArray,
  formKeyDataToObject,
} from 'utils/mapperHelper'
import { safeFetchJson } from 'utils/safeFetch'
import { formatMode } from 'utils/useSmartForm'

import { SmartFormConfig, SmartFormFieldType } from 'reducers/smart-form/smartFormTypes'
import {
  validateForm,
  assertModeChange,
  handleActionOnField,
  ensureResource,
  getCreateInitialData,
  getFieldValue,
} from 'reducers/smart-form/utils'

import * as types from './types'

/**
 * @typedef {object} Form
 * @property {boolean} isValid
 * @property {boolean} hasChanges
 * @property {number} resetCount
 * @property {GlobalForm} global
 * @property {import('reducers/smart-form/smartFormTypes').LineItemsForm} lineItems
 */

/**
 * @typedef {import('reducers/smart-form/smartFormTypes').GlobalForm} GlobalForm
 */

/**
 * @typedef {object} FormField
 * @property {string} value - Value of the field
 * @property {boolean} [isChanged] - If the field has changed
 * @property {string} [dataSetName]
 * @property {string} [dbField]
 * @property {string} [updateDbField]
 * @property {import('reducers/smart-form/smartFormTypes').SmartFormFieldErrorType} [error] - Error object
 */

/**
 * @typedef {'VIEW' | 'DELETE' | 'EDIT' | 'POPUP_EDIT' | 'CREATE' | 'POPUP_CREATE'} SmartFormMode
 */

/**
 * @typedef {object} SmartFormError
 * @property {string} message - The error message
 * @property {string} name - The name of the field
 */

/**
 * @typedef {object} SmartFormCustomActionOptions
 * @property {boolean} [refreshData] - If the data should be refreshed after the action, default to false
 * - The refresh is already handled by the socket updates, so this should be used only if the action does not
 *  trigger the socket updates
 */

/**
 * @typedef {string | (fieldKey: string) => string} SmartFormTranslationPath
 */

/**
 * @typedef {(
 * fetchType: 'put' | 'post' | 'delete',
 * type: 'error' | 'success',
 * source: 'websocket' | 'fetch'
 * ) => string} SmartFormToastTranslationPath
 */

/**
 * @typedef {('put' | 'post' | 'delete')} httpMethod
 */

/**
 * @typedef {import('reducers/smart-form/smartFormTypes').SmartForm} SmartForm
 */

/**
 * @typedef {object} SmartFormState
 * @property {Record<string, SmartForm>} forms - The forms
 */

/**
 * @type {Object<SmartFormMode, SmartFormMode>}
 */
export const MODE = {
  VIEW: 'VIEW',

  DELETE: 'DELETE',

  EDIT: 'EDIT',
  POPUP_EDIT: 'POPUP_EDIT',

  CREATE: 'CREATE',
  POPUP_CREATE: 'POPUP_CREATE',
}

/**
 * @type {SmartFormState}
 */
const initialState = {
  forms: {},
}

function hasChanges(globalForm, lineItemsForm) {
  return Object.keys(globalForm).some((key) => globalForm[key].isChanged) ||
    Object.keys(lineItemsForm.updates).some((key) => lineItemsForm.updates[key].isGlobalChange) ||
    Object.keys(lineItemsForm.insertions).length > 0 ||
    Object.keys(lineItemsForm.deletions).length > 0
}

/**
 * @param {import('reducers/smart-form/smartFormTypes').BaseValidationObjectType} globalFormValidationObject
 * @param {import('reducers/smart-form/smartFormTypes').BaseValidationObjectType} lineItemFormValidationObject
 * @param {SmartForm['config']} config
 * @param {SmartForm['activeData']} activeData
 * @param {SmartForm['activeForm']} activeForm
 * @param {SmartForm['mode']} mode
 * @returns {{
 * globalFormValidationSchema: Joi.ObjectSchema,
 * lineItemsFormValidationSchema: Joi.ObjectSchema,
 * config: SmartForm['config'],
 * formFieldsFlatten: SmartFormFieldType[]
 * }}
 */
function _getValidationFormSchemas(
  globalFormValidationObject,
  lineItemFormValidationObject,
  config,
  activeData,
  activeForm,
  mode,
) {
  const buildSchema = (acc, field, _validationObject) => {
    const schema = _validationObject[field]

    if (!schema) return acc

    const fomattedFieldSchema = formatSchema(field, schema)

    return {
      ...acc,
      [field]: Joi.object().keys({
        value: fomattedFieldSchema,
      }).unknown(true),
    }
  }

  const _globalFormValidationObject = formatValidationObject(globalFormValidationObject, activeData, activeForm, mode)
  const _lineItemFormValidationObject = formatValidationObject(
    lineItemFormValidationObject,
    activeData,
    activeForm,
    mode,
  )

  const formattedFields = config.contents.map((content) => ({
    ...content,
    rows: content.rows.map((row) => ({
      ...row,
      columns: row.columns.map((field) => parseSchema(_globalFormValidationObject, field)),
    })),
  }))

  const formFieldsFlatten = formattedFields.map(({ rows }) => rows.map(({ columns }) => columns).flat()).flat()

  const lineItemFormFieldsFlatten = (
    typeof config.lineItemConfig?.contents === 'function' ?
      config.lineItemConfig.contents(activeData) :
      config.lineItemConfig?.contents
  )?.flatMap(({ rows }) => rows) ?? []

  return {
    globalFormValidationSchema: Joi.object().keys(Object.keys(_globalFormValidationObject).reduce(
      (acc, field) => buildSchema(acc, field, _globalFormValidationObject), {},
    )),
    lineItemsFormValidationSchema: Joi.object().keys(Object.keys(_lineItemFormValidationObject).reduce(
      (acc, field) => buildSchema(acc, field, _lineItemFormValidationObject), {},
    )),
    config: { ...config, contents: formattedFields },
    formFieldsFlatten,
    lineItemFormFieldsFlatten,
  }
}

/**
 * @param {SmartFormState} state
 * @param {string} key
 * @param {any} data
 * @param {{forceIsChangedWhenNotEmpty: boolean, lineItemsInInsertion: boolean}} [options]
 * @param {string} mode
 * @returns {{ activeForm: Form, config: SmartForm['config'], formFieldsFlatten: SmartFormFieldType[] }}
 */
function createActiveForm(state, key, data = {}, options = { forceIsChangedWhenNotEmpty: false }, mode) {
  /**
   * @type {SmartForm}
   */
  const {
    fields,
    config,
    getLineItemFields,
    globalFormValidationObject,
    lineItemFormValidationObject,
    activeForm,
  } = state.forms[key]

  const schemas = _getValidationFormSchemas(
    globalFormValidationObject,
    lineItemFormValidationObject,
    config,
    data,
    activeForm,
    mode,
  )

  schemas.formFieldsFlatten.forEach((field) => {
    if (!field.defaultValue || data[field.name] || getNormalizedMode(mode) !== MODE.CREATE) return

    data[field.name] = field.defaultValue
  })

  const globalForm = dataToFormData(
    data,
    fields,
    false,
    {
      forceIsChangedWhenNotEmpty: options.forceIsChangedWhenNotEmpty,
    },
  )
  const lineItemsForm = buildInitialLineItemsFormState(
    data.lineItems,
    getLineItemFields,
    options.lineItemsInInsertion,
    options.activeLineItemSelections,
  )
  const { isValid, validatedGlobalForm, validatedLineItemsForm, errors } = validateForm({
    globalForm: { form: globalForm, validationSchema: schemas.globalFormValidationSchema },
    lineItemsForm: { form: lineItemsForm, validationSchema: schemas.lineItemsFormValidationSchema },
  })

  return {
    activeForm: {
      ...state.activeForm,
      isValid,
      errors,
      global: validatedGlobalForm,
      lineItems: validatedLineItemsForm,
      hasChanges: hasChanges(validatedGlobalForm, validatedLineItemsForm),
    },
    config: schemas.config,
    formFieldsFlatten: schemas.formFieldsFlatten,
    lineItemFormFieldsFlatten: schemas.lineItemFormFieldsFlatten,
  }
}

function buildInitialLineItemsFormState(lineItems, getLineItemFields, isCreate = false, selections = []) {
  return {
    selections,
    insertions: isCreate ? buildLineItemsFormData(lineItems, getLineItemFields, true) : {},
    updates: !isCreate ? buildLineItemsFormData(lineItems, getLineItemFields) : {},
    deletions: {},
  }
}

function buildLineItemsFormData(lineItems = [], getLineItemFields, isCreate = false) {
  const formData = {}
  const lineItemFields = getLineItemFields?.(!isCreate, isCreate) ?? {}

  lineItems.forEach((lineItem) => {
    formData[lineItem.id] = dataToFormData(lineItem, lineItemFields, false, { forceIsChangedWhenNotEmpty: isCreate })
    formData[lineItem.id].isGlobalChange = isCreate
  })

  return formData
}

/**
 * @param {string} key
 * @param {Joi.Schema} schema
 * @return {Joi.Schema}
 */
function formatSchema(key, schema) {
  return schema.label(key).empty('')
}

/**
 * @param {import('reducers/smart-form/smartFormTypes').BaseValidationObjectType} validationObject
 * @param {SmartForm['activeData']} activeData
 * @param {SmartForm['activeForm']} activeForm
 * @param {SmartFormMode} mode
 * @return {Record<string, Joi.Schema>}
 */
export function formatValidationObject(validationObject, activeData, activeForm, mode) {
  const _validationObject = typeof validationObject === 'function' ?
    validationObject(activeData, activeForm, mode) :
    validationObject

  return Object.keys(_validationObject)
    .reduce((acc, key) => {
      const schema = _validationObject[key]

      if (schema === undefined) throw new Error(`The validation schema for \`${key}\` is undefined.`)

      return {
        ...acc,
        [key]: formatSchema(key, schema),
      }
    }, {})
}

const handleSetActiveData = (data, activeData, detailFetchData = {}) => {
  const id = activeData?.id ?? null

  if (id) {
    const activeDataIndex = data.findIndex((d) => d.id === id)

    if (activeDataIndex === -1) {
      data = [...data, activeData]
    } else {
      data[activeDataIndex] = activeData
    }
  }

  return data
}

/**
 *
 * @param {SmartFormState} state
 * @param {object} action
 * @return {SmartFormState} - The new state
 */
export default function smartFormReducer(state = initialState, action) {
  const { payload } = action

  /**
   * ? Uncomment to debug the actions
   */
  // region logs
  if (Object.values(types).includes(action.type)) {
    // DevLogs.log('SmartFormReducer', action.type, payload)
  }

  switch (action.type) {
  case types.SET_ACTIVE_DATA: {
    const { key, data: newActiveData, forceMode } = payload

    /**
     * @type {SmartForm}
     */
    let { data, loginResource, activeData, config } = state.forms[key]

    data = handleSetActiveData(data, newActiveData, config.detailFetchData)

    const isNewActiveDataId = activeData?.id !== newActiveData?.id

    return {
      ...state,
      forms: {
        ...state.forms,
        [key]: {
          ...state.forms[key],
          mode: forceMode ?? MODE.VIEW,
          activeData: newActiveData,
          data: data,
          ...createActiveForm(state, key, newActiveData, undefined, MODE.VIEW),
          /**
           * When the active data changes, we reset the identification
           */
          identification: isNewActiveDataId ?
            loginResource :
            state.forms[key].identification,
          lineItemIsLoading: false,
        },
      },
    }
  }
  case types.ADD_CREATED_DATA: {
    const { key, data } = payload

    const newData = [...state.forms[key].data, data]

    return {
      ...state,
      forms: {
        ...state.forms,
        [key]: {
          ...state.forms[key],
          mode: MODE.VIEW,
          activeData: data,
          data: newData,
          count: newData.length,
          ...createActiveForm(state, key, data, undefined, MODE.VIEW),
        },
      },
    }
  }
  case types.UPDATE_DATA: {
    const { key, updatedFields } = payload

    /**
     * @type {SmartForm}
     */
    const {
      activeForm,
      config,
      globalFormValidationObject,
      lineItemFormValidationObject,
      activeData,
      mode,
    } = state.forms[key]

    const global = { ...activeForm.global }

    updatedFields.forEach((fieldValue) => {
      /**
       * This is used to skip useless updates.
       * Value did not change or field is not in edit mode.
       *
       * (the `onBlur` event is triggered when the user clicks on the field and click out,
       * even if the value did not change)
       */
      const isChanged = global[fieldValue.field].value !== fieldValue.value

      if (isChanged) {
        global[fieldValue.field] = {
          ...global[fieldValue.field],
          isChanged: true,
          value: handleActionOnField(fieldValue.customUpdate, global[fieldValue.field].value, fieldValue.value),
        }
      }
    })

    const schemas = _getValidationFormSchemas(
      globalFormValidationObject,
      lineItemFormValidationObject,
      config,
      activeData,
      { global, lineItems: activeForm.lineItems },
      mode,
    )

    const { isValid, validatedGlobalForm, validatedLineItemsForm, errors } = validateForm({
      globalForm: { form: global, validationSchema: schemas.globalFormValidationSchema },
      lineItemsForm: { form: activeForm.lineItems, validationSchema: schemas.lineItemsFormValidationSchema },
    })

    return {
      ...state,
      forms: {
        ...state.forms,
        [key]: {
          ...state.forms[key],
          config: schemas.config,
          formFieldsFlatten: schemas.formFieldsFlatten,
          activeForm: {
            ...activeForm,
            hasChanges: hasChanges(validatedGlobalForm, validatedLineItemsForm),
            isValid: isValid,
            errors,
            global: validatedGlobalForm,
            lineItems: validatedLineItemsForm,
          },
        },
      },
    }
  }
  case types.UPDATE_LINE_ITEMS_DATA: {
    const { key, lineItems } = payload

    /**
     * @type {SmartForm}
     */
    const {
      activeForm,
      config,
      globalFormValidationObject,
      lineItemFormValidationObject,
      activeData,
      mode,
    } = state.forms[key]

    const schemas = _getValidationFormSchemas(
      globalFormValidationObject,
      lineItemFormValidationObject,
      config,
      activeData,
      { global: activeForm.global, lineItems },
      mode,
    )
    const { isValid, validatedGlobalForm, validatedLineItemsForm, errors } = validateForm({
      globalForm: { form: activeForm.global, validationSchema: schemas.globalFormValidationSchema },
      lineItemsForm: { form: lineItems, validationSchema: schemas.lineItemsFormValidationSchema },
    })

    return {
      ...state,
      forms: {
        ...state.forms,
        [key]: {
          ...state.forms[key],
          config: schemas.config,
          formFieldsFlatten: schemas.formFieldsFlatten,
          activeForm: {
            ...activeForm,
            hasChanges: hasChanges(validatedGlobalForm, validatedLineItemsForm),
            isValid: isValid,
            errors,
            global: validatedGlobalForm,
            lineItems: validatedLineItemsForm,
          },
        },
      },
    }
  }
  case types.INIT_FORM: {
    /**
     * @type {import('./smartFormTypes').CreateFormParams}
     */
    const {
      countFetcher,
      data,
      fetcher,
      fields,
      config,
      key,
      getTitle,
      getLineItemFields,
      id,
      bulkIds,
      globalFormValidationObject,
      lineItemFormValidationObject,
      parser,
      errorTranslationKeys,
      onFetchCallbacks,
      baseCurrency,
      mode,
      identification,
    } = payload

    if (config.lineItemConfig) {
      if (!getLineItemFields) {
        DevLogs.error('SmartForm', 'getLineItemFields is required when lineItemConfig is provided')
      }

      if (!lineItemFormValidationObject) {
        DevLogs.error('SmartForm', 'lineItemFormValidationObject is required when lineItemConfig is provided')
      }
    }

    const activeData = data[0]

    /**
     * @type {SmartForm}
     */
    const newState = {
      ...state,
      forms: {
        ...state.forms,
        [key]: {
          ...state.forms[key],
          fields,
          lineItemFields: getLineItemFields?.(),
          data,
          countFetcher,
          fetcher,
          getTitle,
          getLineItemFields,
          id,
          bulkIds,
          parser,
          config: {
            ...config,
            contents: mapOverContents(
              config.contents,
              (field) => {
                return {
                  ...field,
                  id: field.id ?? uuid(),
                }
              }),
            actions: {
              ...config.actions,
              identification: {
                ...config.actions?.identification,
                enabled: config.actions?.identification ?? false,
              },
            },
            lineItemConfig: {
              ...config.lineItemConfig,
              actions: {
                ...config.lineItemConfig?.actions,
                onViewActions: config.lineItemConfig?.actions?.onViewActions ?? [],
              },
            },
          },
          globalFormValidationObject: globalFormValidationObject ?? {},
          lineItemFormValidationObject: lineItemFormValidationObject ?? {},
          errorTranslationKeys: {
            required: 'common:validation.required',
            max: 'common:validation.max',
            min: 'common:validation.min',
            greater: 'common:validation.greater',
            ...errorTranslationKeys,
          },
          activeData: activeData,
          count: data.length,
          mode: mode || MODE.VIEW,
          onFetchCallbacks,
          baseCurrency,
          fetchResults: [],
          navigationChecks: {
            isExitWarning: false,
            callback: undefined,
          },
          isLoading: false,
          detailsIsLoading: false,
          entityIsLoading: false,
          lineItemIsLoading: false,
          jobs: [],
          identification: identification,
        },
      },
    }

    const _activeForm = createActiveForm(newState, key, activeData, undefined, MODE.VIEW)
    newState.forms[key].config = _activeForm.config
    newState.forms[key].formFieldsFlatten = _activeForm.formFieldsFlatten
    newState.forms[key].activeForm = _activeForm.activeForm

    return newState
  }
  case types.SET_MODE: {
    const { key, mode, setFormToActiveData, overrideActiveData } = payload

    const normalizedMode = getNormalizedMode(mode)
    const setToCreate = normalizedMode === MODE.CREATE

    /**
     * @type {SmartForm}
     */
    const { activeData, activeForm } = state.forms[key]

    const keepOldForm = !setToCreate

    const newActiveData = setFormToActiveData ?
      overrideActiveData ?? activeData : // Set the form to the param's activeData or the activeData of the form
      setToCreate ?
        {} : // If we are in the create mode, we do not want to keep the active data
        activeData

    const newActiveForm = createActiveForm(
      state,
      key,
      newActiveData,
      {
        forceIsChangedWhenNotEmpty: !keepOldForm,
        lineItemsInInsertion: setToCreate,
        activeLineItemSelections: activeForm?.lineItems?.selections,
      },
      mode,
    )

    /**
     * ! TODO (odeschenes) #DEV-I1875:
     * ! Add logic here to wipe the active data if we change to edit/create mode.
     * ! We should only wipe it if the action that we are setting is NOT in a popup. (See showInPopup of the action)
     */

    return {
      ...state,
      forms: {
        ...state.forms,
        [key]: {
          ...state.forms[key],
          ...newActiveForm,
          mode,
        },
      },
    }
  }
  case types.REMOVED_ACTIVE_DATA: {
    const { key } = payload

    const activeDataId = state.forms[key].activeData?.id

    const activeDataIndex = state.forms[key].data.findIndex((item) => item.id == activeDataId)

    if (activeDataIndex === -1) {
      DevLogs.error('SmartForm', 'REMOVE_ACTIVE_DATA', 'Active data not found in data list')

      return state
    }

    const data = [...state.forms[key].data]

    data.splice(activeDataIndex, 1)

    const newActiveDataIndex = Math.max(0, activeDataIndex - 1)

    return {
      ...state,
      forms: {
        ...state.forms,
        [key]: {
          ...state.forms[key],
          data,
          count: data.length,
          activeData: data[newActiveDataIndex],
          ...createActiveForm(state, key, undefined, undefined, state.forms[key].mode),
        },
      },
    }
  }
  case types.SET_OPTIONS: {
    const { key, options, optionObjects } = payload

    return {
      ...state,
      forms: {
        ...state.forms,
        [key]: {
          ...state.forms[key],
          options: {
            ...state.forms[key].options,
            ...options,
          },
          optionObjects: optionObjects || state.forms[key].optionObjects,
        },
      },
    }
  }
  case types.REMOVE_FORM: {
    const { key } = payload

    const newForms = { ...state.forms }

    delete newForms[key]

    return {
      ...state,
      forms: newForms,
    }
  }
  case types.REFRESH_DATA: {
    const { key, data, activeData = undefined } = payload

    /**
     * @type {SmartForm}
     */
    const { activeData: oldActiveData } = state.forms[key]

    /**
     * By default, we take the new forced activeData or the old activeData
     */
    let _activeData = activeData ?? oldActiveData

    const newActiveData = data.find((item) => item.id === _activeData?.id)

    /**
     * If we did not pass a new activeData, and the old action data has changed, we take the refreshed data
     */
    if (!activeData && newActiveData) {
      _activeData = newActiveData
    }

    return {
      ...state,
      forms: {
        ...state.forms,
        [key]: {
          ...state.forms[key],
          /**
           * If the a new activeData is not passed, we take the same activeData id from the new data
           */
          activeData: _activeData,
          data,
        },
      },
    }
  }
  case types.REFRESH_DATA_COUNT: {
    const { key, count } = payload

    return {
      ...state,
      forms: {
        ...state.forms,
        [key]: {
          ...state.forms[key],
          count,
        },
      },
    }
  }
  case types.SET_ACTIVE_FORM_DATA : {
    const { key, data } = payload

    return {
      ...state,
      forms: {
        ...state.forms,
        [key]: {
          ...state.forms[key],
          ...createActiveForm(state, key, data, undefined, state.forms[key].mode),
        },
      },
    }
  }
  case types.SET_FETCH_RESULT : {
    const {
      key,
      type,
      summary = 'Loading',
      detail = '',
      error = undefined,
      entityTitle = undefined,
      source = 'fetch',
      author = undefined,
      id = undefined,
    } = payload

    /**
     * @type {import('reducers/smart-form/smartFormTypes').SmartFormFetchResults}
     */
    const newFetchResults = [
      ...state.forms[key].fetchResults,
      {
        id: id ?? uuid(),
        type: type,
        error: error,
        summary: summary,
        detail: detail,
        entityTitle,
        source,
        author,
      },
    ]

    return {
      ...state,
      forms: {
        ...state.forms,
        [key]: {
          ...state.forms[key],
          fetchResults: newFetchResults,
        },
      },
    }
  }
  case types.REMOVE_FETCH_RESULT : {
    const { key, id } = payload

    const newFetchResults = state.forms[key].fetchResults.filter((item) => item.id !== id)

    return {
      ...state,
      forms: {
        ...state.forms,
        [key]: {
          ...state.forms[key],
          fetchResults: newFetchResults,
        },
      },
    }
  }
  case types.ADD_ON_FETCH_CALLBACK : {
    const { key, callback: newCallback, id } = payload

    const _onFetchCallbacks = state.forms[key].onFetchCallbacks.filter((item) => item.id !== id)

    return {
      ...state,
      forms: {
        ...state.forms,
        [key]: {
          ...state.forms[key],
          onFetchCallbacks: [..._onFetchCallbacks, { id, callback: newCallback }],
        },
      },
    }
  }
  case types.SET_LOADING_STATE : {
    const { key, isLoading } = payload

    return {
      ...state,
      forms: {
        ...state.forms,
        [key]: {
          ...state.forms[key],
          isLoading,
          entityIsLoading: isLoading || state.forms[key].detailsIsLoading,
        },
      },
    }
  }
  case types.SET_DETAILS_LOADING_STATE : {
    const { key, detailsIsLoading } = payload

    return {
      ...state,
      forms: {
        ...state.forms,
        [key]: {
          ...state.forms[key],
          detailsIsLoading,
          entityIsLoading: detailsIsLoading || state.forms[key].isLoading,
        },
      },
    }
  }
  case types.ADD_JOB: {
    const { key, id, cid, processResultId, callbackParams, onDoneCallback } = payload

    return {
      ...state,
      forms: {
        ...state.forms,
        [key]: {
          ...state.forms[key],
          jobs: [
            ...state.forms[key].jobs,
            {
              id,
              cid,
              processResultId,
              callbackParams,
              onDoneCallback,
            },
          ],
        },
      },
    }
  }
  case types.REMOVE_JOB: {
    const { key, id } = payload

    return {
      ...state,
      forms: {
        ...state.forms,
        [key]: {
          ...state.forms[key],
          jobs: state.forms[key].jobs.filter((item) => item.id !== id),
        },
      },
    }
  }
  case types.SET_IDENTIFICATION: {
    const { key, resource } = payload

    return {
      ...state,
      forms: {
        ...state.forms,
        [key]: {
          ...state.forms[key],
          identification: resource,
        },
      },
    }
  }
  case types.SET_SMART_PIN_REF: {
    const { key, ref } = payload

    return {
      ...state,
      forms: {
        ...state.forms,
        [key]: {
          ...state.forms[key],
          getSmartPinRef: ref,
        },
      },
    }
  }
  case types.SET_SMART_FORM_RELATED_REF: {
    const { formKey, refKey, ref } = payload

    return {
      ...state,
      forms: {
        ...state.forms,
        [formKey]: { ...state.forms[formKey], refs: { ...(state.forms[formKey].refs || {}), [refKey]: ref } },
      },
    }
  }
  case types.SET_LOGIN_RESOURCE: {
    const { key, loginResource } = payload

    return {
      ...state,
      forms: {
        ...state.forms,
        [key]: {
          ...state.forms[key],
          loginResource: loginResource,
          identification: loginResource,
        },
      },
    }
  }
  default: {
    return state
  }
  }
}

/**
 * @param {import('./smartFormTypes').CreateFormParams} payload
 * @return {(function(*): Promise<void>)|*}
 */
export function createForm(payload) {
  return async function(dispatch) {
    await dispatch({
      type: types.INIT_FORM,
      payload,
    })
  }
}

export function setActiveDataByIndex({ key, index }) {
  return async function(dispatch, getState) {
    const state = getState()

    const { data } = state.smartForm.forms[key] ?? {}

    if (!data?.[index]) return

    await dispatch({
      type: types.SET_ACTIVE_DATA,
      payload: { key, data: data[index] },
    })
  }
}

export function setActiveData({ key, data: newActiveData, forceMode = undefined }) {
  return async function(dispatch, getState) {
    await dispatch({
      type: types.SET_ACTIVE_DATA,
      payload: { key, data: newActiveData, forceMode },
    })
  }
}

export function setActionDataById({ key, id }) {
  return async function(dispatch, getState) {
    const state = getState()
    /**
     * @type {SmartForm}
     */
    const { data, config } = state.smartForm.forms[key]

    let activeData = data.find((item) => item.id == id)

    if (!activeData || config.detailFetchData) {
      const entity = getEntityObject(config?.entityName)
      if (!entity?.fetcher) return false

      dispatch(setDetailsLoadingState({ key, detailsIsLoading: true }))
      const [missingData] = await entity.fetcher([id], config.detailFetchData)
      dispatch(setDetailsLoadingState({ key, detailsIsLoading: false }))

      if (!missingData) {
        return false
      }

      activeData = missingData
    }

    await dispatch({
      type: types.SET_ACTIVE_DATA,
      payload: { key, data: activeData },
    })

    return true
  }
}

export function getNormalizedMode(mode) {
  switch (mode) {
  case MODE.CREATE:
  case MODE.POPUP_CREATE:
    return MODE.CREATE
  case MODE.EDIT:
  case MODE.POPUP_EDIT:
    return MODE.EDIT
  default:
    return MODE.VIEW
  }
}

/**
 *
 * @param {*} params
 * @param {string} params.key
 * @param {string} params.mode
 * @param {boolean} [params.setFormToActiveData=false] - If true, the activeData will be set to the form
 * (useful for the create mode)
 * @returns
 */
export function changeFormMode({ key, mode, setFormToActiveData = false, isGetOverrideActiveData = false }) {
  return function thunk(dispatch, getState) {
    let overrideActiveData
    if (isGetOverrideActiveData) {
      const { sessionActiveData, sessionResource } = getCreateInitialData({ key })
      overrideActiveData = sessionActiveData
      if (sessionResource) dispatch(setIdentification({ key, resource: sessionResource }))
    }

    const authorized = assertModeChange(mode, getState().smartForm.forms[key], overrideActiveData)

    if (!authorized) {
      DevLogs.error('SmartForm', 'changeFormMode', 'You are not authorized to change the mode of the form', mode)

      return false
    }

    dispatch({
      type: types.SET_MODE,
      payload: {
        key,
        mode,
        setFormToActiveData: !!overrideActiveData || setFormToActiveData,
        overrideActiveData,
      },
    })

    return true
  }
}

export function updateForm({ key, fields }) {
  return { type: types.UPDATE_DATA, payload: { key, updatedFields: fields } }
}

export function addToLineItemsForm({ key, insertions }) {
  return function addToLineItemsFormThunk(dispatch, getState) {
    /**
     * @type {SmartForm}
     */
    const { activeForm: { lineItems }, getLineItemFields } = getState().smartForm.forms[key]
    const lineItemFields = getLineItemFields?.() ?? {}
    const newInsertions = { ...lineItems.insertions }
    insertions.forEach((insertion) => newInsertions[insertion.id] = dataToFormData(
      insertion,
      lineItemFields,
      undefined,
    ))
    dispatch({
      type: types.UPDATE_LINE_ITEMS_DATA,
      payload: { key, lineItems: { ...lineItems, insertions: newInsertions } },
    })
  }
}

/**
 * @param {Object} param
 * @param {string} param.key
 * @param {{id: string, fields: {field: any, value: any}[]}[]} param.updates
 * @returns
 */
export function updateLineItemsForm({ key, updates: lineItemUpdates }) {
  return function updateLineItemsFormThunk(dispatch, getState) {
    /**
     * @type {SmartForm}
     */
    const { activeForm: { lineItems }, config: { lineItemConfig } } = getState().smartForm.forms[key]
    const lineItemSideEffects = lineItemConfig?.sideEffects

    const newLineItemsForm = { ...lineItems }

    const { insertions, updates } = lineItemUpdates.reduce((acc, { id, fields }) => {
      const lineItemState = newLineItemsForm.insertions[id] ? 'insertions' : 'updates'
      const globalForm = newLineItemsForm[lineItemState][id]

      acc[lineItemState][id] = updateItemFromUpdates(
        fields,
        globalForm,
        lineItemSideEffects,
      )

      return acc
    }, { insertions: {}, updates: {} })

    if (Object.keys(insertions).length === 0 && Object.keys(updates).length === 0) return

    dispatch({
      type: types.UPDATE_LINE_ITEMS_DATA,
      payload: {
        key,
        lineItems: {
          ...newLineItemsForm,
          insertions: {
            ...newLineItemsForm.insertions,
            ...insertions,
          },
          updates: {
            ...newLineItemsForm.updates,
            ...updates,
          },
        },
      },
    })
  }
}

export function resetLineItemsForm(key) {
  return function resetLineItemsFormThunk(dispatch, getState) {
    /**
     * @type {SmartForm}
     */
    const { getLineItemFields } = getState().smartForm.forms[key]
    dispatch({
      type: types.UPDATE_LINE_ITEMS_DATA,
      payload: { key, lineItems: buildInitialLineItemsFormState([], getLineItemFields) },
    })
  }
}

export function setLineItemsForm({ key, lineItems }) {
  return async function setLineItemsFormThunk(dispatch, getState) {
    /**
     * @type {SmartForm}
     */
    const { activeForm: { lineItems: _lineItems } } = getState().smartForm.forms[key]
    dispatch({ type: types.UPDATE_LINE_ITEMS_DATA, payload: { key, lineItems: { ..._lineItems, ...lineItems } } })
  }
}

/**
 * @param {Object} params
 * @param {string} params.key
 * @param {any[]} params.lineItems
 * @returns
 */
export function refreshLineItemById({ key, lineItems }) {
  return function refreshLineItemByIdThunk(dispatch, getState) {
    /**
     * @type {SmartForm}
     */
    const { activeData } = getState().smartForm.forms[key]

    const newLineItems = [...activeData.lineItems].map((item) => {
      const index = lineItems.findIndex((lineItem) => lineItem.id === item.id)

      if (index === -1) {
        return item
      } else {
        return lineItems[index]
      }
    })

    const dataToRefresh = {
      ...activeData,
      lineItems: newLineItems,
    }

    dispatch( refreshDataById({ key, data: dataToRefresh }))
  }
}

export function deleteFromSelection(key) {
  return async function deleteFromSelectionThunk(dispatch, getState) {
    /**
     * @type {SmartForm}
     */
    const { activeForm: { lineItems } } = getState().smartForm.forms[key]
    const newInsertions = { ...lineItems.insertions }
    const newDeletions = { ...lineItems.deletions }

    lineItems.selections.forEach((selection) => {
      if (newInsertions[selection.id]) {
        delete newInsertions[selection.id]
      } else {
        newDeletions[selection.id] = selection
      }
    })

    dispatch({
      type: types.UPDATE_LINE_ITEMS_DATA,
      payload: {
        key,
        lineItems: { ...lineItems, selections: [], insertions: newInsertions, deletions: newDeletions },
      },
    })
  }
}

export function discardChanges({ key }) {
  return function(dispatch, getState) {
    const { activeData } = getState().smartForm.forms[key]

    dispatch({
      type: types.SET_ACTIVE_DATA,
      payload: { key, data: activeData },
    })
  }
}

export function fetchOptions({ key, options }) {
  return async function(dispatch, getState) {
    /**
     * @type {SmartForm}
     */
    const { activeData, activeForm, mode } = getState().smartForm.forms[key]
    const { isEdit, isCreate } = formatMode(mode)

    const optionsToFetch = options.filter((option) => !option.isLazy)
    const _getGlobalFieldValue = (fieldKey) => getFieldValue(
      fieldKey, activeForm?.global, activeData, isEdit || isCreate,
    )
    const promises = optionsToFetch.map((option) =>
      option.fetcher(option.getFetchData?.(activeData, _getGlobalFieldValue) || option.fetchData),
    )

    const results = await Promise.allSettled(promises)
    const payload = optionsToFetch.reduce((acc, option, index) => {
      const failed = results[index].status === 'rejected'

      acc[option.key] = !failed ? results[index].value : []

      return acc
    }, {})

    await dispatch({
      type: types.SET_OPTIONS,
      payload: { key, options: payload, optionObjects: options },
    })
  }
}

export function appendToOptions({ key, optionKey, options: newOptions }) {
  return async function(dispatch, getState) {
    /**
     * @type {SmartForm}
     */
    const options = getState().smartForm.forms[key].options?.[optionKey] || []
    const optionIds = options.map((o) => o.id)

    await dispatch({
      type: types.SET_OPTIONS,
      payload: {
        key,
        options: { [optionKey]: [...options, ...newOptions.filter((o) => !optionIds.includes(o.id))] },
      },
    })
  }
}

export function buildUrl(url, id) {
  return url.replace(':id', id)
}

/**
 * @typedef {import('reducers/smart-form/smartFormTypes').SmartFormDefaultAction |
 * import('reducers/smart-form/smartFormTypes').SmartFormCustomAction
 * } SmartFormAction
 */

/**
 * @param {{
 * key: string,
 * dispatch: function,
 * id: string|string[],
 * action: SmartFormAction,
 * data: object,
 * activeForm: Form,
 * config: SmartFormConfig,
 * fields: object[],
 * method: string,
 * customMethod: string | undefined,
 * bodyDataInfo: Record<string, any> | undefined,
 * globalFormValidationObject: import('reducers/smart-form/smartFormTypes').ValidationObjectType,
 * lineItemFormValidationObject: import('reducers/smart-form/smartFormTypes').ValidationObjectType,
 * fields: object[],
 * getSmartPinRef: SmartForm["getSmartPinRef"],
 * }} options
 * @returns
 */
export async function _handleFetch({
  key,
  dispatch,
  id,
  action,
  data,
  activeForm,
  config,
  fields,
  method,
  customMethod = undefined,
  bodyDataInfo = undefined,
  activeData,
  globalFormValidationObject,
  lineItemFormValidationObject,
  lineItemFields,
  getSmartPinRef,
  mode,
}) {
  const { ok, resource } = await ensureResource({
    dispatch,
    key,
    getSmartPinRef,
  })

  if (!ok) {
    return {
      ok: false,
    }
  }

  const lineItemData = activeForm.lineItems

  const schemas = _getValidationFormSchemas(
    globalFormValidationObject,
    lineItemFormValidationObject,
    config,
    activeData,
    { global: data, lineItems: lineItemData },
    mode,
  )

  const { value: globalValue } = await schemas.globalFormValidationSchema.validate(data, { abortEarly: false })
  Promise.all([
    schemas.globalFormValidationSchema.validate(data, { abortEarly: false }),
    schemas.lineItemsFormValidationSchema.validate(data?.lineItems, { abortEarly: false }),
  ])

  const validateLineItems = (_form) => Object.keys(_form).reduce(async(acc, id) => ({
    ...await acc,
    [id]: (await schemas.lineItemsFormValidationSchema.validate(_form[id], { abortEarly: false })).value,
  }), Promise.resolve({}))

  const objectData = formKeyDataToObject(globalValue, {
    isDatabaseNull: false,
    skipNonEditableField: true,
    onlyChanged: method !== 'post',
    fieldsData: fields,
    skipNullValues: method === 'post',
  })

  objectData.id = id
  if (globalValue?.lineItems) {
    const insertionsValue = await validateLineItems(lineItemData.insertions)
    const insertions = formDataToArray(
      insertionsValue,
      true,
      true,
      config.lineItemConfig?.includeId ?? false,
      true,
      lineItemFields,
    )
    if (method === 'post') {
      objectData.lineItems = insertions
    } else {
      const updates = { ...lineItemData.updates }
      Object.keys(updates)
        .filter((key) => !updates[key].isGlobalChange)
        .forEach((key) => delete updates[key])
      const updatesValue = await validateLineItems(updates)
      objectData.lineItems = {
        insertions,
        updates: formDataToArray(updatesValue, true, true, true, true, lineItemFields),
        deletions: Object.keys(lineItemData.deletions),
      }
    }
  }

  const request = (resource) => {
    if (config.formatFormData) {
      config.formatFormData(objectData, method, activeForm, customMethod, resource)
    }

    let bodyData
    const ids = [].concat(id)

    if (config.formatBodyData) {
      bodyData = config.formatBodyData(objectData, method, customMethod, data, bodyDataInfo, resource, activeData)
    } else if (ids.length > 1) {
      bodyData = { data: ids.map((id) => ({ ...objectData, id })) }
    } else {
      bodyData = { data: objectData, resource }
    }

    return safeFetchJson(
      buildUrl(action.url, id),
      {
        method: customMethod ?? method,
        body: JSON.stringify(bodyData),
        headers: { 'Content-Type': 'application/json' },
      },
    )
  }

  const handledFetch = await handleFetch({
    key,
    dispatch,
    action,
    request,
    resource,
  })

  return {
    ...handledFetch,
    ok: true,
  }
}

/**
 *
 * @param {Object} options
 * @param {any} options.dispatch
 * @param {string} options.key
 * @param {any} options.action
 * @param {(resource: any) => Promise<any>} options.request
 * @param {string} options.id
 * @param {import('reducers/smart-form/smartFormTypes').SmartForm['getSmartPinRef']} options.getSmartPinRef
 * @returns
 */
export async function handleActionResult({ dispatch, key, action, request, id, getSmartPinRef }) {
  const { ok, resource } = await ensureResource({
    dispatch,
    key,
    getSmartPinRef,
  })

  if (!ok) return false

  const { response, processResultId, silent = false } = await handleFetch({
    request,
    action,
    dispatch,
    key,
    resource,
  })

  if (!response || silent) return

  dispatch(handleResponse({
    key,
    response: response,
    action,
    id,
    processResultId,
  }))

  return response
}

export async function handleFetch({ dispatch, key, action, request, resource, getSmartPinRef }) {
  const { ok, resource: pinResource } = await ensureResource({
    dispatch,
    key,
    getSmartPinRef,
  })

  if (!ok) return false

  dispatch(setLoadingState({ key, isLoading: true }))

  let processResultId
  // eslint-disable-next-line prefer-const
  let response

  const timeoutId = setTimeout(async() => {
    if (response) return

    processResultId = await dispatch(initializeSlowResponse({
      key,
      summary: action.proccessContent?.summaryKey,
      detail: action.proccessContent?.detailKey,
    }))
  }, 1000)

  response = await request(pinResource ?? resource)

  const isJob = response?.response?.result?.isTimeout

  if (processResultId && !isJob) {
    dispatch(removeFetchResult({ key, id: processResultId }))
  }

  clearTimeout(timeoutId)

  if (!isJob) {
    dispatch(setLoadingState({ key, isLoading: false }))
  }

  return { response, processResultId, silent: response?.silent ?? false }
}

/**
 * @param {object} result
 * @param {string} [id]
 * @param {Function} parser
 * @return {{data: object[], dataFromId: object}}}
 */
function _handleResult(result, id, parser) {
  const parsedData = (Array.isArray(result) ? result : [result]).map((item) => parser(item)).flat()

  return {
    data: parsedData,
    dataFromId: id ? parsedData.find((item) => item.id === id) : null,
  }
}

export function handlePut({ key, action, changeMode = false, customData = undefined, bodyDataInfo = undefined }) {
  return async function(dispatch, getState) {
    /**
     * @type {SmartForm}
     */
    const {
      activeForm,
      activeData,
      config,
      fields,
      bulkIds,
      globalFormValidationObject,
      lineItemFormValidationObject,
      lineItemFields,
      getSmartPinRef: getSmartPinRef,
      mode,
    } = getState().smartForm.forms[key]

    const data = customData ?? activeForm.global
    const id = bulkIds?.length > 1 ? bulkIds : data.id.value

    if (!action.url) {
      DevLogs.error('SmartForm', 'handleActionResult', 'No url found for action', action)

      return
    }

    const { response, processResultId, ok } = await _handleFetch({
      key,
      dispatch,
      id,
      action,
      data,
      activeForm,
      config,
      fields,
      method: 'put',
      bodyDataInfo,
      globalFormValidationObject,
      lineItemFormValidationObject,
      activeData,
      lineItemFields,
      getSmartPinRef,
      mode,
    })

    if (!ok) return

    const handledResponse = await dispatch(
      handleResponse({
        key,
        action,
        id,
        response: { response },
        type: 'put',
        processResultId,
      }),
    )

    if (changeMode) {
      dispatch(changeFormMode({ key, mode: MODE.VIEW }))
    }

    return handledResponse
  }
}

export function handlePost({ key, action, changeMode = true, customData = undefined }) {
  return async function(dispatch, getState) {
    /**
     * @type {SmartForm}
     */
    const {
      activeForm,
      activeData,
      config,
      fields,
      globalFormValidationObject,
      lineItemFormValidationObject,
      lineItemFields,
      getSmartPinRef: getSmartPinRef,
      mode,
    } = getState().smartForm.forms[key]

    const customMethod = action.type

    if (!action.url) {
      DevLogs.error('SmartForm', 'handleActionResult', 'No url found for action', action)

      return
    }

    const id = uuid()

    const { response, processResultId, ok } = await _handleFetch({
      key,
      dispatch,
      id: id,
      action,
      data: customData ?? activeForm.global,
      activeForm,
      config,
      fields,
      method: 'post',
      customMethod,
      globalFormValidationObject,
      lineItemFormValidationObject,
      activeData,
      lineItemFields,
      getSmartPinRef,
      mode,
    })

    if (!ok) return

    const handledResponse = await dispatch(handleResponse({
      key, action, id,
      response: { response, options: { refreshData: true } },
      type: 'post',
      processResultId,
    }))

    if (changeMode && response.isSuccess) {
      dispatch(changeFormMode({ key, mode: MODE.VIEW }))
    }

    return handledResponse
  }
}

export function handleDelete({ key, action, id, changeMode = true }) {
  return async function(dispatch, getState) {
    /**
     * @type {SmartForm}
     */
    const {
      config,
      fields,
      globalFormValidationObject,
      lineItemFormValidationObject,
      activeData,
      lineItemFields,
      getSmartPinRef: getSmartPinRef,
      activeForm,
      mode,
    } = getState().smartForm.forms[key]

    const customMethod = action.type

    if (changeMode) {
      dispatch(changeFormMode({ key, mode: MODE.VIEW }))
    }

    if (!action.url) {
      DevLogs.error('SmartForm', 'handleActionResult', 'No url found for action', action)

      return
    }

    const { response, processResultId, ok } = await _handleFetch({
      key,
      dispatch,
      id,
      action,
      config,
      fields,
      method: 'delete',
      customMethod: customMethod ?? 'delete',
      globalFormValidationObject,
      lineItemFormValidationObject,
      activeData,
      lineItemFields,
      getSmartPinRef,
      activeForm,
      mode,
    })

    if (!ok) return

    return await dispatch(handleResponse({
      key, action, id,
      response: { response },
      type: 'delete',
      processResultId,
    }))
  }
}

export function removeForm({ key }) {
  return async function(dispatch) {
    await dispatch({
      type: types.REMOVE_FORM,
      payload: { key },
    })
  }
}

export function fetchData(key) {
  return function(smartGridData) {
    return async function fetchDataThunk(dispatch, getState) {
      /**
       * @type {SmartForm}
       */
      const { fetcher, activeData, config } = getState().smartForm.forms[key]

      if (!fetcher) return

      let data = await fetcher(smartGridData)

      if (config.detailFetchData && activeData) {
        data = data.map((item) => {
          if (item.id === activeData.id) return { ...activeData }

          return item
        })
      }

      await dispatch({ type: types.REFRESH_DATA, payload: { key, data } })

      return data
    }
  }
}

export function fetchDataCount(key) {
  return function(smartGridData) {
    return async function fetchDataThunk(dispatch, getState) {
      const { countFetcher } = getState().smartForm.forms[key]

      if (!countFetcher) return

      const count = await countFetcher(smartGridData)

      await dispatch({ type: types.REFRESH_DATA_COUNT, payload: { key, count } })

      return count
    }
  }
}

/**
 * Refresh a specific data by id
 * This does not handle the case were the data is not in the old data
 *
 * @param {{key: string, data: any[]}} params
 * @returns void
 */
export function refreshDataById({ key, data: dataToRefresh }) {
  return async function(dispatch, getState) {
    const { data: oldData } = getState().smartForm.forms[key]

    const id = dataToRefresh.id

    const newData = oldData.map((item) => {
      if (item.id == id) {
        return dataToRefresh
      }

      return item
    })
    await dispatch({
      type: types.REFRESH_DATA, payload: {
        key,
        data: newData,
      },
    })
  }
}

/**
 * Refresh the data with new data
 * Note that this also handles new data that is not in the old data
 *
 * @param {{key: string, data: any[]}} params
 * @returns void
 */
export function refreshData({ key, data: dataToRefresh }) {
  return async function(dispatch, getState) {
    /**
     * @type {SmartForm}
     */
    const { data: oldData, activeData } = getState().smartForm.forms[key]

    /**
     * Swap the new data with the old data
     */
    const newData = oldData.map((oldItem) => {
      /**
       * The index of the current old item in our new data array
       */
      const refreshedItemIndex = dataToRefresh.findIndex((newItem) => newItem.id == oldItem.id)
      /**
       * The new data
       */
      const newItem = refreshedItemIndex !== -1 ? dataToRefresh[refreshedItemIndex] : null

      /**
       * If the old item is to be refreshed, we removed it from our new data array and return the new data
       */
      if (newItem) {
        dataToRefresh.splice(refreshedItemIndex, 1)
        return newItem
      }

      /**
       * If the old item is not to be refreshed, we return the old item
       */
      return oldItem
    })

    let changedActiveData = activeData?.id ? newData.find((d) => d.id === activeData?.id) : undefined
    /**
     * If there is still data left in our new data array, it means that we have new data
     */
    if (dataToRefresh.length) {
      /**
       * We add the new data to the beginning of our new data array
       */
      newData.unshift(...dataToRefresh)

      /**
       * This means that we will have to change the active data
       */
      changedActiveData = dataToRefresh[0]
    }
    await dispatch({
      type: types.REFRESH_DATA, payload: {
        key,
        data: newData,
        activeData: changedActiveData,
      },
    })
  }
}

export function refreshDeletedData({ key, id }) {
  return async function(dispatch, getState) {
    const { data: oldData, activeData } = getState().smartForm.forms[key]

    const newData = oldData.filter((item) => item.id !== id)

    const newActiveData = (activeData?.id === id) ? newData[0] : activeData

    await dispatch({
      type: types.REFRESH_DATA, payload: {
        key,
        data: newData,
        activeData: newActiveData,
      },
    })
  }
}

export function removeFetchResult({ key, id }) {
  return {
    type: types.REMOVE_FETCH_RESULT, payload: { key, id },
  }
}

export function addOnFetchCallback({ key, callback, id }) {
  return {
    type: types.ADD_ON_FETCH_CALLBACK, payload: { key, callback, id },
  }
}

/**
 * @typedef {object} HandleResponseParams
 * @property {string} key
 * @property {string} [id]
 * @property {{
 * response: import('utils/safeFetch').SafeFetchResponse,
 * options: SmartFormCustomActionOptions
 * }} response
 * @property {{type: (string | undefined)}} action
 * @property {httpMethod} [type] - The type of the action result, default to 'put'
 * @property {SmartForm} [state]
 */

/**
 * @param {HandleResponseParams} params
 * @return {object}
 */
export function handleResponse({ key, id, response: actionResponse, action, type, processResultId, onDoneCallback }) {
  return async(dispatch, getState) => {
    if (!actionResponse) return

    /**
     * @type {SmartForm}
     */
    const { parser, onFetchCallbacks, getTitle, activeData = {} } = getState().smartForm.forms[key]

    const { response, options } = actionResponse

    const { isSuccess, result, error } = response

    if (!isSuccess) {
      dispatch({ type: types.SET_FETCH_RESULT, payload: {
        key,
        type: type ?? 'put',
        error: result ?? error,
      },
      })

      onDoneCallback?.(response)

      return result
    }

    if (result?.isTimeout) {
      dispatch(addJob({
        cid: result.cid,
        key,
        id: result.jobUUID,
        processResultId: processResultId,
        callbackParams: {
          id, action, type,
        },
        onDoneCallback,
      }))

      return null
    }

    const { dataFromId: parsedResult, data: allParsedData } = _handleResult(result, id, parser)

    let titles

    if (parsedResult) {
      titles = getTitle(parsedResult)
    }

    dispatch(
      {
        type: types.SET_FETCH_RESULT,
        payload: {
          key,
          type: type ?? 'put',
          error: isSuccess ? undefined : result,
          entityTitle: titles,
        },
      })

    const dataToRefresh = parsedResult ? [parsedResult] : allParsedData

    const shouldRefreshData =
      valueOrDefault(options?.refreshData, true) ||
      activeData.modifiedDate !== dataToRefresh.find((item) => item.id == activeData?.id)?.modifiedDate

    if (shouldRefreshData) {
      const cloneDataToRefresh = JSON.parse(JSON.stringify(dataToRefresh))
      if (type === 'delete' && id) {
        await dispatch(refreshDeletedData({ key, id }))
      } else {
        await dispatch(refreshData({ key, data: dataToRefresh }))
      }

      await Promise.all(
        onFetchCallbacks
          .map(({ callback }) => callback(cloneDataToRefresh, type, action.type)),
      )
    }

    onDoneCallback?.(response)

    return parsedResult
  }
}

export function initializeSlowResponse({ key, summary, detail }) {
  const id = uuid()

  return async function(dispatch) {
    await dispatch(
      {
        type: types.SET_FETCH_RESULT,
        payload: {
          key,
          type: 'loading',
          id,
          summary: summary ?? `common:toastMessage.processing`,
          detail: detail,
        },
      },
    )

    return id
  }
}

export function setLoadingState({ key, isLoading }) {
  return { type: types.SET_LOADING_STATE, payload: { key, isLoading: !!isLoading } }
}

export function setDetailsLoadingState({ key, detailsIsLoading }) {
  return { type: types.SET_DETAILS_LOADING_STATE, payload: { key, detailsIsLoading } }
}

export function addJob({ key, id, cid, processResultId, callbackParams, onDoneCallback }) {
  return { type: types.ADD_JOB, payload: { key, id, cid, processResultId, callbackParams, onDoneCallback } }
}

export function removeJob({ key, id }) {
  return { type: types.REMOVE_JOB, payload: { key, id } }
}

export function setIdentification({ key, resource }) {
  return { type: types.SET_IDENTIFICATION, payload: { key, resource } }
}

/**
 *
 * @param {SmartFormMode} mode - The mode to check
 */
export function isPopupMode(mode) {
  return mode.startsWith('POPUP_')
}

export function setSmartPinRef(key, ref) {
  return { type: types.SET_SMART_PIN_REF, payload: { key, ref } }
}

export function setSmartFormRelatedRef(formKey, refKey, ref) {
  return { type: types.SET_SMART_FORM_RELATED_REF, payload: { formKey, refKey, ref } }
}

export function setLoginResource(key, loginResource) {
  return { type: types.SET_LOGIN_RESOURCE, payload: { key, loginResource } }
}
