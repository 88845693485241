
import { buildGetUrl, parse } from 'utils/api'
import { dataToFormData, formDataToArray, formKeyDataToObject } from 'utils/mapperHelper'
import { parseError, safeFetch, safeFetchJson } from 'utils/safeFetch'
import { convertFromDollarPerBase } from 'utils/unitConverter'

import {
  parseCurrency,
  getDefaultCurrency,
  fetchCurrencies as _fetchCurrencies,
} from 'reducers/currencies/currenciesSlice'

import {
  GET_PRICE_LISTS_COUNT,
  GET_PRICE_LISTS,
  GET_PRICE_LIST,
  CLEAR_PRICE_LIST,
  CREATE_PRICE_LIST,
  UPDATE_PRICE_LIST,
  GET_ITEMS_COUNT,
  GET_ITEMS,
  GET_CURRENCIES,
  SET_IS_CREATE,
  SET_GLOBAL_FORM,
  SET_LINE_ITEMS_FORM,
  DELETE_LINE_ITEMS_FROM_SELECTION,
  RESET_FORM,
} from './types'

const dataSetName = 'priceList'
const fields = getFields()
const initialState = {
  dataSetName,
  fields,
  itemFields: getItemFields(),
  priceListsCount: 0,
  priceLists: [],
  activePriceList: getDefaultPriceList(),
  activeForm: getDefaultForm(),
  currencies: [],
  baseCurrency: {},
}

export default function priceListsReducer(state = initialState, action) {
  const { payload } = action
  switch (action.type) {
  case GET_PRICE_LISTS_COUNT: {
    return {
      ...state,
      priceListsCount: payload,
    }
  }
  case GET_PRICE_LISTS: {
    return {
      ...state,
      priceLists: payload,
    }
  }
  case GET_PRICE_LIST: {
    return buildPriceListState(state, payload)
  }
  case CREATE_PRICE_LIST: {
    const newState = buildPriceListState(state, payload)
    return {
      ...newState,
      activeForm: {
        ...newState.activeForm,
        lineItems: {
          ...newState.activeForm.lineItems,
          selections: [],
          insertions: {},
          deletions: {},
        },
      },
    }
  }
  case UPDATE_PRICE_LIST: {
    return buildPriceListState(state, payload)
  }
  case CLEAR_PRICE_LIST: {
    return {
      ...state,
      activePriceList: getDefaultPriceList(),
      activeForm: getDefaultForm(),
    }
  }
  case GET_ITEMS_COUNT: {
    return {
      ...state,
      activePriceList: {
        ...state.activePriceList,
        itemsCount: payload,
      },
    }
  }
  case GET_ITEMS: {
    let lineItemsForm = { ...state.activeForm.lineItems }
    Object.keys(lineItemsForm.updates).forEach((uuid) => {
      if (!lineItemsForm.updates[uuid].isGlobalChange) {
        delete lineItemsForm.updates[uuid]
      }
    })

    lineItemsForm = {
      ...lineItemsForm,
      updates: {
        ...buildLineItemsFormData(payload),
        ...lineItemsForm.updates,
      },
    }

    return {
      ...state,
      activePriceList: {
        ...state.activePriceList,
        items: payload,
      },
      activeForm: {
        ...state.activeForm,
        hasChanges: hasChanges(state.activeForm.global, lineItemsForm),
        lineItems: lineItemsForm,
      },
    }
  }
  case GET_CURRENCIES: {
    const baseCurrency = payload.find((currency) => currency.isBase)

    return {
      ...state,
      currencies: payload,
      baseCurrency,
      activeForm: {
        ...state.activeForm,
        currencyInfo: parseCurrency(
          state.activePriceList,
          state.activeForm.global.currencyId.data,
          baseCurrency,
          state.activeForm.isCreate,
        ),
      },
    }
  }
  case SET_IS_CREATE: {
    return {
      ...state,
      activeForm: {
        ...state.activeForm,
        isCreate: payload,
        currencyInfo: parseCurrency(
          state.activePriceList,
          state.activeForm.global.currencyId.data,
          state.baseCurrency,
          payload,
        ),
      },
    }
  }
  case SET_GLOBAL_FORM: {
    return {
      ...state,
      activeForm: {
        ...state.activeForm,
        hasChanges: hasChanges(payload, state.activeForm.lineItems),
        isValid: isFormValid(payload),
        global: payload,
        currencyInfo: parseCurrency(
          state.activePriceList,
          payload.currencyId.data,
          state.baseCurrency,
          state.activeForm.isCreate,
        ),
      },
    }
  }
  case SET_LINE_ITEMS_FORM: {
    return {
      ...state,
      activeForm: {
        ...state.activeForm,
        hasChanges: hasChanges(state.activeForm.global, payload),
        lineItems: payload,
      },
    }
  }
  case DELETE_LINE_ITEMS_FROM_SELECTION: {
    const newInsertions = { ...state.activeForm.lineItems.insertions }
    const newDeletions = { ...state.activeForm.lineItems.deletions }
    state.activeForm.lineItems.selections.forEach((selection) => {
      if (newInsertions[selection.id]) {
        delete newInsertions[selection.id]
      } else {
        newDeletions[selection.id] = selection
      }
    })

    return buildLineItemsFormState(state, {
      ...state.activeForm.lineItems,
      selections: [],
      insertions: newInsertions,
      deletions: newDeletions,
    })
  }
  case RESET_FORM: {
    const globalForm = dataToFormData(state.activePriceList, getFields(true))
    const lineItemsForm = buildInitialLineItemsFormState(state.activePriceList.lineItems)

    return {
      ...state,
      activeForm: {
        ...state.activeForm,
        hasChanges: hasChanges(globalForm, lineItemsForm),
        isValid: isFormValid(globalForm),
        resetCount: state.activeForm.resetCount + 1,
        global: globalForm,
        lineItems: lineItemsForm,
        currencyInfo: parseCurrency(
          state.activePriceList,
          globalForm.currencyId.data,
          state.baseCurrency,
          state.activeForm.isCreate,
        ),
      },
    }
  }
  default: {
    return state
  }
  }
}

// price lists exports
export function getFields(editOnly) {
  const editFields = {
    'id': { dataSetName, dbField: 'id', isEdit: false },
    'currencyId': { dataSetName, dbField: 'currency_id', isEdit: true },
    'name': { dataSetName, dbField: 'name', isEdit: true },
    'description': { dataSetName, dbField: 'description', isEdit: true },
    'scope': { dataSetName, dbField: 'scope', isEdit: true },
    'pricingMethod': { dataSetName, dbField: 'pricing_method', isEdit: true },
    'lineCount': { dataSetName, parse: parseLineCount },
    'lineItems': { parse: (priceList = {}, options = {}) => {
      const lineItems = (priceList.lineItems || []).map((item) => {
        return parseLineItem(item, options.defaultUnits)
      })
      return [...lineItems]
    } },
  }
  if (editOnly) {
    return editFields
  }

  const fields = {
    createdDate: { dataSetName, dbField: 'created_date', type: 'date' },
    modifiedDate: { dataSetName, dbField: 'modified_date', type: 'date' },
    modifiedBy: { dataSetName, dbField: 'modified_by' },
    modifiedById: { dataSetName, dbField: 'modified_by_id' },
    currencyCode: { dataSetName: 'currency', dbField: 'currency_code' },
    currencySymbol: { dataSetName: 'currency', dbField: 'currency_symbol' },
    exchangeRate: { dataSetName: 'currency', dbField: 'exchange_rate' },
  }

  return { ...fields, ...editFields }
}

export function fetchPriceListsCount(data) {
  return async function fetchPriceListsCountThunk(dispatch) {
    try {
      const result = await (await safeFetch(buildGetUrl('/new_api/price-lists/count', data))).json()
      if (result.isSuccess) {
        const count = +result.result[0].count || 0
        dispatch({ type: GET_PRICE_LISTS_COUNT, payload: count })
        return count
      }
    } catch (err) {
      console.error(err)
    }
    return 0
  }
}

export async function _fetchPriceLists(data = {}, callBackOnSuccess = undefined) {
  let priceLists = []
  try {
    const result = await (await safeFetch(buildGetUrl('/new_api/price-lists', { ...data }))).json()
    if (result.isSuccess) {
      priceLists = result.result.map((priceList) => parsePriceList(priceList))

      if (callBackOnSuccess) {
        callBackOnSuccess(priceLists)
      }
    }
  } catch (err) {
    console.error(err)
  }

  return priceLists
}
export function fetchPriceLists(data = {}) {
  return async function fetchPriceListsThunk(dispatch) {
    return _fetchPriceLists(data, (priceLists) => dispatch({ type: GET_PRICE_LISTS, payload: priceLists }))
  }
}

export function fetchPriceList(priceListId) {
  return async function fetchPriceListThunk(dispatch) {
    if (priceListId === 'new') {
      dispatch({ type: SET_IS_CREATE, payload: true })
      return null
    } else {
      let parsedPriceList = null
      try {
        const result = await (await safeFetch(`/new_api/price-lists/${priceListId}`)).json()
        if (result.isSuccess) {
          const [priceList] = result.result
          parsedPriceList = parsePriceList(priceList)
          dispatch({ type: GET_PRICE_LIST, payload: parsedPriceList })
        }
      } catch (err) {
        console.error(err)
      }
      return parsedPriceList
    }
  }
}

export function savePriceList(setPage) {
  return async function savePriceListThunk(dispatch, getState) {
    const priceListsStore = getState().priceLists
    const globalFormData = formKeyDataToObject(priceListsStore.activeForm.global)
    const insertions = formDataToArray(priceListsStore.activeForm.lineItems.insertions, true, true, false)
    if (priceListsStore.activeForm.isCreate) {
      setPage((page) => ({ ...page, isCreating: true }))
      const priceList = {
        ...globalFormData,
        lineItems: insertions,
      }
      return _createPriceList(dispatch, priceList )
    } else {
      const updates = { ...priceListsStore.activeForm.lineItems.updates }
      Object.keys(updates)
        .filter((key) => !updates[key].isGlobalChange)
        .forEach((key) => delete updates[key])

      const priceList = {
        id: priceListsStore.activePriceList.id,
        ...globalFormData,
        lineItems: {
          insertions,
          updates: formDataToArray(updates),
          deletions: Object.keys(priceListsStore.activeForm.lineItems.deletions),
        },
      }
      return _updatePriceList(dispatch, priceList)
    }
  }
}

async function _createPriceList(dispatch, priceList) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ priceList }),
  }
  try {
    const result = await (await safeFetch(`/new_api/price-lists`, requestOptions)).json()
    const payload = result.isSuccess ? parsePriceList(result.result[0]) : null
    const error = !result.isSuccess ? result.result : null
    dispatch({ type: CREATE_PRICE_LIST, payload, error })
    return { isCreate: true, priceList: payload }
  } catch (error) {
    dispatch({ type: CREATE_PRICE_LIST, error })
  }
}

async function _updatePriceList(dispatch, priceList) {
  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ priceList }),
  }
  try {
    const result = await (await safeFetch(`/new_api/price-lists/${priceList.id}`, requestOptions)).json()
    const [updated] = result.isSuccess ? result.result : []
    const payload = updated ? parsePriceList(updated) : null
    const error = !result.isSuccess ? result.result : null

    dispatch({ type: UPDATE_PRICE_LIST, payload, error })
    return { priceList: payload }
  } catch (error) {
    dispatch({ type: UPDATE_PRICE_LIST, error })
  }
}

export async function deletePriceList(priceListIds) {
  try {
    return await (await safeFetch(`/new_api/price-lists/${priceListIds}`, { method: 'DELETE' })).json()
  } catch (error) {
    return parseError(error)
  }
}

export function clearPriceList(dispatch) {
  dispatch({ type: CLEAR_PRICE_LIST })
}

// items exports
export function getItemFields(editOnly) {
  const editFields = {
    id: { dataSetName: 'priceListItem', dbField: 'id', isEdit: false },
    moq: { dataSetName: 'priceListItem', dbField: 'min_measure', formDefaultValue: null, isEdit: true },
    rate: { dataSetName: 'priceListItem', dbField: 'rate', formDefaultValue: null, isEdit: true },
    templateId: { dataSetName: 'priceListItem', dbField: 'template_id', isEdit: true },
  }
  if (editOnly) {
    return editFields
  }

  const fields = {
    exist: { dataSetName: 'priceListItem', dbField: 'exist' },
    createdDate: { dataSetName: 'priceListItem', dbField: 'created_date' },
    createdBy: { dataSetName: 'priceListItem', dbField: 'created_by' },
    createdById: { dataSetName: 'priceListItem', dbField: 'created_by_id' },
    modifiedDate: { dataSetName: 'priceListItem', dbField: 'modified_date' },
    modifiedBy: { dataSetName: 'priceListItem', dbField: 'modified_by' },
    modifiedById: { dataSetName: 'priceListItem', dbField: 'modified_by_id' },
    dimension: { dataSetName: 'priceListItem', dbField: 'dimension_to_display' },
    measureUnit: { dataSetName: 'priceListItem', dbField: 'measure_unit', parse: getUnit },
    priceListId: { dataSetName: 'priceListItem', dbField: 'price_list_id' },
    templateSku: { dataSetName: 'templateView', dbField: 'sku', trimAlias: 'template' },
    templateDescription: { dataSetName: 'templateView', dbField: 'description', trimAlias: 'template' },
    templateMoq: { dataSetName: 'templateView', dbField: 'min_selling_measure', trimAlias: 'template' },
    templateConvertedSellingPrice: {
      dataSetName: 'templateView',
      dbField: 'unit_selling_price',
      dataSetAlias: 'template',
      parse: parseTemplateSellingPrice,
    },
    templateTitle: { parse: (item = {}) => item.template_title },
    templateSecondaryDescription: {
      dataSetName: 'templateView',
      dbField: 'secondary_description',
      trimAlias: 'template',
    },
    templatePartNumber: { dataSetName: 'templateView', dbField: 'part_number', trimAlias: 'template' },
    templateManufacturer: { dataSetName: 'templateView', dbField: 'manufacturer', trimAlias: 'template' },
    templateRevision: { dataSetName: 'templateView', dbField: 'revision', trimAlias: 'template' },
    templateMaterial: { dataSetName: 'templateView', dbField: 'material_title', parse: (item) => item.material_title },
    templateTreatment: {
      dataSetName: 'templateView',
      dbField: 'treatment_title',
      parse: (item) => item.treatment_title,
    },
    templateCategory: { dataSetName: 'templateView', dbField: 'category_title', parse: (item) => item.category_title },
    templateRawImperial: {
      dataSetName: 'templateView',
      dbField: 'raw_imperial_title',
      parse: (item) => item.raw_imperial_title,
    },
    templateRawMetric: {
      dataSetName: 'templateView',
      dbField: 'raw_metric_title',
      parse: (item) => item.raw_metric_title,
    },
  }
  return { ...fields, ...editFields }
}

export function fetchItemsCount(data) {
  return async function fetchItemsCountThunk(dispatch) {
    try {
      const result = await (await safeFetch(buildGetUrl('/new_api/price-lists/items/count', data))).json()
      if (result.isSuccess) {
        const count = +result.result[0].count || 0
        dispatch({ type: GET_ITEMS_COUNT, payload: count })
        return count
      }

      return 0
    } catch (err) {
      console.error(err)
      return 0
    }
  }
}

export function fetchItems(data, mapData = {}) {
  return async function fetchItemsThunk(dispatch) {
    let items = []

    try {
      const result = await (await safeFetch(buildGetUrl('/new_api/price-lists/items', data))).json()
      if (result.isSuccess) {
        items = result.result.map((item) => parseLineItem(item, mapData.defaultUnits))
        dispatch({ type: GET_ITEMS, payload: items })
      }
    } catch (err) {
      console.error(err)
    }

    return items
  }
}

export async function fetchPriceListsByIds(ids, data) {
  if (!ids?.length) return []

  const { isSuccess, result } = await safeFetchJson(
    buildGetUrl(`/new_api/price-lists/${ids}`, data),
  )

  return isSuccess ? result.map(parsePriceList) : []
}

// currency exports
export function fetchCurrencies() {
  return async function fetchCurrenciesThunk(dispatch) {
    const currencies = await _fetchCurrencies()
    dispatch({ type: GET_CURRENCIES, payload: currencies })
  }
}

// form exports
export function updateGlobalFormFields(fieldValues) {
  return async function updateGlobalFormFieldsThunk(dispatch, getState) {
    const priceListsStore = getState().priceLists
    const payload = { ...priceListsStore.activeForm.global }

    fieldValues.forEach((fieldValue) => {
      payload[fieldValue.field] = {
        ...priceListsStore.activeForm.global[fieldValue.field],
        value: fieldValue.value,
        data: fieldValue.data,
        isChanged: true,
      }
    })

    dispatch({ type: SET_GLOBAL_FORM, payload })
  }
}

export function updateLineItemsForm(newLineItemsForm) {
  return async function updateLineItemsFormThunk(dispatch) {
    dispatch({ type: SET_LINE_ITEMS_FORM, payload: newLineItemsForm })
  }
}

export function deleteFromSelection(dispatch) {
  dispatch({ type: DELETE_LINE_ITEMS_FROM_SELECTION })
}

export function resetForm(dispatch) {
  dispatch({ type: RESET_FORM })
}

// price list parsing functions
function buildPriceListState(state, payload) {
  if (!payload) {
    return state
  }

  const globalForm = dataToFormData(payload, getFields(true))
  const lineItemsForm = buildInitialLineItemsFormState(payload.lineItems)
  const newActiveForm = {
    ...state.activeForm,
    isCreate: false,
    isValid: isFormValid(globalForm),
    global: globalForm,
    lineItems: lineItemsForm,
    currencyInfo: parseCurrency(payload, globalForm.currencyId.data, state.baseCurrency, false),
  }
  return {
    ...state,
    activePriceList: {
      ...payload,
    },
    activeForm: {
      ...newActiveForm,
      hasChanges: hasChanges(globalForm, lineItemsForm),
    },
  }
}

export function parsePriceList(priceList) {
  const options = {
    defaultData: getDefaultPriceList(),
    fields: initialState.fields,
    dataSetName,
  }
  return parse(priceList, options)
}

// items parsing functions
export function parseLineItem(item, defaultUnits) {
  const options = {
    fields: initialState.itemFields,
    dataSetName: 'priceListItem',
    defaultUnits,
  }
  return parse(item, options)
}

function parseLineCount(priceList) {
  return (priceList.lineItems || []).length
}

function getUnit(item = {}, options = {}) {
  const dimension = item.dimension_to_display
  const defaultUnits = options.defaultUnits || {}

  return item.measure_unit || item.template_selling_unit || item.template_measure_unit || defaultUnits[dimension]
}

function parseTemplateSellingPrice(item = {}, options) {
  const dimension = item.dimension_to_display
  const sellingPrice = item.template_unit_selling_price || 0
  return convertFromDollarPerBase(dimension, sellingPrice, getTemplateUnit(item, options)) || 0
}

function getTemplateUnit(item = {}, options = {}) {
  const dimension = item.dimension_to_display
  const defaultUnits = options.defaultUnits || {}

  return item.template_selling_unit || item.template_measure_unit || defaultUnits[dimension]
}

// form parsing functions
function buildLineItemsFormState(state, lineItems) {
  return {
    ...state,
    activeForm: {
      ...state.activeForm,
      hasChanges: hasChanges( state.activeForm.global, lineItems ),
      lineItems,
    },
  }
}

function buildInitialLineItemsFormState(lineItems = []) {
  return {
    selections: [],
    insertions: {},
    updates: buildLineItemsFormData(lineItems),
    deletions: {},
  }
}

function buildLineItemsFormData(lineItems = []) {
  const updates = {}

  lineItems.forEach((lineItem) => {
    updates[lineItem.id] = dataToFormData(lineItem, getItemFields(true))
    updates[lineItem.id].isGlobalChange = false
  })

  return updates
}

function hasChanges(global, lineItems) {
  return Object.keys(global).some((key) => global[key].isChanged) ||
    Object.keys(lineItems.updates).some((key) => lineItems.updates[key].isGlobalChange) ||
    Object.keys(lineItems.insertions).length > 0 ||
    Object.keys(lineItems.deletions).length > 0
}

function getDefaultPriceList() {
  return {
    ...parse({}, { fields }),
  }
}

function getDefaultForm() {
  return {
    isCreate: false,
    hasChanges: false,
    isValid: false,
    resetCount: 0,
    global: dataToFormData(getDefaultPriceList(), getFields(true)),
    lineItems: buildInitialLineItemsFormState(),
    currencyInfo: getDefaultCurrency(),
  }
}

function isFormValid(form) {
  return !!form.name.value && !!form.currencyId.value
}

export function getPriceListTitle(priceList) {
  return priceList.name
}

export function getPriceListItemTitle(priceListItem) {
  return priceListItem.templateDescription
}
