import { v4 as uuid } from 'uuid'

import { buildGetUrl } from 'utils/api'
import { calculateCostWithDiscount } from 'utils/costCalculator'
import { dateToStringWithoutTimezone } from 'utils/dateParser'
import { getFirstString } from 'utils/getFirstOfType'
import {
  buildDictionary,
  dataToFormData,
  formDataToArray,
  formKeyDataToObject,
  dataToDbData,
  mergeUnchangedFormData,
} from 'utils/mapperHelper'
import { parseError, safeFetch, safeFetchJson } from 'utils/safeFetch'

import { getFields as getAddressFields } from 'reducers/addresses/shared'
import { parseCurrency, fetchCurrencies as _fetchCurrencies } from 'reducers/currencies/currenciesSlice'
import { _fetchItemByIds as fetchItemTemplateByIds } from 'reducers/items/itemsSlice'
import { fetchPriceListsByIds } from 'reducers/price-lists/priceListsSlice'
import {
  buildReportingTagsFormState,
  buildReportingTagsState,
  buildReportingTagsFormStateWithDeletions,
  formatReportingTagsForSaving,
  getDefaultReportingTagsForm,
  getReportingTagFields,
} from 'reducers/reporting-tags/reportingTagAssociationSlice'

import {
  fields,
  lineItemFields,
  dataSetName,
  defaultSalesOrderMapData,
  getFields,
  getLineItemFields,
  getGroupFields,
  getDefaultSalesOrder,
  parseSalesOrder,
  parseSalesOrderGroup,
  parseTotalPrice,
  parseTaxObj,
  parseItemTaxObj,
  _fetchSalesOrder,
  parseSalesOrderLineItem,
} from './shared'
import {
  GET_SALES_ORDERS_COUNT,
  GET_SALES_ORDERS,
  GET_SALES_ORDER,
  CLEAR_SALES_ORDER,
  DUPLICATE_SALES_ORDER,
  CREATE_SALES_ORDER,
  UPDATE_SALES_ORDER,
  DELETE_SALES_ORDER,
  SET_IS_CREATE,
  SET_GLOBAL_FORM,
  SET_LINE_ITEMS_FORM,
  DELETE_LINE_ITEMS_FROM_SELECTION,
  RESET_FORM,
  SET_CUSTOMER_DETAILS,
  GET_CURRENCIES,
  SET_BILL_TO_FORM,
  SET_SHIP_TO_FORM,
  DUPLICATE_LINE_ITEMS_FROM_SELECTION,
  SET_SO_ITEM_REPORTING_TAGS_FORM,
  UPDATE_SALES_ORDER_ITEMS,
  INIT_SO_ITEM_REPORTING_TAGS_FORM,
  DELETE_SO_ITEM_REPORTING_TAGS_FROM_SELECTION,
  SET_SO_ITEM_REPORTING_TAGS_FORM_SELECTION,
  UPDATE_ACTIVE_SALES_ORDER_STATUS,
  UPDATE_SALES_ORDERS,
  SET_TAXES,
  UPDATE_CREATING_SALES_ORDER_ITEMS,
} from './types/actions'

const itemEntityName = 'sales-order-items'

const initialState = {
  dataSetName,
  fields,
  salesOrdersCount: 0,
  salesOrders: [],
  activeSalesOrder: getDefaultSalesOrder(),
  activeForm: getDefaultForm(),
  reportingTagsForm: getDefaultReportingTagsForm(itemEntityName),
  itemFields: lineItemFields,
  groupFields: getGroupFields(),
  reportingTagFields: getReportingTagFields(itemEntityName),
  currencies: [],
  baseCurrency: {},
  duplicateSalesOrder: null,
  taxDict: {},
}

export default function salesOrdersReducer(state = initialState, action) {
  const { payload } = action
  switch (action.type) {
  case GET_SALES_ORDERS_COUNT: {
    return {
      ...state,
      salesOrdersCount: payload,
    }
  }
  case GET_SALES_ORDERS: {
    return {
      ...state,
      salesOrders: payload,
    }
  }
  case GET_SALES_ORDER: {
    return buildSalesOrderState(state, payload.salesOrder, payload.keepSelection)
  }
  case CREATE_SALES_ORDER: {
    return buildSalesOrderState(state, payload)
  }
  case UPDATE_SALES_ORDER: {
    return buildSalesOrderState(state, payload)
  }
  case UPDATE_SALES_ORDERS: {
    const newState = { ...state }
    payload.forEach((salesOrder) => {
      const salesOrderIndex = newState.salesOrders.findIndex((so) => so.id === salesOrder.id)
      if (salesOrderIndex !== -1) {
        newState.salesOrders[salesOrderIndex] = { ...newState.salesOrders[salesOrderIndex], ...salesOrder }
      }
    })
    return newState
  }
  case UPDATE_ACTIVE_SALES_ORDER_STATUS: {
    const newState = { ...state }
    const payloadActiveSalesOrder = payload.find((salesOrder) => salesOrder.id === newState.activeSalesOrder.id)
    if (payloadActiveSalesOrder) {
      newState.activeSalesOrder = {
        ...newState.activeSalesOrder,
        status: payloadActiveSalesOrder.status,
        fulfillmentStatus: payloadActiveSalesOrder.fulfillmentStatus,
        invoiceStatus: payloadActiveSalesOrder.invoiceStatus,
        paymentStatus: payloadActiveSalesOrder.paymentStatus,
      }
    }
    return newState
  }
  case CLEAR_SALES_ORDER: {
    return {
      ...state,
      activeSalesOrder: getDefaultSalesOrder(),
      activeForm: getDefaultForm(),
    }
  }
  case DUPLICATE_SALES_ORDER: {
    const isSales = state.activeSalesOrder.projectType === 'sales'
    return {
      ...state,
      duplicateSalesOrder: JSON.parse(JSON.stringify({
        ...state.activeSalesOrder,
        projectId: !isSales ? state.activeSalesOrder.projectId : null,
        projectType: !isSales ? state.activeSalesOrder.projectType : null,
        projectName: !isSales ? state.activeSalesOrder.projectName : null,
      })),
    }
  }
  case GET_CURRENCIES: {
    const baseCurrency = payload.find((currency) => currency.isBase)
    let currencyInfo = state.activeForm.currencyInfo
    if (!currencyInfo.currencyCode) {
      currencyInfo = parseCurrency(
        state.activeSalesOrder,
        state.activeForm.global.customerId.details || state.activeSalesOrder,
        baseCurrency,
        true,
      )
    }

    return {
      ...state,
      currencies: payload,
      baseCurrency,
      activeForm: {
        ...state.activeForm,
        currencyInfo,
      },
    }
  }
  case SET_IS_CREATE: {
    const globalForm = state.duplicateSalesOrder ?
      dataToFormData(state.duplicateSalesOrder, getFields(true, true), true) :
      dataToFormData(getDefaultSalesOrder(), getFields(true))

    globalForm.salesOrderDate = {
      ...globalForm.salesOrderDate,
      value: dateToStringWithoutTimezone(undefined, true),
      isChanged: true,
    }

    const lineItemsForm = state.duplicateSalesOrder ? buildInitialLineItemsFormState(
      state.duplicateSalesOrder.lineItems.filter((lineItem) => lineItem.isGroup || !!lineItem.templateId),
      true,
    ) : state.activeForm.lineItems

    const billToForm = state.duplicateSalesOrder ? dataToFormData(
      state.duplicateSalesOrder.billTo,
      getAddressFields(true),
      true,
    ) : state.activeForm.billTo

    const shipToForm = state.duplicateSalesOrder ? dataToFormData(
      state.duplicateSalesOrder.shipTo,
      getAddressFields(true),
      true,
    ) : state.activeForm.shipTo

    const currencyInfo = state.duplicateSalesOrder ? parseCurrency(
      state.duplicateSalesOrder,
      undefined,
      state.baseCurrency,
    ) : getDefaultCurrencyInfo()

    const subtotal = getSubtotalFromForm(lineItemsForm, state.activeSalesOrder.lineItems, payload.maxDigits)
    const taxObj = getTaxObjFromForm(
      lineItemsForm,
      state.activeSalesOrder.lineItems,
      { maxDigits: payload.maxDigits, taxDict: state.taxDict },
    )
    const activeSalesOrder = state.duplicateSalesOrder ? {
      ...state.duplicateSalesOrder,
      subtotal,
      taxObj,
      total: subtotal + (+taxObj.total || 0),
    } : state.activeSalesOrder

    const newActiveForm = {
      ...state.activeForm,
      isCreate: payload.isCreate,
      global: globalForm,
      lineItems: lineItemsForm,
      billTo: billToForm,
      shipTo: shipToForm,
      isValid: isFormValid(globalForm, activeSalesOrder, lineItemsForm),
      currencyInfo,
    }

    return {
      ...state,
      duplicateSalesOrder: null,
      activeSalesOrder,
      activeForm: {
        ...newActiveForm,
        hasChanges: hasChanges(newActiveForm),
      },
    }
  }
  case SET_GLOBAL_FORM: {
    return {
      ...state,
      activeForm: {
        ...state.activeForm,
        hasChanges: hasChanges({ ...state.activeForm, global: payload }),
        isValid: isFormValid(payload, state.activeSalesOrder, state.activeForm.lineItems),
        global: payload,
      },
    }
  }
  case SET_LINE_ITEMS_FORM: {
    return buildLineItemsFormState(state, payload.lineItems, payload.maxDigits, state.taxDict)
  }
  case DELETE_LINE_ITEMS_FROM_SELECTION: {
    const newInsertions = { ...state.activeForm.lineItems.insertions }
    const newDeletions = { ...state.activeForm.lineItems.deletions }
    state.activeForm.lineItems.selections.forEach((selection) => {
      if (newInsertions[selection.id]) {
        delete newInsertions[selection.id]
      } else {
        newDeletions[selection.id] = selection
      }
    })

    const newGroupInsertions = { ...state.activeForm.lineItems.groupInsertions }
    const newGroupDeletions = { ...state.activeForm.lineItems.groupDeletions }
    state.activeForm.lineItems.groupSelections.forEach((selection) => {
      if (newGroupInsertions[selection.id]) {
        delete newGroupInsertions[selection.id]
      } else {
        newGroupDeletions[selection.id] = selection
      }
    })

    return buildLineItemsFormState(state, {
      ...state.activeForm.lineItems,
      selections: [],
      insertions: newInsertions,
      deletions: newDeletions,
      groupSelections: [],
      groupInsertions: newGroupInsertions,
      groupDeletions: newGroupDeletions,
    },
    payload.maxDigits,
    state.taxDict,
    )
  }
  case SET_BILL_TO_FORM: {
    return {
      ...state,
      activeForm: {
        ...state.activeForm,
        hasChanges: hasChanges({ ...state.activeForm, billTo: payload }),
        billTo: payload,
      },
    }
  }
  case SET_SHIP_TO_FORM: {
    return {
      ...state,
      activeForm: {
        ...state.activeForm,
        hasChanges: hasChanges({ ...state.activeForm, shipTo: payload }),
        shipTo: payload,
      },
    }
  }
  case SET_CUSTOMER_DETAILS: {
    const customerDetails = payload.customerDetails
    const currencyInfo = parseCurrency(
      state.activeSalesOrder,
      customerDetails,
      state.baseCurrency,
      true,
    )

    const newGlobalForm = {
      ...state.activeForm.global,
      customerId: {
        ...state.activeForm.global.customerId,
        value: customerDetails.id,
        isChanged: true,
        details: customerDetails,
      },
      contactPersonId: {
        ...state.activeForm.global.contactPersonId,
        value: customerDetails.contactPerson?.id,
        isChanged: true,
      },
      carrierId: {
        ...state.activeForm.global.carrierId,
        value: customerDetails?.carrierId,
        isChanged: true,
      },
    }
    const newLineItemsForm = {
      ...state.activeForm.lineItems,
      insertions: payload.insertions,
      updates: payload.updates,
    }
    const billToForm = dataToFormData(customerDetails.billingAddress, getAddressFields(true), true)
    const shipToForm = dataToFormData(customerDetails.shippingAddress, getAddressFields(true), true)

    const subtotal = getSubtotalFromForm(newLineItemsForm, state.activeSalesOrder.lineItems, payload.maxDigits)
    const taxObj = getTaxObjFromForm(
      newLineItemsForm,
      state.activeSalesOrder.lineItems,
      { maxDigits: payload.maxDigits, taxDict: state.taxDict },
    )

    return {
      ...state,
      activeForm: {
        ...state.activeForm,
        hasChanges: hasChanges({ ...state.activeForm, global: newGlobalForm, lineItems: newLineItemsForm }),
        isValid: isFormValid(newGlobalForm, state.activeSalesOrder, newLineItemsForm),
        global: newGlobalForm,
        lineItems: newLineItemsForm,
        billTo: billToForm,
        shipTo: shipToForm,
        currencyInfo,
      },
      activeSalesOrder: {
        ...state.activeSalesOrder,
        subtotal,
        taxObj,
        total: subtotal + (+taxObj.total || 0),
      },
    }
  }
  case RESET_FORM: {
    const globalForm = dataToFormData(
      state.activeForm.isCreate ? getDefaultSalesOrder() : state.activeSalesOrder,
      getFields(true),
    )
    const lineItemsForm = buildInitialLineItemsFormState(
      state.activeForm.isCreate ? undefined : state.activeSalesOrder.lineItems,
    )
    const billToForm = dataToFormData(
      state.activeForm.isCreate ? {} : state.activeSalesOrder.billTo,
      getAddressFields(true),
    )
    const shipToForm = dataToFormData(
      state.activeForm.isCreate ? {} : state.activeSalesOrder.shipTo,
      getAddressFields(true),
    )
    const currencyInfo = state.activeForm.isCreate ? getDefaultCurrencyInfo() : parseCurrency(
      state.activeSalesOrder,
      globalForm.customerId.details || state.activeSalesOrder,
      state.baseCurrency,
      true,
    )
    const subtotal = getSubtotalFromForm(lineItemsForm, state.activeSalesOrder.lineItems, payload.maxDigits)
    const taxObj = getTaxObjFromForm(
      lineItemsForm,
      state.activeSalesOrder.lineItems,
      { maxDigits: payload.maxDigits, taxDict: state.taxDict },
    )

    const newActiveForm = {
      ...state.activeForm,
      billTo: billToForm,
      shipTo: shipToForm,
      isValid: isFormValid(globalForm, state.activeSalesOrder, lineItemsForm),
      resetCount: state.activeForm.resetCount + 1,
      global: globalForm,
      lineItems: lineItemsForm,
      currencyInfo,
    }

    return {
      ...state,
      activeSalesOrder: {
        ...state.activeSalesOrder,
        subtotal,
        taxObj,
        total: subtotal + (+taxObj.total || 0),
      },
      activeForm: {
        ...newActiveForm,
        hasChanges: hasChanges(newActiveForm),
      },
    }
  }
  case DUPLICATE_LINE_ITEMS_FROM_SELECTION: {
    const newInsertions = { ...state.activeForm.lineItems.insertions }
    const itemFields = getLineItemFields(false, false)
    let highestRankValue = Math.max(
      ...Object.keys(state.activeForm.lineItems.updates)
        .map((id) => state.activeForm.lineItems.updates[id].rank.value),
    )
    state.activeForm.lineItems.selections.forEach((selection) => {
      highestRankValue = highestRankValue + 1
      const newInsertionId = uuid()
      newInsertions[newInsertionId] = dataToFormData(
        { ...selection, id: newInsertionId, rank: highestRankValue },
        itemFields,
        false,
        { forceIsChangedWhenNotEmpty: true },
      )

      const toDuplicate = (
        state.activeForm.lineItems.insertions[selection.id] ||
        state.activeForm.lineItems.updates[selection.id] ||
        {}
      )
      Object.keys(toDuplicate)
        .filter((key) => toDuplicate[key].isChanged)
        .forEach((key) => {
          newInsertions[newInsertionId][key] = { ...toDuplicate[key] }
        })
    })
    return buildLineItemsFormState(
      state,
      { ...state.activeForm.lineItems, selections: [], insertions: newInsertions },
      payload.maxDigits,
      state.taxDict,
    )
  }
  case UPDATE_SALES_ORDER_ITEMS: {
    const salesOrderItems = payload?.salesOrderItems?.filter((salesOrderItem) => !!salesOrderItem?.id) || []
    salesOrderItems.forEach((payloadLineItem) => {
      const formLineItem = state.activeForm.lineItems.updates[payloadLineItem.id]
      if (formLineItem) {
        const changedValues = formKeyDataToObject(formLineItem, { isDatabase: false, onlyChanged: true })
        const newLineItem = payload.keepUserChanges ? { ...payloadLineItem, ...changedValues } : payloadLineItem
        const formData = dataToFormData(newLineItem, getLineItemFields(true, false), false)
        if (payload.keepUserChanges) {
          const changedKeys = Object.keys(changedValues)
          changedKeys.forEach((changedKey) => {
            formData[changedKey].isChanged = true
          })
          formData.isGlobalChange = changedKeys.length
        }
        // TODO (bzoretic) / TODO (lleduc): Could be improved. We currently need to force
        // a change of reference on lineItems so that the useMemos DataHandlerMeasureTemplate.formData,
        // DataHandlerDescriptionTemplate.formData and DataHandlerUnitPriceTemplate.formData are triggered.
        // Can't just change the dependancies of the useMemos because other places only trigger a reference
        // change on lineItems.
        state.activeForm.lineItems = {
          ...state.activeForm.lineItems,
          updates: {
            ...state.activeForm.lineItems.updates,
            [payloadLineItem.id]: formData,
          },
        }
      }
    })

    salesOrderItems.forEach((payloadLineItem) => {
      state.activeSalesOrder.lineItems = state.activeSalesOrder.lineItems.map((lineItem) => {
        if (lineItem.id === payloadLineItem.id) {
          return payloadLineItem
        }
        return lineItem
      })
    })
    return state
  }
  case UPDATE_CREATING_SALES_ORDER_ITEMS: {
    const updatedInsertions = {}
    const lineItemFieldKeys = Object.keys(lineItemFields)
    const fieldMapper = {
      unitPrice: 'unitSellingPrince',
      discount: 'vendorDiscount',
      conversionFactor: 'conversionFactor',
      initialConversionFactor: 'initialConversionFactor',
    }

    payload.forEach((item) => {
      const targetInsertions = Object.values(state.activeForm.lineItems.insertions).filter((insertion) => {
        return insertion.templateId.value === item.id
      })

      targetInsertions.forEach((currentInsertion) => {
        const lineItemData = templateToLineItem(
          currentInsertion.id.value,
          item,
          lineItemFieldKeys,
          currentInsertion.rank.value,
          fieldMapper,
        )

        const templateToLineItemFormData = dataToFormData(lineItemData, lineItemFields)

        updatedInsertions[currentInsertion.id.value] = mergeUnchangedFormData(
          currentInsertion,
          templateToLineItemFormData,
        )

        updatedInsertions[currentInsertion.id.value].discounted = {
          ...currentInsertion.discounted,
          value: calculateCostWithDiscount(
            updatedInsertions[currentInsertion.id.value].unitPrice.value,
            updatedInsertions[currentInsertion.id.value].discount.value,
            false,
          ),
        }
      })
    })

    return {
      ...state,
      activeForm: {
        ...state.activeForm,
        lineItems: {
          ...state.activeForm.lineItems,
          insertions: updatedInsertions,
        },
      },
    }
  }
  case SET_SO_ITEM_REPORTING_TAGS_FORM: {
    return buildReportingTagsFormState(state, payload)
  }
  case SET_TAXES: {
    return { ...state, taxDict: payload }
  }
  case INIT_SO_ITEM_REPORTING_TAGS_FORM: {
    return buildReportingTagsState(state, payload, itemEntityName)
  }
  case SET_SO_ITEM_REPORTING_TAGS_FORM_SELECTION: {
    return buildReportingTagsFormState(state, { ...state.reportingTagsForm.reportingTags, selections: payload })
  }
  case DELETE_SO_ITEM_REPORTING_TAGS_FROM_SELECTION: {
    return buildReportingTagsFormStateWithDeletions(state)
  }
  default: {
    return state
  }
  }
}

export function fetchSalesOrdersCount(data) {
  return async function fetchSalesOrdersCountThunk(dispatch) {
    try {
      const result = await (await safeFetch(buildGetUrl(
        '/new_api/sales-orders/count',
        { ...data, ...defaultSalesOrderMapData },
      ))).json()
      if (result.isSuccess) {
        const count = +result.result[0].count || 0
        dispatch({ type: GET_SALES_ORDERS_COUNT, payload: count })
        return count
      }

      return 0
    } catch (err) {
      console.error(err)
      return 0
    }
  }
}

export function fetchSalesOrdersCardsCount(ids) {
  return async function fetchSalesOrdersCardsCountThunk(dispatch) {
    const data = {
      salesOrdersIds: ids,
      onlyCards: true,
    }
    return _fetchSalesOrdersCardsCount(data)
  }
}

export async function _fetchSalesOrdersCardsCount(data) {
  try {
    const result = await (await safeFetch(buildGetUrl(
      '/new_api/sales-orders/items/count',
      { ...data, ...defaultSalesOrderMapData },
    ))).json()
    if (result.isSuccess) {
      const count = +result.result[0].count || 0
      return count
    }
  } catch (err) {
    console.error(err)
    return 0
  }
}

export function fetchSalesOrders(data, mapData) {
  return async function fetchSalesOrdersThunk(dispatch) {
    const salesOrders = await _fetchSalesOrders(data, mapData)
    dispatch({
      type: GET_SALES_ORDERS,
      payload: salesOrders,
    })
    return salesOrders
  }
}

export async function _fetchSalesOrders(data, mapData = {}) {
  let salesOrders = []

  try {
    const result = await (await safeFetch(buildGetUrl('/new_api/sales-orders', {
      ...data,
      excludeItems: true,
      calculateStatuses: true,
      includeTaxDetails: true,
    }))).json()
    if (result.isSuccess) {
      salesOrders = result.result.map((salesOrder) => parseSalesOrder(salesOrder, mapData))
    }
  } catch (err) {
    console.error(err)
  }

  return salesOrders
}

export function fetchSalesOrder(salesOrderId, mapData, keepSelection = false) {
  return async function fetchSalesOrderThunk(dispatch, getState) {
    if (salesOrderId === 'new') {
      const maxDigits = getState().settings.global.number_decimals
      dispatch({ type: SET_IS_CREATE, payload: { isCreate: true, maxDigits } })
      return null
    } else {
      const salesOrder = await _fetchSalesOrder(salesOrderId, mapData)
      dispatch({ type: GET_SALES_ORDER, payload: { salesOrder, keepSelection } })
      return salesOrder
    }
  }
}

export function getSalesOrderTitle(entityData) {
  return entityData.name
}

export function fetchSalesOrderByIds(ids, data, mapData) {
  return async function(dispatch) {
    const salesOrders = await _fetchSalesOrderByIds(ids, data, mapData)
    dispatch({ type: UPDATE_ACTIVE_SALES_ORDER_STATUS, payload: salesOrders })
    dispatch({ type: UPDATE_SALES_ORDERS, payload: salesOrders })
    return salesOrders
  }
}

export async function _fetchSalesOrderByIds(ids, data, mapData) {
  if (!ids?.length) return []

  const { isSuccess, result } = await safeFetchJson(
    buildGetUrl(`/new_api/sales-orders/${ids.join(',')}`, { ...data, includeTaxDetails: true }))

  return isSuccess ? result.map((salesOrder) => parseSalesOrder(salesOrder, mapData)) : []
}

export function saveReportingTags(salesOrderItemId, mapData) {
  return async function saveReportingTagsThunk(dispatch, getState) {
    const reportingTagsForm = getState().salesOrders.reportingTagsForm
    const formattedReportingTags = formatReportingTagsForSaving(itemEntityName, reportingTagsForm.reportingTags)

    const salesOrderItem = {
      id: salesOrderItemId,
      reportingTags: formattedReportingTags,
    }

    return _updateSalesOrderItem(dispatch, salesOrderItem, mapData)
  }
}

/**
 * Take all the data from customerConsignmentsForm and format it in a savable format.
 */
function _getCustomerConsignmentForm(insertions) {
  return Object.keys(insertions).reduce((acc, id) => {
    const customServiceConfiguration = insertions[id].customServiceConfiguration
    if (customServiceConfiguration.isChanged) {
      const customerConsignmentInsertion =
        customServiceConfiguration.value.customerConsignmentsForm?.value?.insertions || {}
      if (Object.keys(customerConsignmentInsertion).length) {
        const formatedData = formDataToArray(customerConsignmentInsertion, true, true, false)
        // sales_order_item_id was temporary on insertion and won't be the final sales order item id used by backend
        formatedData.forEach((customerConsignment) => delete customerConsignment.sales_order_item_id)
        acc[id] = formatedData
      }
    }
    return acc
  }, {})
}

export function saveSalesOrder(setPage, mapData) {
  return async function saveSalesOrderThunk(dispatch, getState) {
    const salesOrdersStore = getState().salesOrders
    const globalFormData = formKeyDataToObject(salesOrdersStore.activeForm.global)
    const customerConsignmentFormDict = _getCustomerConsignmentForm(salesOrdersStore.activeForm.lineItems.insertions)
    const insertions = formDataToArray(
      salesOrdersStore.activeForm.lineItems.insertions,
      true,
      true,
      true,
      undefined,
      lineItemFields,
    ).map((insertion) => {
      const _insertion = { ...insertion }
      delete _insertion.id
      if (insertion.customServiceConfiguration) {
        _insertion.customServiceConfiguration = {
          ...insertion.customServiceConfiguration,
          customerConsignments: customerConsignmentFormDict[insertion.id] ?? undefined,
        }
      }
      return _insertion
    })
    const groupInsertions = formDataToArray(salesOrdersStore.activeForm.lineItems.groupInsertions, true, true, false)
    const billToFormData = formKeyDataToObject(salesOrdersStore.activeForm.billTo)
    const shipToFormData = formKeyDataToObject(salesOrdersStore.activeForm.shipTo)
    if (salesOrdersStore.activeForm.isCreate) {
      setPage((page) => ({ ...page, isCreating: true }))
      const salesOrder = {
        ...globalFormData,
        lineItems: insertions,
        groups: groupInsertions,
        bill_to_address: billToFormData,
        ship_to_address: shipToFormData,
      }
      return _createSalesOrder(dispatch, salesOrder, mapData)
    } else {
      const updates = { ...salesOrdersStore.activeForm.lineItems.updates }
      Object.keys(updates)
        .filter((key) => !updates[key].isGlobalChange)
        .forEach((key) => delete updates[key])

      const groupUpdates = { ...salesOrdersStore.activeForm.lineItems.groupUpdates }
      Object.keys(groupUpdates)
        .filter((key) => !groupUpdates[key].isGlobalChange)
        .forEach((key) => delete groupUpdates[key])

      const salesOrder = {
        id: salesOrdersStore.activeSalesOrder.id,
        ...globalFormData,
        lineItems: {
          insertions,
          updates: formDataToArray(
            updates,
            undefined,
            undefined,
            undefined,
            undefined,
            lineItemFields,
          ),
          deletions: Object.keys(salesOrdersStore.activeForm.lineItems.deletions),
        },
        groups: {
          insertions: groupInsertions,
          updates: formDataToArray(groupUpdates),
          deletions: Object.keys(salesOrdersStore.activeForm.lineItems.groupDeletions),
        },
        bill_to_address: billToFormData,
        ship_to_address: shipToFormData,
      }

      return _updateSalesOrder(dispatch, salesOrder, mapData, salesOrdersStore.activeForm.global.customerId?.details)
    }
  }
}

async function _createSalesOrder(dispatch, salesOrder, mapData) {
  const isCreateSalesProject = salesOrder.project_id === '_insert'
  if (salesOrder.project_id === '_insert') {
    delete salesOrder.project_id
  }
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ salesOrder, isCreateSalesProject }),
  }

  try {
    const result = await (await safeFetch(`/new_api/sales-orders`, requestOptions)).json()
    const [created] = result.isSuccess ? result.result : []
    const payload = created ? parseSalesOrder(created, mapData) : null
    const error = !result.isSuccess ? result.result : null
    dispatch({ type: CREATE_SALES_ORDER, payload, error })

    return { isCreate: true, salesOrder: payload }
  } catch (error) {
    dispatch({ type: CREATE_SALES_ORDER, error })
  }
}

export function updateSalesOrder(salesOrder, mapData, customerDetails) {
  return async function updateSalesOrderThunk(dispatch) {
    await _updateSalesOrder(dispatch, salesOrder, mapData, customerDetails)
  }
}

async function _updateSalesOrder(dispatch, salesOrder, mapData, customerDetails) {
  const isCreateSalesProject = salesOrder.project_id === '_insert'
  if (salesOrder.project_id === '_insert') {
    delete salesOrder.project_id
  }

  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ salesOrder, isCreateSalesProject }),
  }

  try {
    const result = await (await safeFetch(`/new_api/sales-orders/${salesOrder.id}`, requestOptions)).json()
    const [updated] = result.isSuccess ? result.result : []
    const payload = updated ? { ...parseSalesOrder(updated, mapData), customerDetails } : null
    const error = !result.isSuccess ? result.result : null
    dispatch({ type: UPDATE_SALES_ORDER, payload, error })

    return { salesOrder: payload }
  } catch (error) {
    dispatch({ type: UPDATE_SALES_ORDER, error })
  }
}

async function _updateSalesOrderItem(dispatch, salesOrderItem, mapData) {
  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ salesOrderItem }),
  }

  try {
    const result = await (await safeFetch(`/new_api/sales-orders/items/${salesOrderItem.id}`, requestOptions)).json()
    const [updated] = result.isSuccess ? result.result : []
    const payload = updated ? {
      ...parseSalesOrderLineItem(
        updated,
        {
          defaultUnits: mapData?.defaultUnits,
          isPrimaryLanguage: mapData?.isPrimaryLanguage,
          isDocumentPrimaryLanguage: mapData?.isDocumentPrimaryLanguage,
          taxDict: mapData?.taxDict,
          priceMaxDigits: mapData?.priceMaxDigits,
          measureMaxDigits: mapData?.measureMaxDigits,
        },
      ),
    } : null
    const error = !result.isSuccess ? result.result : null
    const salesOrderItems = payload ? [payload] : null
    dispatch({ type: UPDATE_SALES_ORDER_ITEMS, payload: { salesOrderItems, keepUserChanges: false }, error })
    return { isSuccess: result.isSuccess, result: payload }
  } catch (error) {
    dispatch({ type: UPDATE_SALES_ORDER_ITEMS, error })
    return { isSuccess: false, result: error }
  }
}

export function duplicateSalesOrder(dispatch) {
  dispatch({ type: DUPLICATE_SALES_ORDER })
}

export function deleteSalesOrder(salesOrderId) {
  return async function deleteSalesOrderThunk(dispatch) {
    try {
      const result = await (await safeFetch(`/new_api/sales-orders/${salesOrderId}`, { method: 'DELETE' })).json()
      const error = !result.isSuccess ? result.result : null
      dispatch({ type: DELETE_SALES_ORDER, payload: result.isSuccess, error })

      return result
    } catch (error) {
      dispatch({ type: DELETE_SALES_ORDER, error })
    }
  }
}

export async function fetchNextNumber() {
  let nextNumber = ''

  try {
    const result = await (await safeFetch('/new_api/sales-orders/next-number')).json()
    if (result.isSuccess) {
      nextNumber = result.result
    }
  } catch (err) {
    console.error(err)
  }

  return nextNumber
}

export function reserve(plannedLedgers, salesOrderId, mapData) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ plannedLedgers }),
  }

  return async function reserveThunk(dispatch) {
    try {
      const result = await (await safeFetch(`/new_api/planned-ledgers`, requestOptions)).json()
      const salesOrder = result.isSuccess ? await _fetchSalesOrder(salesOrderId, mapData) : null
      const error = !result.isSuccess ? result.result : null
      dispatch({ type: UPDATE_SALES_ORDER, payload: salesOrder, error })
    } catch (error) {
      dispatch({ type: UPDATE_SALES_ORDER, error })
    }
  }
}

export function autoReserve(ids, salesOrderId, mapData) {
  return async function autoReserveThunk(dispatch) {
    try {
      const result = await (await safeFetch(
        `/new_api/planned-ledgers/force-reserve/sales-order-items/${ids}`,
        { method: 'POST' },
      )).json()
      const salesOrder = result.isSuccess ? await _fetchSalesOrder(salesOrderId, mapData) : undefined
      dispatch({ type: UPDATE_SALES_ORDER, payload: salesOrder })
      return result
    } catch (error) {
      dispatch({ type: UPDATE_SALES_ORDER })
      return parseError(error)
    }
  }
}

export async function invoice(data, lineItems, companyId) {
  try {
    if (!data.selectedInvoice.isCreate) {
      const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ invoice: { line_items: { insertions: lineItems } } }),
      }

      return await (await safeFetch(`/api/integrations/invoices/${data.selectedInvoice.id}`, requestOptions)).json()
    } else {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          invoice: {
            company_id: companyId,
            name: data.invoiceNumber || undefined,
            is_external: data.pushToZohoBooks,
            invoice_date: data.invoiceDate,
            line_items: lineItems,
            config: {
              payment_gateways: data.useStripe ? ['stripe'] : [],
            },
          },
        }),
      }

      return await (await safeFetch(`/api/integrations/invoices`, requestOptions)).json()
    }
  } catch (error) {
    return parseError(error)
  }
}

export async function updateLineItems(lineItems) {
  try {
    const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        salesOrderItems: lineItems,
      }),
    }

    return await (await safeFetch(`/new_api/sales-orders/items/batch`, requestOptions)).json()
  } catch (error) {
    return parseError(error)
  }
}

export function salesOrderToShipmentInfo(salesOrder) {
  return { plant_id: salesOrder.plantId }
}

export function updateSalesOrders(salesOrders) {
  return async function updateSalesOrdersThunk(dispatch) {
    dispatch({ type: GET_SALES_ORDERS, payload: salesOrders })
  }
}

export function clearSalesOrder(dispatch) {
  dispatch({ type: CLEAR_SALES_ORDER })
}

export function fetchCurrencies() {
  return async function fetchCurrenciesThunk(dispatch) {
    const currencies = await _fetchCurrencies()
    dispatch({ type: GET_CURRENCIES, payload: currencies })
  }
}

export function reopenSalesOrder(salesOrderId, mapData = {}) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
  }

  return async function openSalesOrderThunk(dispatch) {
    try {
      const result = await (await safeFetch(`/new_api/sales-orders/${salesOrderId}/reopen`, requestOptions)).json()
      const [updated] = result.isSuccess ? result.result : []
      const payload = updated ? parseSalesOrder(updated, mapData) : null
      const error = !result.isSuccess ? result.result : null
      dispatch({ type: UPDATE_SALES_ORDER, payload, error })
    } catch (error) {
      dispatch({ type: UPDATE_SALES_ORDER, error })
    }
  }
}

export function openSalesOrder(salesOrderId, mapData = {}) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
  }

  return async function openSalesOrderThunk(dispatch) {
    try {
      const result = await (await safeFetch(`/new_api/sales-orders/${salesOrderId}/open`, requestOptions)).json()
      const [updated] = result.isSuccess ? result.result : []
      const payload = updated ? parseSalesOrder(updated, mapData) : null
      const error = !result.isSuccess ? result.result : null
      dispatch({ type: UPDATE_SALES_ORDER, payload, error })
    } catch (error) {
      dispatch({ type: UPDATE_SALES_ORDER, error })
    }
  }
}

export function closeSalesOrder(salesOrderId, mapData = {}) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
  }

  return async function closeSalesOrderThunk(dispatch) {
    try {
      const result = await (await safeFetch(`/new_api/sales-orders/${salesOrderId}/close`, requestOptions)).json()
      const [updated] = result.isSuccess ? result.result : []
      const payload = updated ? parseSalesOrder(updated, mapData) : null
      const error = !result.isSuccess ? result.result : null
      dispatch({ type: UPDATE_SALES_ORDER, payload, error })
    } catch (error) {
      dispatch({ type: UPDATE_SALES_ORDER, error })
    }
  }
}

export function cancelSalesOrder(salesOrderId, mapData = {}) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
  }

  return async function cancelSalesOrderThunk(dispatch) {
    try {
      const result = await (await safeFetch(`/new_api/sales-orders/${salesOrderId}/cancel`, requestOptions)).json()
      const [updated] = result.isSuccess ? result.result : []
      const payload = updated ? parseSalesOrder(updated, mapData) : null
      const error = !result.isSuccess ? result.result : null
      dispatch({ type: UPDATE_SALES_ORDER, payload, error })
    } catch (error) {
      dispatch({ type: UPDATE_SALES_ORDER, error })
    }
  }
}

export function holdSalesOrder(salesOrderId, mapData = {}) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
  }

  return async function holdSalesOrderThunk(dispatch) {
    try {
      const result = await (await safeFetch(`/new_api/sales-orders/${salesOrderId}/hold`, requestOptions)).json()
      const [updated] = result.isSuccess ? result.result : []
      const payload = updated ? parseSalesOrder(updated, mapData) : null
      const error = !result.isSuccess ? result.result : null
      dispatch({ type: UPDATE_SALES_ORDER, payload, error })
    } catch (error) {
      dispatch({ type: UPDATE_SALES_ORDER, error })
    }
  }
}

export function setSalesOrderToQuoteSent(salesOrderId, mapData = {}) {
  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ salesOrder: { status: 'quote_submitted' } }),
  }

  return async function setSalesOrderToQuoteSentThunk(dispatch) {
    try {
      const result = await (await safeFetch(`/new_api/sales-orders/${salesOrderId}`, requestOptions)).json()
      const [updated] = result.isSuccess ? result.result : []
      const payload = updated ? parseSalesOrder(updated, mapData) : null
      const error = !result.isSuccess ? result.result : null
      dispatch({ type: UPDATE_SALES_ORDER, payload, error })
    } catch (error) {
      dispatch({ type: UPDATE_SALES_ORDER, error })
    }
  }
}

export function updateGlobalFormFields(fieldValues) {
  return async function updateGlobalFormFieldsThunk(dispatch, getState) {
    const salesOrdersStore = getState().salesOrders
    const payload = { ...salesOrdersStore.activeForm.global }

    fieldValues.forEach((fieldValue) => {
      payload[fieldValue.field] = {
        ...salesOrdersStore.activeForm.global[fieldValue.field],
        value: fieldValue.value,
        isChanged: true,
      }
    })

    dispatch({ type: SET_GLOBAL_FORM, payload })
  }
}

export function updateGlobalFormQuoteInfo(quoteInfo) {
  return async function updateGlobalFormQuoteInfoThunk(dispatch, getState) {
    const salesOrdersStore = getState().salesOrders
    const newNotes = salesOrdersStore.activeForm.global.quoteNotes.value || quoteInfo?.value?.notes || ''
    const newConditions = salesOrdersStore.activeForm.global.quoteConditions.value || quoteInfo?.value?.conditions || ''
    const payload = {
      ...salesOrdersStore.activeForm.global,
      quoteNotes: {
        ...salesOrdersStore.activeForm.global.quoteNotes,
        value: newNotes,
        isChanged: salesOrdersStore.activeForm.isCreate ||
          newNotes != salesOrdersStore.activeForm.global.quoteNotes.value,
      },
      quoteConditions: {
        ...salesOrdersStore.activeForm.global.quoteConditions,
        value: newConditions,
        isChanged: salesOrdersStore.activeForm.isCreate ||
          newConditions != salesOrdersStore.activeForm.global.quoteConditions.value,
      },
    }
    dispatch({ type: SET_GLOBAL_FORM, payload })
  }
}

export function resetForm(dispatch, getState) {
  const maxDigits = getState().settings.global.number_decimals
  dispatch({ type: RESET_FORM, payload: { maxDigits } })
}

function buildSalesOrderState(state, payload, keepSelection) {
  if (!payload) {
    return state
  }
  let selectionIds
  let groupSelectionIds
  if (keepSelection) {
    selectionIds = state.activeForm.lineItems.selections.map((selection) => selection.id)
    groupSelectionIds = state.activeForm.lineItems.groupSelections.map((selection) => selection.id)
  }
  const globalForm = dataToFormData(payload, getFields(true))
  const lineItemsForm = buildInitialLineItemsFormState(payload.lineItems, false, selectionIds, groupSelectionIds)
  const billToForm = dataToFormData(payload.billTo, getAddressFields(true))
  const shipToForm = dataToFormData(payload.shipTo, getAddressFields(true))
  const newActiveForm = {
    ...state.activeForm,
    isCreate: false,
    isValid: isFormValid(globalForm, payload, lineItemsForm),
    global: globalForm,
    lineItems: lineItemsForm,
    billTo: billToForm,
    shipTo: shipToForm,
    currencyInfo: parseCurrency(payload, globalForm.customerId.details || payload, state.baseCurrency, true),
  }

  return {
    ...state,
    activeSalesOrder: {
      ...payload,
      customerDetails: payload.customerDetails?.id ?
        payload.customerDetails :
        state.activeSalesOrder.customerDetails,
    },
    activeForm: {
      ...newActiveForm,
      hasChanges: hasChanges(newActiveForm),
    },
  }
}

export function updateLineItemsForm(newLineItemsForm) {
  return async function updateLineItemsFormThunk(dispatch, getState) {
    const maxDigits = getState().settings.global.number_decimals
    dispatch({ type: SET_LINE_ITEMS_FORM, payload: { lineItems: newLineItemsForm, maxDigits } })
  }
}

export function deleteFromSelection(dispatch, getState) {
  const maxDigits = getState().settings.global.number_decimals
  dispatch({ type: DELETE_LINE_ITEMS_FROM_SELECTION, payload: { maxDigits } })
}

export function updateCustomerDetails(customerDetails, mapData = {}) {
  return async function updateCustomerDetailsThunk(dispatch, getState) {
    const salesOrderStore = getState().salesOrders
    const [priceList] = customerDetails.priceListId ? await fetchPriceListsByIds([customerDetails.priceListId]) : []

    const currentUpdates = Object.values(salesOrderStore.activeForm.lineItems.updates || {})
    const currentInsertions = Object.values(salesOrderStore.activeForm.lineItems.insertions || {})

    const itemIds = currentInsertions.map((insertion) => insertion.templateId.value)
    const itemDict = buildDictionary(
      await fetchItemTemplateByIds(itemIds, { ...mapData, customerDetails }),
      'id',
    )

    const lineItemFieldKeys = Object.keys(lineItemFields)

    const newInsertions = {}
    const newUpdates = {}

    currentInsertions.forEach((currentInsertion) => {
      const relatedItem = itemDict[currentInsertion.templateId.value]
      const relatedPriceListItem = priceList?.lineItems.find(
        (item) => item.templateId === currentInsertion.templateId.value)

      const lineItemData = templateToLineItem(
        currentInsertion.id.value,
        relatedItem,
        lineItemFieldKeys,
        currentInsertion.rank.value,
      )

      if (relatedPriceListItem) {
        lineItemData.unitPrice = relatedPriceListItem.rate
        lineItemData.quantity = relatedPriceListItem.moq
      }
      lineItemData.taxId = getLineItemTaxId(customerDetails)
      newInsertions[currentInsertion.id.value] = dataToFormData(lineItemData, lineItemFields)
      newInsertions[currentInsertion.id.value].templateId.isChanged = true
      newInsertions[currentInsertion.id.value].rank.isChanged = true
      newInsertions[currentInsertion.id.value].taxId.isChanged = true
    })

    currentUpdates.forEach((currentUpdate) => {
      const relatedPriceListItem = priceList?.lineItems.find(
        (item) => item.templateId === currentUpdate.templateId.value,
      )

      const dataToForm = {
        'taxId': getLineItemTaxId(customerDetails),
        'id': currentUpdate.id.value,
        'unitPrice': relatedPriceListItem ?
          relatedPriceListItem.rate :
          salesOrderStore.activeSalesOrder.lineItems.find((item) => item.id === currentUpdate.id.value).unitPrice,
        'quantity': relatedPriceListItem ?
          relatedPriceListItem.moq :
          salesOrderStore.activeSalesOrder.lineItems.find((item) => item.id === currentUpdate.id.value).quantity,
      }
      const fieldToFormData = {
        id: lineItemFields.id,
        taxId: lineItemFields.taxId,
        unitPrice: lineItemFields.unitPrice,
        quantity: lineItemFields.quantity,
      }

      newUpdates[currentUpdate.id.value] = {
        ...currentUpdate,
        ...dataToFormData(
          dataToForm,
          fieldToFormData,
          true,
        ),
      }
      newUpdates[currentUpdate.id.value].isGlobalChange = true
    })

    const maxDigits = getState().settings.global.number_decimals
    dispatch({ type: SET_CUSTOMER_DETAILS,
      payload: { customerDetails, insertions: newInsertions, updates: newUpdates, maxDigits } })
  }
}

export function updateBillToForm(newBillToForm) {
  return async function updateBillToFormThunk(dispatch, getState) {
    const salesOrdersStore = getState().salesOrders
    const payload = { ...salesOrdersStore.activeForm.billTo, ...newBillToForm }
    dispatch({ type: SET_BILL_TO_FORM, payload })
  }
}

export function updateShipToForm(newShipToForm) {
  return async function updateShipToFormThunk(dispatch, getState) {
    const salesOrdersStore = getState().salesOrders
    const payload = { ...salesOrdersStore.activeForm.shipTo, ...newShipToForm }
    dispatch({ type: SET_SHIP_TO_FORM, payload })
  }
}

function getDefaultForm() {
  return {
    isCreate: false,
    hasChanges: false,
    isValid: false,
    resetCount: 0,
    global: dataToFormData(getDefaultSalesOrder(), getFields(true)),
    lineItems: buildInitialLineItemsFormState(),
    billTo: dataToFormData({}, getAddressFields(true)),
    shipTo: dataToFormData({}, getAddressFields(true)),
    currencyInfo: getDefaultCurrencyInfo(),
  }
}

function getSubtotalFromForm(lineItemsForm, activeLineItems, options) {
  return getLineItemsFormItems(lineItemsForm, activeLineItems).reduce((acc, lineItem) => {
    const totalPricePerLineItem = parseTotalPrice(
      lineItem.unit_price || 0,
      lineItem.dimension_to_display,
      lineItem.measure_unit,
      lineItem.measure || 0,
      lineItem.discount,
      {
        priceMaxDigits: options.price_max,
        measureMaxDigits: options.measure_max,
      },
    )
    return acc + totalPricePerLineItem
  }, 0)
}
function getTaxObjFromForm(lineItemsForm, activeLineItems, options) {
  const lineItems = getLineItemsFormItems(lineItemsForm, activeLineItems).map((lineItem) => {
    return {
      taxObj: parseItemTaxObj(lineItem, {
        taxDict: options.taxDict,
        priceMaxDigits: options.maxDigits.price_max,
        measureMaxDigits: options.maxDigits.measure_max,
      }),
    }
  })
  return parseTaxObj(lineItems)
}

export function getLineItemsFormItems(lineItemsForm, activeLineItems) {
  const toObjectOptions = { skipUpdateDbField: true, onlyChanged: false, isDatabaseNull: false }
  const deletionIds = Object.keys(lineItemsForm.deletions)
  const insertions = Object.keys(lineItemsForm.insertions).map((id) =>
    formKeyDataToObject(lineItemsForm.insertions[id], toObjectOptions))
  const updates = Object.keys(lineItemsForm.updates)
    .filter((id) => !deletionIds.includes(id))
    .map((id) => {
      return {
        ...(dataToDbData(activeLineItems.find((lineItem) => lineItem.id === id), getLineItemFields(false), true) || {}),
        ...formKeyDataToObject(lineItemsForm.updates[id], toObjectOptions),
      }
    })
  return [...insertions, ...updates]
}

function buildLineItemsFormState(state, lineItems, maxDigits, taxDict) {
  const subtotal = getSubtotalFromForm(lineItems, state.activeSalesOrder.lineItems, maxDigits)
  const taxObj = getTaxObjFromForm(
    lineItems,
    state.activeSalesOrder.lineItems,
    { maxDigits, taxDict },
  )
  return {
    ...state,
    activeSalesOrder: {
      ...state.activeSalesOrder,
      subtotal,
      taxObj,
      total: subtotal + (+taxObj.total || 0),
    },
    activeForm: {
      ...state.activeForm,
      hasChanges: hasChanges({ ...state.activeForm, lineItems }),
      lineItems,
      isValid: isFormValid(state.activeForm.global, state.activeSalesOrder, lineItems),
    },
  }
}

function buildInitialLineItemsFormState(lineItems = [], isDuplicate = false, selectionIds, groupSelectionIds) {
  const _lineItems = lineItems.filter((lineItem) => !lineItem.isGroup)
  const _groups = lineItems.filter((lineItem) => lineItem.isGroup)
  return {
    selections: selectionIds ?
      _lineItems.filter((lineItem) => selectionIds.includes(lineItem.id)) :
      [],
    insertions: isDuplicate ? buildLineItemsFormData(_lineItems, true) : {},
    updates: !isDuplicate ? buildLineItemsFormData(_lineItems) : {},
    deletions: {},
    groupSelections: groupSelectionIds ?
      _groups.filter((group) => groupSelectionIds.includes(group.id)) :
      [],
    groupInsertions: isDuplicate ? buildGroupsFormData(_groups, true) : {},
    groupUpdates: !isDuplicate ? buildGroupsFormData(_groups) : {},
    groupDeletions: {},
  }
}

function buildLineItemsFormData(lineItems = [], isDuplicate = false) {
  const formData = {}

  lineItems.forEach((lineItem) => {
    formData[lineItem.id] = dataToFormData(lineItem, getLineItemFields(!isDuplicate, isDuplicate), isDuplicate)
    formData[lineItem.id].isGlobalChange = isDuplicate
  })

  return formData
}

function buildGroupsFormData(groups = [], isDuplicate = false) {
  const formData = {}

  groups.forEach((group) => {
    formData[group.id] = dataToFormData(group, getGroupFields(!isDuplicate), isDuplicate)
    formData[group.id].isGlobalChange = isDuplicate
  })

  return formData
}

function getDefaultCurrencyInfo() {
  return { exchangeRate: 1 }
}

function hasChanges(form) {
  return Object.keys(form.global).some((key) => form.global[key].isChanged) ||
    Object.keys(form.billTo).some((key) => form.billTo[key].isChanged) ||
    Object.keys(form.shipTo).some((key) => form.shipTo[key].isChanged) ||
    Object.keys(form.lineItems.updates).some((key) => form.lineItems.updates[key].isGlobalChange) ||
    Object.keys(form.lineItems.insertions).length > 0 ||
    Object.keys(form.lineItems.deletions).length > 0 ||
    Object.keys(form.lineItems.groupInsertions).length > 0 ||
    Object.keys(form.lineItems.groupUpdates).some((key) => form.lineItems.groupUpdates[key].isGlobalChange) ||
    Object.keys(form.lineItems.groupDeletions).length > 0
}

function isFormValid(globalForm, saleOrder, lineItemForm) {
  const lineItemInsertions = Object.values(lineItemForm.insertions)
  const isCustomServiceConfigurationRequired = lineItemInsertions.some((lineItem) => {
    return lineItem.templateType?.value === 'custom_service' &&
      !lineItem.customServiceConfiguration?.isChanged
  })
  return !!globalForm.customerId.value && !!globalForm.plantId.value &&
    !isCustomServiceConfigurationRequired &&
  (!saleOrder.status ||
    saleOrder.status === 'draft' ||
    saleOrder.status === 'quote_submitted' ||
    !!Date.parse(globalForm.salesOrderDate.value))
}

export function templateToLineItem(id, item, lineItemFieldKeys, rank, fieldMapper = {}) {
  const lineItemData = {
    id,
    isTaxed: item.isTaxed,
    name: item.descriptionToCustomerLanguage,
    dimension: item.dimension,
    unit: item.measureUnit,
    quantity: item.moq,
    moq: item.moq,
    discount: 0,
    discounted: item.sellingPrice,
    unitPrice: item.sellingPrice,
    notes: item.longDescriptionToCustomerLanguage,
    promisedDate: null,
    sku: item.sku,
    weightUnit: item.weightUnit,
    unitWeight: item.unitWeight,
    isManufactured: item.isManufactured,
    isPurchased: item.isPurchased,
    isSelling: item.isSelling,
    templateUnit: item.measureUnit,
    rank,
    customServiceConfiguration: {
      manufacturingOrderTemplateId: item.manufacturingOrderId,
    },
    accountId: item.incomeAccountId,
  }

  if (Object.keys(fieldMapper).length > 0) {
    Object.keys(fieldMapper).forEach((field) => {
      const mapper = fieldMapper[field]
      lineItemData[field] = item[Array.isArray(mapper) ? mapper.find((m) => item[m]) : mapper] ?? null
    })
  }

  Object.keys(item).forEach((key) => {
    const templateDataKey = `template${key.charAt(0).toUpperCase() + key.slice(1)}`
    if (lineItemFieldKeys.includes(templateDataKey)) {
      lineItemData[templateDataKey] = item[key]
    }
  })

  return lineItemData
}

export function getSalesOrderItemTitle(entityData) {
  return getFirstString(
    entityData.overwritten_name,
    entityData.name,
    entityData.materialTitle,
  )
}

export function getSalesOrderGroupTitle(entityData) {
  return entityData.name
}

export function duplicateFromSelection(dispatch, getState) {
  const maxDigits = getState().settings.global.number_decimals
  dispatch({ type: DUPLICATE_LINE_ITEMS_FROM_SELECTION, payload: { maxDigits } })
}

export async function fetchSalesOrderItemGroupByids(ids, data) {
  if (!ids?.length) return []

  const { isSuccess, result } = await safeFetchJson(
    buildGetUrl(`/new_api/sales-orders/groups/${ids}`, data),
  )

  return isSuccess ? result.map((item) => parseSalesOrderGroup(item)) : []
}

export function getLineItemTaxId(customerDetails) {
  return customerDetails.taxId || customerDetails.shippingAddress?.tax?.id || customerDetails.billingAddress?.tax?.id
}

function _buildAddressForUpdate(newAddress, oldAddress, isUpdate) {
  return {
    country: isUpdate ? newAddress?.country?.value ?? oldAddress?.country?.value : oldAddress?.country?.value,
    state: isUpdate ? newAddress?.state?.value ?? oldAddress?.state?.value : oldAddress?.state?.value,
  }
}

export function getCountryStateFromAddress(newAddress, activeForm, type) {
  const shipTo = _buildAddressForUpdate(newAddress, activeForm.shipTo, type === 'shipTo')
  const isShipTo = shipTo.country && shipTo.state

  const billTo = _buildAddressForUpdate(newAddress, activeForm.billTo, type === 'billTo')
  const country = isShipTo ? shipTo.country : billTo.country
  const state = isShipTo ? shipTo.state : billTo.state
  return {
    country,
    state,
    isShipTo,
  }
}
