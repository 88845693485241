import { configureStore } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'

import addressesReducer from './addresses/addressesSlice'
import attributesReducer from './attributes/attributesSlice'
import billsReducer from './bills/billsSlice'
import cardGroupsReducer from './card-groups/cardGroupsSlice'
import cardStepsReducer from './card-steps/cardStepsSlice'
import cardsReducer from './cards/cardsSlice'
import cardsErrorReducer from './cards/errorSlice'
import categoriesReducer from './categories/categoriesSlice'
import categoriesErrorReducer from './categories/errorSlice'
import categoriesSuccessReducer from './categories/successSlice'
import chartsOfAccountsReducer from './charts-of-accounts/chartsOfAccountsSlice'
import checkListsReducer from './checklists/checkListsSlice'
import companiesReducer from './companies/companiesSlice'
import consignmentItemsReducer from './consignment-items/consignmentItemsSlice'
import consignmentItemsErrorReducer from './consignment-items/errorSlice'
import contactCarrierAccountsReducer from './contact-carrier-account/contactCarrierAccountsSlice'
import contactCategoriesReducer from './contact-categories/contactCategoriesSlice'
import contactCategoriesErrorReducer from './contact-categories/errorSlice'
import contactCategoriesSuccessReducer from './contact-categories/successSlice'
import contactPeopleReducer from './contact-people/contactPeopleSlice'
import contactsReducer from './contacts/contactsSlice'
import costReducer from './cost/costSlice'
import costErrorReducer from './cost/errorSlice'
import countriesReducer from './countries/countriesSlice'
import countryOfOriginsReducer from './country-of-origins/countryOfOriginsSlice'
import currenciesReducer from './currencies/currenciesSlice'
import customFieldsReducer from './custom-fields/customFieldsSlice'
import customIdentityProvidersReducer from './custom-identity-providers/customIdentityProvidersSlice'
import customIdentityProviderErrorReducer from './custom-identity-providers/errorSlice'
import dashboardsReducer from './dashboards/dashboardsSlice'
import emailTemplatesReducer from './email-templates/emailTemplatesSlice'
import emailsReducer from './emails/emailsSlice'
import emailsErrorReducer from './emails/errorSlice'
import emailsSuccessReducer from './emails/successSlice'
import equipmentsReducer from './equipments/equipmentsSlice'
import eventsReducer from './events/eventsSlice'
import filesReducer from './files/filesSlice'
import harmonizedSystemCodesReducer from './harmonized-system-codes/harmonizedSystemCodesSlice'
import devicesReducer from './iiot/devicesSlice'
import incotermsReducer from './incoterms/incotermsSlice'
import integrationsReducer from './integrations/integrationsSlice'
import inventoriesErrorReducer from './inventories/errorSlice'
import inventoriesReducer from './inventories/inventoriesSlice'
import inventoriesSuccessReducer from './inventories/successSlice'
import inventoryConditionsReducer from './inventory-conditions/inventoryConditionsSlice'
import invoicesReducer from './invoices/invoicesSlice'
import itemGroupsErrorReducer from './item-groups/errorSlice'
import itemGroupsReducer from './item-groups/itemGroupsSlice'
import itemGroupsSuccessReducer from './item-groups/successSlice'
import itemsErrorReducer from './items/errorSlice'
import itemsReducer from './items/itemsSlice'
import itemsSuccessReducer from './items/successSlice'
import labelsReducer from './labels/labelsSlice'
import locationsErrorReducer from './locations/errorSlice'
import locationsReducer from './locations/locationsSlice'
import materialsErrorReducer from './materials/errorSlice'
import materialsReducer from './materials/materialsSlice'
import materialsSuccessReducer from './materials/successSlice'
import messageCallbacksReducer from './message-callbacks/messageCallbacksSlice'
import plannedLedgersReducer from './planned-ledgers/plannedLedgersSlice'
import planningErrorReducer from './planning/errorSlice'
import planningReducer from './planning/planningSlice'
import plantsErrorReducer from './plants/errorSlice'
import plantsReducer from './plants/plantsSlice'
import plantsSuccessReducer from './plants/successSlice'
import popupsReducer from './popups/popupsSlice'
import priceListsErrorReducer from './price-lists/errorSlice'
import priceListsReducer from './price-lists/priceListsSlice'
import priceListsSuccessReducer from './price-lists/successSlice'
import projectPlanningReducer from './project-planning/projectPlanningSlice'
import projectErrorReducer from './projects/errorSlice'
import projectsReducer from './projects/projectsSlice'
import purchaseOrdersErrorReducer from './purchase-orders/errorSlice'
import purchaseOrderItemsReducer from './purchase-orders/purchaseOrderItemsSlice'
import purchaseOrdersReducer from './purchase-orders/purchaseOrdersSlice'
import purchaseOrdersSuccessReducer from './purchase-orders/successSlice'
import rawsErrorReducer from './raws/errorSlice'
import rawsReducer from './raws/rawsSlice'
import rawsSuccessReducer from './raws/successSlice'
import receptionsErrorReducer from './receptions/errorSlice'
import receptionItemsReducer from './receptions/receptionItemsSlice'
import receptionsReducer from './receptions/receptionsSlice'
import refsReducer from './refs/refsSlice'
import reportingTagsReducer from './reporting-tags/reportingTagsSlice'
import resourcesErrorReducer from './resources/errorSlice'
import resourcesReducer from './resources/resourcesSlice'
import salesOrdersErrorReducer from './sales-orders/errorSlice'
import salesOrderItemsReducer from './sales-orders/salesOrderItemsSlice'
import salesOrdersReducer from './sales-orders/salesOrdersSlice'
import salesOrdersSuccessReducer from './sales-orders/successSlice'
import settingsErrorReducer from './settings/errorSlice'
import settingsReducer from './settings/settingsSlice'
import settingsSuccessReducer from './settings/successSlice'
import shipmentPlanningReducer from './shipment-planning/shipmentPlanningSlice'
import shipmentsErrorReducer from './shipments/errorSlice'
import shipmentItemsReducer from './shipments/shipmentItemsSlice'
import shipmentsReducer from './shipments/shipmentsSlice'
import shipmentsSuccessReducer from './shipments/successSlice'
import smartFormReducer from './smart-form/smartFormSlice'
import smartGridInputsReducer from './smart-grid-inputs/smartGridInputSlice'
import statesReducer from './states/statesSlice'
import stepsReducer from './steps/stepsSlice'
import synonymsReducer from './synonyms/synonymsSlice'
import taresErrorReducer from './tares/errorSlice'
import taresSuccessReducer from './tares/successSlice'
import taresReducer from './tares/taresSlice'
import taxesByRegionReducer from './taxes/taxesByRegionSlice'
import taxesReducer from './taxes/taxesSlice'
import taxRulesReducer from './taxes/taxRulesSlice'
import toastReducer from './toasts/toastSlice'
import transactionsReducer from './transactions/transactionsSlice'
import treatmentsErrorReducer from './treatments/errorSlice'
import treatmentsSuccessReducer from './treatments/successSlice'
import treatmentsReducer from './treatments/treatmentsSlice'
import loginUserErrorReducer from './users/login-user/errorSlice'
import loginUserSuccessReducer from './users/login-user/successSlice'
import loginUserReducer from './users/login-user/userSlice'
import valueStreamReducer from './value-stream/valueStreamSlice'
import viewsErrorReducer from './views/errorSlice'
import viewsSuccessReducer from './views/successSlice'
import viewsReducer from './views/viewsSlice'
import warehousesReducer from './warehouses/warehousesSlice'

export type ReducerState = {
  [K in keyof typeof state]: ReturnType<typeof state[K]>;
};

const state = {
  addresses: addressesReducer,
  attributes: attributesReducer,
  bills: billsReducer,
  cardGroups: cardGroupsReducer,
  cards: cardsReducer,
  cardsError: cardsErrorReducer,
  cardSteps: cardStepsReducer,
  categories: categoriesReducer,
  categoriesError: categoriesErrorReducer,
  categoriesSuccess: categoriesSuccessReducer,
  chartsOfAccounts: chartsOfAccountsReducer,
  checklists: checkListsReducer,
  companies: companiesReducer,
  consignmentItems: consignmentItemsReducer,
  consignmentItemsError: consignmentItemsErrorReducer,
  contactCarrierAccounts: contactCarrierAccountsReducer,
  contactCategories: contactCategoriesReducer,
  contactCategoriesError: contactCategoriesErrorReducer,
  contactCategoriesSuccess: contactCategoriesSuccessReducer,
  contactPeople: contactPeopleReducer,
  contacts: contactsReducer,
  cost: costReducer,
  costError: costErrorReducer,
  countries: countriesReducer,
  countryOfOrigins: countryOfOriginsReducer,
  currenciesReducer: currenciesReducer,
  customFields: customFieldsReducer,
  customIdentityProviderError: customIdentityProviderErrorReducer,
  customIdentityProviders: customIdentityProvidersReducer,
  dashboards: dashboardsReducer,
  devices: devicesReducer,
  emails: emailsReducer,
  emailsError: emailsErrorReducer,
  emailsSuccess: emailsSuccessReducer,
  emailTemplates: emailTemplatesReducer,
  equipments: equipmentsReducer,
  events: eventsReducer,
  files: filesReducer,
  harmonizedSystemCodes: harmonizedSystemCodesReducer,
  incoterms: incotermsReducer,
  integrations: integrationsReducer,
  inventories: inventoriesReducer,
  inventoriesError: inventoriesErrorReducer,
  inventoriesSuccess: inventoriesSuccessReducer,
  inventoryConditions: inventoryConditionsReducer,
  invoices: invoicesReducer,
  itemGroups: itemGroupsReducer,
  itemGroupsError: itemGroupsErrorReducer,
  itemGroupsSuccess: itemGroupsSuccessReducer,
  items: itemsReducer,
  itemsError: itemsErrorReducer,
  itemsSuccess: itemsSuccessReducer,
  labels: labelsReducer,
  locations: locationsReducer,
  locationsError: locationsErrorReducer,
  loginUser: loginUserReducer,
  loginUserError: loginUserErrorReducer,
  loginUserSuccess: loginUserSuccessReducer,
  materials: materialsReducer,
  materialsError: materialsErrorReducer,
  materialsSuccess: materialsSuccessReducer,
  messageCallbacks: messageCallbacksReducer,
  plannedLedgers: plannedLedgersReducer,
  planning: planningReducer,
  planningError: planningErrorReducer,
  plants: plantsReducer,
  plantsError: plantsErrorReducer,
  plantsSuccess: plantsSuccessReducer,
  popups: popupsReducer,
  priceLists: priceListsReducer,
  priceListsError: priceListsErrorReducer,
  priceListsSuccess: priceListsSuccessReducer,
  projectError: projectErrorReducer,
  projects: projectsReducer,
  projectPlanning: projectPlanningReducer,
  purchaseOrderItems: purchaseOrderItemsReducer,
  purchaseOrders: purchaseOrdersReducer,
  purchaseOrdersError: purchaseOrdersErrorReducer,
  purchaseOrdersSuccess: purchaseOrdersSuccessReducer,
  raws: rawsReducer,
  rawsError: rawsErrorReducer,
  rawsSuccess: rawsSuccessReducer,
  receptionLineItems: receptionItemsReducer,
  receptions: receptionsReducer,
  receptionsError: receptionsErrorReducer,
  refs: refsReducer,
  reportingTags: reportingTagsReducer,
  resources: resourcesReducer,
  resourcesError: resourcesErrorReducer,
  salesOrderItems: salesOrderItemsReducer,
  salesOrders: salesOrdersReducer,
  salesOrdersError: salesOrdersErrorReducer,
  salesOrdersSuccess: salesOrdersSuccessReducer,
  settings: settingsReducer,
  settingsError: settingsErrorReducer,
  settingsSuccess: settingsSuccessReducer,
  shipmentLineItems: shipmentItemsReducer,
  shipmentPlanning: shipmentPlanningReducer,
  shipments: shipmentsReducer,
  shipmentsError: shipmentsErrorReducer,
  shipmentsSuccess: shipmentsSuccessReducer,
  smartForm: smartFormReducer,
  smartGridInputs: smartGridInputsReducer,
  states: statesReducer,
  steps: stepsReducer,
  synonyms: synonymsReducer,
  tares: taresReducer,
  taresError: taresErrorReducer,
  taresSuccess: taresSuccessReducer,
  taxesByRegion: taxesByRegionReducer,
  taxes: taxesReducer,
  taxRules: taxRulesReducer,
  toast: toastReducer,
  transactions: transactionsReducer,
  treatments: treatmentsReducer,
  treatmentsError: treatmentsErrorReducer,
  treatmentsSuccess: treatmentsSuccessReducer,
  valueStreams: valueStreamReducer,
  views: viewsReducer,
  viewsError: viewsErrorReducer,
  viewsSuccess: viewsSuccessReducer,
  warehouses: warehousesReducer,
}

const rootReducer = combineReducers(state)

export const setupStore = (preloadedState) => {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: { warnAfter: 128 },
    }),
    preloadedState,
  })
}

export default rootReducer
