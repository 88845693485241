export const GET_INVENTORIES_COUNT = 'GET_INVENTORIES_COUNT'
export const GET_INVENTORIES = 'GET_INVENTORIES'

export const GET_NOT_CONSUMED_COUNT = 'GET_NOT_CONSUMED_COUNT'
export const GET_NOT_CONSUMED = 'GET_NOT_CONSUMED'

export const CREATE_INVENTORIES = 'CREATE_INVENTORIES'
export const CLEAR_CREATE_SUCCESS = 'CLEAR_CREATE_INVENTORIES_SUCCESS'
export const UPDATE_INVENTORIES = 'UPDATE_INVENTORIES'
export const CLEAR_UPDATE_ERROR = 'CLEAR_UPDATE_INVENTORIES_ERROR'
export const WASTE_INVENTORIES = 'WASTE_INVENTORIES'
export const CLEAR_WASTE_SUCCESS = 'CLEAR_WASTE_INVENTORIES_SUCCESS'
export const DELETE_INVENTORIES = 'DELETE_INVENTORIES'
export const CLEAR_DELETE_SUCCESS = 'CLEAR_DELETE_INVENTORIES_SUCCESS'

export const CLEAR_INVENTORIES = 'CLEAR_INVENTORIES'
export const CLEAR_NOT_CONSUMED = 'CLEAR_NOT_CONSUMED'

export const SET_SCAN_ERROR = 'SET_INVENTORY_SCAN_ERROR'
export const CLEAR_SCAN_ERROR = 'CLEAR_INVENTORY_SCAN_ERROR'
